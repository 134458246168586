import * as constant from './constant';

const expense = (value) => (value ? '' : constant.EMPTY_EXPENSE);

const validateExpense = (value, setError) => {
    const err = expense(value);
    if (err) {
        setError(err);
    }
};

const validateForm = (value, setError) => {
    const err = expense(value);
    if (err) {
        setError(err);
    }
    return !err;
};

export { validateExpense, validateForm };

import { statisticService } from '@services';
import * as constant from './constant';
import unitOfWork from '@controllers';

const setNewProfit = (setData, values, type) => {
    setData({
        labels: values.map((value) => value.name || value.displayName),
        datasets: [
            {
                data: values.map((value) =>
                    value.bills.length > 0
                        ? type === 'count'
                            ? parseInt(value.bills[0].count)
                            : parseInt(value.bills[0].totalPrice)
                        : 0,
                ),
                backgroundColor: constant.colors,
                borderColor: constant.colors,
                borderWidth: 1,
            },
        ],
    });
};

const setNewExpense = (setData, values, type) => {
    setData({
        labels: values.map((value) => value.name || value.displayName),
        datasets: [
            {
                data: values.map((value) =>
                    value.expenseBills.length > 0
                        ? type === 'count'
                            ? parseInt(value.expenseBills[0].count)
                            : parseInt(value.expenseBills[0].totalPrice)
                        : 0,
                ),
                backgroundColor: constant.colors,
                borderColor: constant.colors,
                borderWidth: 1,
            },
        ],
    });
};

const handleStatistic = (
    catalog,
    selectedType,
    selectedValue,
    setData,
    startDate,
    endDate,
) => {
    const cancelController = new AbortController();

    if (catalog.type === 0) {
        const setNewData = (result) =>
            setNewProfit(setData, result, selectedValue);

        unitOfWork.statistic.statisticProfitByType(
            cancelController.signal,
            selectedType,
            startDate,
            endDate,
            setNewData,
        );
    } else {
        const setNewData = (result) =>
            setNewExpense(setData, result, selectedValue);

        unitOfWork.statistic.statisticExpenseByType(
            cancelController.signal,
            selectedType,
            startDate,
            endDate,
            setNewData,
        );
    }

    return cancelController;
};

const handleChangeTypeClick = (setCatalog) => {
    setCatalog((catalog) => ({
        name: constant.nameCatalog[1 - catalog.type],
        type: 1 - catalog.type,
        data: constant.types[1 - catalog.type],
    }));
};

const handleSelectedTypeChange = (e, setSelectedType) => {
    setSelectedType(e.target.value);
};

const handleSelectedValue = (e, setSelectedValue) => {
    setSelectedValue(e.target.value);
};

export {
    handleSelectedValue,
    handleStatistic,
    handleSelectedTypeChange,
    handleChangeTypeClick,
};

import { _p } from '../../../utils/model-proto';
import BaseModel from '../..';
import NoteGroup from '../noteGroup';

class NoteGroups extends BaseModel {
  static properties = {
    list: _p(NoteGroup, new NoteGroup()),
  };

  constructor(obj) {
    super(obj);
  }

  /*
  list: NoteGroup;
  */
}

export default NoteGroups;

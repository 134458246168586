import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import _ from 'lodash';
import * as event from './event';
import styles from './RetailBill.module.scss';
import logo from '@assets/image/logo.jpg';

function RetailBill({ bill }) {
  const [today, setToday] = useState(new Date());
  const [bills, setBills] = useState([
    {
      product: bill.product.name,
      count: 1,
      price: bill.price,
      total: bill.price,
    },
    ...Array(4)
      .fill('')
      .map(() => ({
        product: '',
        count: '',
        price: '',
        total: '',
      })),
  ]);

  const [discount, setDiscount] = useState(0);

  const [feeDelivery, setFeeDelivery] = useState(0);

  const [VAT, setVAT] = useState(8);

  const prevTotal = React.useMemo(
    () => _.sumBy(bills, (bill) => bill.total || 0),
    [bills],
  );

  const total = React.useMemo(
    () => prevTotal - discount,
    [prevTotal, discount],
  );

  const totalPayment = React.useMemo(
    () => feeDelivery + total + Math.ceil((total * VAT) / 100),
    [feeDelivery, total, VAT],
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div className={styles.contactHeader}>
          <h3 className={styles.companyName}>Công ty Khắc dấu quốc tiến</h3>
          <h4>
            MST: <span className={styles.tax}>0316398073 - </span>
            <span className={styles.hotline}>Hotline: 0933339833</span>
          </h4>
          <p>VPKD: 198 Nguyễn Duy Cung, P12, Q.Gò Vấp, TP.HCM</p>
        </div>

        <h3 className={styles.titleHeader}>Hóa đơn</h3>

        <div className={styles.logoWrapper}>
          <img src={logo} alt="" />
        </div>
      </div>

      <div className={styles.receiver}>
        <div>
          <span className={styles.receiverHeader}>Khách hàng:</span>
          <span className={styles.receiverContent} contentEditable>
            {bill.name} - {bill.phones.join(' - ')}
          </span>
        </div>

        <div className={styles.receiverWrapper}>
          <span className={clsx(styles.receiverHeader, styles.receiverAddress)}>
            Địa chỉ:
          </span>
          <span className={styles.receiverContent} contentEditable>
            {bill.address}
          </span>
        </div>
      </div>

      <div className={styles.tableHeader}>
        <div className={styles.tableCell}>STT</div>
        <div className={styles.tableCell}>Sản phẩm-Qui cách</div>
        <div className={styles.tableCell}>Số lượng</div>
        <div className={styles.tableCell}>Đơn giá</div>
        <div className={styles.tableCell}>Thành tiền</div>
      </div>

      <div className={styles.tableContent}>
        {bills.map((bill, index) => (
          <div className={styles.tableRow}>
            <div className={styles.tableCell}>{index + 1}</div>
            <input
              className={styles.tableCell}
              value={bill.product}
              onChange={(e) => event.handleProductChange(e, index, setBills)}
            />
            <input
              className={styles.tableCell}
              value={bill.count}
              onChange={(e) => event.handleCountChange(e, index, setBills)}
            />
            <input
              className={styles.tableCell}
              value={bill.price && bill.price.toLocaleString('vi-VN')}
              onChange={(e) => event.handlePriceChange(e, index, setBills)}
            />
            <div className={styles.tableCell}>
              {bill.total && bill.total.toLocaleString('vi-VN')}
            </div>
          </div>
        ))}

        <div className={styles.tableRow}>
          <div className={clsx(styles.tableCell, styles.tableCellSpanFourth)}>
            Tổng tiền
          </div>

          <div className={styles.tableCell}>
            {parseInt(prevTotal).toLocaleString('vi-VN')}
          </div>
        </div>

        <div className={styles.tableRow}>
          <div className={clsx(styles.tableCell, styles.tableCellSpanFourth)}>
            Giảm giá
          </div>

          <input
            className={styles.tableCell}
            value={discount && discount.toLocaleString('vi-VN')}
            onChange={(e) =>
              event.handleDiscountChange(e, setDiscount, prevTotal)
            }
          />
        </div>

        <div className={styles.tableRow}>
          <div className={clsx(styles.tableCell, styles.tableCellSpanFourth)}>
            Thành tiền
          </div>

          <div className={styles.tableCell}>
            {total.toLocaleString('vi-VN')}
          </div>
        </div>

        <div className={styles.tableRow}>
          <div className={clsx(styles.tableCell, styles.tableCellSpanFourth)}>
            Phí VAT (
            <input
              type="text"
              className={styles.inputVAT}
              value={`${VAT}%`}
              onChange={(e) => {
                const value = parseInt(e.target.value.match(/[0-9]+/g) || 0);
                value > 100 ? setVAT(100) : setVAT(value);
              }}
            />
            )
          </div>

          <div className={styles.tableCell}>
            {Math.ceil((total * VAT) / 100).toLocaleString('vi-VN')}
          </div>
        </div>

        <div className={styles.tableRow}>
          <div className={clsx(styles.tableCell, styles.tableCellSpanFourth)}>
            Phí vận chuyển
          </div>

          <input
            className={styles.tableCell}
            value={feeDelivery.toLocaleString('vi-VN')}
            onChange={(e) => event.handleFeeDeliveryChange(e, setFeeDelivery)}
          />
        </div>

        <div className={clsx(styles.tableRow, styles.total)}>
          <div
            className={clsx(
              styles.tableCell,
              styles.tableCellSpanFourth,
              'textUppercase',
              'textUnderline',
              'textBold',
            )}
          >
            Tổng thanh toán
          </div>

          <div className={clsx(styles.tableCell, 'textBold')}>
            {totalPayment.toLocaleString('vi-VN')}
          </div>
        </div>
      </div>

      <div className={styles.footer}>
        <div className={styles.noteWrapper}>
          <p className={styles.noteHeader}>Ghi chú:</p>
          <p className={styles.noteContent} contentEditable>
            {bill.note}
          </p>
        </div>

        <div className={styles.signWrapper}>
          <p className={styles.signContent}>
            Tp.HCM, ngày {today.getDate()} tháng {today.getMonth() + 1} năm{' '}
            {today.getFullYear()}.
          </p>
          <p className={styles.signContent}>ĐẠI DIỆN CÔNG TY</p>
        </div>
      </div>
    </div>
  );
}

export default RetailBill;

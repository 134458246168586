import { _p } from '@/domain/models/utils/model-proto';
import BaseModel from '../..';
import Phone from '../phone';

class Phones extends BaseModel {
  static properties = {
    list: _p(Phone, new Phone()),
  };

  constructor(obj) {
    super(obj);
  }

  /*
  type: string | null;
  name: string | null;
  */

  get phones() {
    return this.list.map(({ phone }) => phone);
  }

  join(delimiter = ' - ') {
    return this.phones.join(delimiter);
  }
}

export default Phones;

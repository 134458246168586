const setNewBill = (setBill, type, value) => {
    setBill((bill) => ({
        ...bill,
        [type]: value,
    }));
};

const handleNameChange = (e, setBill) => {
    setNewBill(setBill, 'name', e.target.value);
};

const handleAddressChange = (e, setBill) => {
    setNewBill(setBill, 'address', e.target.value);
};

const handleShipServiceChange = (e, setBill) => {
    const id = parseInt(e.target.value);

    setBill((bill) => ({
        ...bill,
        shipServiceID: id,
    }));
};

const handleEcommerceChange = (e, setBill) => {
    setBill((bill) => ({
        ...bill,
        ecommerceID: parseInt(e.target.value),
    }));
};

export {
    handleNameChange,
    handleAddressChange,
    handleShipServiceChange,
    handleEcommerceChange,
};

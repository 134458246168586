import { _p, _pc } from '@/domain/models/utils/model-proto';
import BaseModel from '@/domain/models/entities';
import QtProduct from '@/domain/models/entities/stock/qt-product';
import QtPrice from '@/domain/models/value-object/qtPrice';

class ExportOrderDetail extends BaseModel {
  static properties = {
    id: null,
    exportOrderId: null,
    productId: null,
    count: null,
    price: _pc(QtPrice, null),
    product: _p(QtProduct, null),
  };

  constructor(obj) {
    super(obj);
  }

  /*
  id: number;
  exportOrderId: number;
  count: number;
  price: number;
  product: QtProduct || null;
  */

  get totalPrice() {
    return this.price.totalPrice(this.count);
  }
}

export default ExportOrderDetail;

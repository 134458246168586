import clsx from 'clsx';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { connect } from 'react-redux';
import {
  ConfirmModal,
  CustomDatePicker,
  ExpenseModal,
  ExpenseTable,
  NotifyModal,
} from '@components';
import styles from './Expense.module.scss';
import * as event from './event';
import * as defaultDate from '@components/CustomDatePicker/defaultValue';

function Expense(props) {
  // States
  const {
    state: { user },
  } = props;
  const isAdmin = useMemo(() => !!user?.isAdmin, [user]);
  const [startDate, setStartDate] = useState(
    defaultDate.getStartDate(new Date()),
  );
  const [endDate, setEndDate] = useState(defaultDate.getEndDate(new Date()));
  const [filterExpenseIDs, setFilterExpenseIDs] = useState([]);
  const [filterExpenses, setFilterExpenses] = useState([]);
  const [selectedExpense, setSelectedExpense] = useState(0);

  const [hideAddModal, setHideAddModal] = useState(true);

  // First fetch
  const [expenses, setExpenses] = useState([]);
  const [expense, setExpense] = useState(0);
  const [expenseBills, setExpenseBills] = useState([]);
  const [noExpense, setNoExpense] = useState(0);
  const [totalExpense, setTotalExpense] = useState(0);

  // Input
  const [price, setPrice] = useState(0);
  const [note, setNote] = useState('');
  const [order, setOrder] = useState('ASC');

  const [error, setError] = useState({
    expense: '',
    price: '',
  });

  const [notifyModal, setNotifyModal] = useState({
    type: '',
    title: 'Thêm',
    content: null,
  });
  const [notifyModalShow, setNotifyModalShow] = useState(false);

  const [confirmModal, setConfirmModal] = useState({
    actionTitle: '',
    actionConfirm: '',
    actionConduct: '',
  });
  const [confirmModalShow, setConfirmModalShow] = useState(false);

  const [page, setPage] = useState(1);
  let [stopScrollEvent, setStopScrollEvent] = useState(true);
  const expenseTableRef = useRef();

  const [newData, setNewData] = useState('');

  const [updateDate, setUpdateDate] = useState(new Date());
  const [selectedExpenseBillID, setSelectedExpenseBillID] = useState(0);

  // Get expenses list
  useEffect(() => {
    event.getExpenses(setExpenses);
  }, []);

  React.useLayoutEffect(() => {
    const cbScroll = (e) => {
      const maxDistanceFromBot = e.target.offsetHeight;
      if (
        e.target.scrollHeight - e.target.scrollTop <= maxDistanceFromBot &&
        !stopScrollEvent
      ) {
        setStopScrollEvent(true);
        setPage((page) => page + 1);
      }
    };
    expenseTableRef.current.addEventListener('scroll', cbScroll);

    return () => {
      expenseTableRef.current.removeEventListener('scroll', cbScroll);
    };
  }, [stopScrollEvent, expenseTableRef]);

  useEffect(() => {
    event.getExpenseBills(
      isAdmin,
      page,
      startDate,
      endDate,
      order,
      selectedExpense,
      setExpenseBills,
      setNoExpense,
      setTotalExpense,
      setStopScrollEvent,
      setNewData,
      setFilterExpenseIDs,
    );
  }, [page]);

  useEffect(() => {
    if (page === 1) {
      event.getExpenseBills(
        isAdmin,
        page,
        startDate,
        endDate,
        order,
        selectedExpense,
        setExpenseBills,
        setNoExpense,
        setTotalExpense,
        setStopScrollEvent,
        setNewData,
        setFilterExpenseIDs,
      );
    } else setPage(1);
  }, [startDate, endDate, order, selectedExpense]);

  useEffect(() => {
    if (filterExpenseIDs.length > 0 && expenses.length > 0) {
      setFilterExpenses(
        expenses.filter((item) =>
          filterExpenseIDs.some((expense) => expense.expenseID === item.id),
        ),
      );
    }
  }, [filterExpenseIDs, expenses]);

  return (
    <React.Fragment>
      <div className={styles.container}>
        <div className={styles.containerHeader}>
          {/* Form */}
          <form
            className={styles.form}
            onSubmit={(e) =>
              event.handleSubmitForm(
                e,
                expense,
                price,
                note,
                setPrice,
                setNote,
                setExpenseBills,
                setNoExpense,
                setTotalExpense,
                setNotifyModalShow,
                setNotifyModal,
                setError,
              )
            }
          >
            <div
              className={clsx(styles.inputWrapper, {
                [styles.error]: error.expense,
              })}
              error={error.expense}
            >
              <div className={styles.selectLabelWrapper}>
                <label htmlFor="reason" className={styles.label}>
                  Khoản chi
                </label>

                {isAdmin && (
                  <i
                    className={clsx(styles.icon, 'fa fa-circle-plus')}
                    onClick={() => setHideAddModal(false)}
                  />
                )}
              </div>

              <select
                id="reason"
                className={styles.select}
                value={expense}
                onChange={(e) =>
                  event.handleExpenseChange(e, setExpense, setError)
                }
                onBlur={() => event.handleExpenseBlur(expense, setError)}
              >
                <option value="0">Chọn khoản chi</option>
                {expenses.map((expense) => (
                  <option value={expense.id} key={expense.id}>
                    {expense.name}
                  </option>
                ))}
              </select>
            </div>

            <div
              className={clsx(styles.inputWrapper, {
                [styles.error]: error.price,
              })}
              error={error.price}
            >
              <label htmlFor="price" className={styles.label}>
                Số tiền
              </label>
              <input
                id="price"
                className={styles.input}
                value={price.toLocaleString('vi-VN')}
                onChange={(e) => event.handlePriceChange(e, setPrice, setError)}
              />
            </div>

            <div className={styles.inputWrapper}>
              <label htmlFor="note" className={styles.label}>
                Ghi chú
              </label>
              <input
                id="note"
                className={styles.input}
                value={note}
                onChange={(e) => setNote(e.target.value)}
              />
            </div>

            <input className={styles.btn} type="submit" value="Thêm" />
          </form>

          <div className={styles.filterContainer}>
            <div className={styles.statistic}>
              <div className={styles.statisticContent}>
                <span className={styles.statisticLabel}>Số khoản chi:</span>
                <span className={styles.statisticValue}>{noExpense}</span>
              </div>

              <div className={styles.statisticContent}>
                <span className={styles.statisticLabel}>Tổng chi:</span>
                <span className={styles.statisticValue}>
                  {totalExpense.toLocaleString('vi-VN', {
                    style: 'currency',
                    currency: 'VND',
                  })}
                </span>
              </div>
            </div>

            <div
              className={clsx(styles.btnRefreshWrapper, {
                [styles.newData]: newData,
              })}
              newdata={newData}
            >
              <button
                className={clsx(styles.btn, {
                  [styles.disabled]: !newData,
                })}
                onClick={() => event.handleRefreshClick(setNewData, setPage)}
                disabled={newData ? '' : 'disabled'}
              >
                Refresh
              </button>
            </div>

            {isAdmin && (
              <React.Fragment>
                <select
                  id="reason"
                  className={styles.select}
                  value={selectedExpense}
                  onChange={(e) =>
                    event.handleSelectedExpenseChange(
                      e,
                      setSelectedExpense,
                      setError,
                    )
                  }
                >
                  <option value="0">Chọn khoản chi</option>
                  {filterExpenses.map((expense) => (
                    <option value={expense.id} key={expense.id}>
                      {expense.name}
                    </option>
                  ))}
                </select>

                <CustomDatePicker
                  startDate={startDate}
                  setStartDate={setStartDate}
                  endDate={endDate}
                  setEndDate={setEndDate}
                />
              </React.Fragment>
            )}
          </div>
        </div>

        <ExpenseTable
          ref={expenseTableRef}
          isAdmin={isAdmin}
          expenseBills={expenseBills}
          getExpenseBills={() =>
            event.getExpenseBills(
              isAdmin,
              page,
              startDate,
              endDate,
              order,
              selectedExpense,
              setExpenseBills,
              setNoExpense,
              setTotalExpense,
              setStopScrollEvent,
              setNewData,
              expenses,
              setFilterExpenseIDs,
            )
          }
          setPage={setPage}
          showConfirmModal={(modal) =>
            event.showConfirmModal(modal, setConfirmModal, setConfirmModalShow)
          }
          showNotifyModal={(modal) =>
            event.showNotifyModal(modal, setNotifyModal, setNotifyModalShow)
          }
          setExpenseBills={setExpenseBills}
          setNoExpense={setNoExpense}
          setTotalExpense={setTotalExpense}
          newData={newData}
          order={order}
          setOrder={setOrder}
          setSelectedExpenseBillID={setSelectedExpenseBillID}
        />
      </div>

      <div
        className={clsx(styles.modal, {
          [styles.hide]: !selectedExpenseBillID,
        })}
        id={styles.modal}
        onClick={() => setSelectedExpenseBillID(0)}
      >
        <div className={styles.overlay}></div>

        <form
          className={styles.formUpdate}
          onClick={(e) => e.stopPropagation()}
          onSubmit={(e) =>
            event.handleUpdateSubmit(
              e,
              selectedExpenseBillID,
              updateDate,
              setSelectedExpenseBillID,
              setExpenseBills,
              setNotifyModal,
              setNotifyModalShow,
            )
          }
        >
          <div className={styles.dateWrapper}>
            <label htmlFor="endDatePicker" className={styles.datePickerLabel}>
              Ngày chi
            </label>

            <ReactDatePicker
              selected={updateDate}
              onChange={(date) =>
                event.handleUpdateDateChange(
                  date,
                  setUpdateDate,
                  setNotifyModal,
                  setNotifyModalShow,
                )
              }
              className={styles.datePicker}
              id="endDatePicker"
              dateFormat="dd/MM/yyyy"
            />
          </div>

          <input className={styles.btn} type="submit" value="Cập nhật" />
        </form>
      </div>

      <ExpenseModal
        expenses={expenses}
        setSelectedExpense={setExpense}
        getExpenses={() => event.getExpenses(setExpenses)}
        hide={hideAddModal}
        closeModal={() => setHideAddModal(true)}
        setNotifyModal={setNotifyModal}
        setNotifyModalShow={setNotifyModalShow}
      />

      <NotifyModal
        modal={notifyModal}
        show={notifyModalShow}
        closeModal={() => setNotifyModalShow(false)}
      />

      <ConfirmModal
        modal={confirmModal}
        show={confirmModalShow}
        closeModal={() => setConfirmModalShow(false)}
      />
    </React.Fragment>
  );
}

export default connect(({ state }) => ({ state }))(Expense);

import { UserContext } from '@contexts/App';
import React, { useContext, useEffect, useState } from 'react';
import * as event from './event';
import ProtectedRoute from './ProtectedRoute';

function StaffRoute() {
    const [isStaff, setIsStaff] = useState(<React.Fragment />);
    const userContext = useContext(UserContext);

    useEffect(() => {
        event.isStaff(userContext, setIsStaff);
    }, [userContext]);

    const StaffOutlet = () => isStaff;

    return (
        <React.Fragment>
            <ProtectedRoute>
                <StaffOutlet />
            </ProtectedRoute>
        </React.Fragment>
    );
}

export default StaffRoute;

import { _p } from '@/domain/models/utils/model-proto';
import BaseModel from '..';
import Categories from './categories';
import Ecommerces from './ecommerces';
import ShipServices from './shipServices';
import NoteGroupRules from './noteGroupRules';
import Selection from './selection';
import Provinces from './provinces';
import Users from './users';

class MasterData extends BaseModel {
  static properties = {
    ecommerces: _p(Ecommerces, new Ecommerces()),
    shipServices: _p(ShipServices, new ShipServices()),
    categories: _p(Categories, new Categories()),
    noteGroupRules: _p(NoteGroupRules, new NoteGroupRules()),
    provinces: _p(Provinces, new Provinces()),
    users: _p(Users, new Users()),
    selection: _p(Selection, new Selection()),
    loading: false,
  };

  constructor(obj) {
    super(obj);
  }

  /*
  ecommerces: Ecommerces,
  */
}

export default MasterData;

import { roleService } from '@services';

class RoleController {
  getAll = async (cancelSignal, setRoles, toast) => {
    try {
      const roles = await roleService.getAll(cancelSignal);

      setRoles(roles);
    } catch (error) {
      toast.show({
        severity: 'error',
        summary: 'Thông báo lỗi',
        detail: 'Không thể lấy roles',
        sticky: true,
      });
    }
  };
}

export default RoleController;

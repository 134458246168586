import { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { shopConfig } from '@/config/shop-config';
import actions from '@/application/actions';
import QtPrice from '@/domain/models/value-object/qtPrice';
import QtDate from '@/domain/models/value-object/qtDate';
import { useArrayChange, useInputTextChange, useQuery } from '@/hooks';
import styles from './styles.module.scss';

const createBillDetailDefault = (order) => ({
  product: order?.product?.name ?? '',
  count: order ? 1 : '',
  price: order?.price ?? '',
});

const createBillDetailsDefault = (order) => {
  const firstItem = createBillDetailDefault(order);

  return [
    firstItem,
    ...Array(9)
      .fill('')
      .map(() => createBillDetailDefault()),
  ];
};

const KhadaRetail = (props) => {
  const {
    state: {
      domain: { order },
    },
    actions: { getOrderById },
  } = props;
  console.log('🚀 ~ file: index.jsx:32 ~ KhadaRetail ~ order:', order);

  const today = new QtDate();
  const query = useQuery();
  const id = query.get('id');
  const autoPrint = query.get('autoPrint') ?? true;
  const [billDetails, setBillDetails] = useState(createBillDetailsDefault());
  const [manualDiscount, setManualDiscount] = useState(
    new QtPrice({ value: 0 }),
  );
  const [tax, setTax] = useState(0);
  const [shippingPrice, setShippingPrice] = useState(QtPrice.create('0'));
  const [paidPrice, setPaidPrice] = useState(QtPrice.create('0'));
  const updateBillDetails = useArrayChange(setBillDetails);

  const onChangeBillDetails = (index) => (e) => {
    const { name, value } = e.target;

    if (name === 'price') {
      updateBillDetails(index, value, { field: name, Model: QtPrice });
    } else {
      updateBillDetails(index, value, { field: name });
    }
  };

  const onTaxChange = useCallback((e) => setTax(e.target.value), []);

  const onPriceChange = useCallback(
    (setter) => (e) => {
      setter(QtPrice.create(e.target.value));
    },
    [],
  );

  const totalPrices = billDetails.map(({ count = 0, price = 0 }) =>
    count > 0 ? new QtPrice({ value: count * price }) : '',
  );

  const totalPrice = QtPrice.create(
    totalPrices.reduce((prev, current) => prev + current || 0, 0),
  );

  const taxPrice = QtPrice.create(
    Math.ceil((totalPrice * parseInt(tax)) / 100),
  );

  const totalPriceWithTax = QtPrice.create(
    totalPrice + taxPrice - manualDiscount + shippingPrice,
  );

  const totalPriceToPay = QtPrice.create(totalPriceWithTax - paidPrice);

  useEffect(() => {
    if (id) {
      getOrderById({ id });
    }
  }, [id]);

  useEffect(() => {
    if (order?.id && JSON.parse(autoPrint)) {
      setTimeout(window.print, 1000);
    }
  }, [order, autoPrint]);

  useEffect(() => {
    if (order?.id) {
      setBillDetails(createBillDetailsDefault(order));
    }
  }, [order]);

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <img alt="Retail Header" src={shopConfig.images.retailHeader} />
          <h3 className={styles.titleHeader}>
            Hóa đơn bán hàng<span>Số: {order.id}</span>
          </h3>
        </div>

        <div className={styles.content}>
          <div className={styles.receiver}>
            <div>
              <span className={styles.receiverHeader}>Khách hàng:</span>
              <span className={styles.receiverContent} contentEditable>
                {order.name} - {order.phones.join(' - ')}
              </span>
            </div>

            <div className={styles.receiverWrapper}>
              <span className={styles.receiverHeader}>Địa chỉ:</span>
              <span className={styles.receiverContent} contentEditable>
                {order.address}
              </span>
            </div>
          </div>

          <div className={styles.tableHeader}>
            <div className={styles.tableCell}>Sản phẩm</div>
            <div className={styles.tableCell}>SL</div>
            <div className={styles.tableCell}>Đơn giá</div>
            <div className={styles.tableCell}>Thành tiền</div>
          </div>

          <div className={styles.tableContent}>
            {billDetails.map((bill, index) => (
              <div className={styles.tableRow} key={bill.name}>
                <input
                  className={styles.tableCell}
                  value={bill.product}
                  name="product"
                  onChange={onChangeBillDetails(index)}
                />
                <input
                  className={styles.tableCell}
                  value={bill.count}
                  name="count"
                  onChange={onChangeBillDetails(index)}
                />
                <input
                  className={styles.tableCell}
                  name="price"
                  value={bill.price.vnd}
                  onChange={onChangeBillDetails(index)}
                />
                <div className={styles.tableCell}>{totalPrices[index].vnd}</div>
              </div>
            ))}

            <div className={clsx(styles.tableRow, styles.priceSection)}>
              <div
                className={clsx(styles.tableCell, styles.tableCellSpanTwo)}
              />
              <div className={styles.tableCell}>Tổng tiền</div>

              <div className={styles.tableCell}>{totalPrice.vnd}</div>
            </div>

            <div className={clsx(styles.tableRow, styles.priceSection)}>
              <div
                className={clsx(styles.tableCell, styles.tableCellSpanTwo)}
              />
              <div className={styles.tableCell}>Giảm giá</div>
              <input
                className={styles.tableCell}
                value={manualDiscount.vnd}
                onChange={onPriceChange(setManualDiscount)}
              />
            </div>

            <div className={clsx(styles.tableRow, styles.tablePriceRow, styles.priceSection)}>
              <div className={clsx(styles.tableCell, styles.priceWithLabel)}>
                <div>
                  VAT (
                  <input
                    type="text"
                    className={styles.inputVAT}
                    value={tax}
                    onChange={onTaxChange}
                  />
                  %):
                </div>

                <div>{taxPrice.vnd}</div>
              </div>

              <div className={clsx(styles.tableCell, styles.priceWithLabel)}>
                <div>SHIP:</div>

                <input
                  value={shippingPrice.vnd}
                  onChange={onPriceChange(setShippingPrice)}
                />
              </div>

              <div className={styles.tableCell}>
                Thành tiền
              </div>

              <div className={styles.tableCell}>{totalPriceWithTax.vnd}</div>
            </div>

            <div className={clsx(styles.tableRow, styles.priceSection)}>
              <div
                className={clsx(styles.tableCell, styles.tableCellSpanTwo)}
              />
              <div className={styles.tableCell}>Cọc trước</div>

              <input
                className={styles.tableCell}
                value={paidPrice.vnd}
                onChange={onPriceChange(setPaidPrice)}
              />
            </div>

            <div className={clsx(styles.tableRow, styles.priceSection)}>
              <div
                className={clsx(styles.tableCell, styles.tableCellSpanTwo)}
              />
              <div className={styles.tableCell}>Cần thanh toán</div>

              <div className={clsx(styles.tableCell, 'textBold')}>
                {totalPriceToPay.vnd}
              </div>
            </div>
          </div>

          <div className={styles.footer}>
            <p className={styles.footerText}>Khách hàng</p>

            <p className={styles.footerText}>Người giao</p>

            <div className={styles.signWrapper}>
              <p className={styles.signContent}>
                Tp.HCM, {today.fullDateVietnamese}
              </p>
              <p className={styles.signContent}>ĐẠI DIỆN CÔNG TY</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ state }) => ({ state });

export default connect(mapStateToProps, actions)(KhadaRetail);

import * as constant from './constant';
import { userService } from '@services';

const submitForm = (user, setRespondMsg, updateAccount, setShowResultModal) => {
    userService
        .update(user)
        .then((res) => res.json())
        .then((data) => {
            if (data.error) {
                setRespondMsg({
                    success: '',
                    error: data.error,
                });
            } else {
                setRespondMsg({
                    success: constant.SUCCESS_MSG,
                    error: '',
                });
                updateAccount(user);
            }
            setShowResultModal(true);
        });
};

const handleSubmitForm = (
    user,
    setRespondMsg,
    updateAccount,
    showModal,
    setShowResultModal,
) => {
    const actionTitle = 'Cập nhật tài khoản';
    const actionConfirm = `Bạn có chắc muốn cập nhật thông tin tài khoản không?`;
    const actionConduct = () =>
        submitForm(user, setRespondMsg, updateAccount, setShowResultModal);
    showModal(actionTitle, actionConfirm, actionConduct);
};

export { handleSubmitForm };

import _ from 'lodash';
import { useCallback } from 'react';

export const useOnChangeArrayField = (setFormValue) =>
  useCallback(
    (field, itemFilteredPath, itemFilteredValue, newValuePath, newValue) =>
      setFormValue(({ [field]: fieldValue, ...other }) => {
        const newFieldValue = [...fieldValue];
        const idx = newFieldValue.findIndex(
          (item) => _.get(item, itemFilteredPath) === itemFilteredValue,
        );
        newFieldValue[idx] = _.set(newFieldValue[idx], newValuePath, newValue);

        return {
          ...other,
          [field]: newFieldValue,
        };
      }),
    [],
  );

import BaseModel from '../..';

class ShipService extends BaseModel {
  static properties = {
    id: null,
    name: null,
    price: null,
  };

  constructor(obj) {
    super(obj);
  }

  /*
  id: number | null;
  name: string;
  price: null
  */
}

export default ShipService;

import _ from 'lodash';
import * as constantNotify from '../NotifyModal/constant';
import { expenseService } from '../../services';

const handleRemoveClick = (
    expenseID,
    getExpenses,
    setSelectedExpense,
    setNotifyModal,
    setNotifyModalShow,
) => {
    expenseService
        .remove(expenseID)
        .then((res) => res.json())
        .then((data) => {
            if (data.error) {
                setNotifyModal({
                    title: 'Xóa',
                    type: constantNotify.ERROR,
                    content: data.error,
                });
                setNotifyModalShow(true);
            } else {
                getExpenses();
                setSelectedExpense(0);
            }
        });
};

const handleUpdateClick = (setExpense, expense) => {
    setExpense({
        id: expense.id,
        name: expense.name,
    });
};

const handleSaveClick = (expense, setSelectedExpenseDetail) => {
    setSelectedExpenseDetail(0);
};

export { handleRemoveClick, handleUpdateClick, handleSaveClick };

import unitOfWork from '@controllers';

const submitForm = (
  ecommerce,
  setToastMessage,
  fetchEcommerces,
  closeEditButton,
) => {
  if (ecommerce.id) {
    unitOfWork.ecommerce.update(
      ecommerce,
      setToastMessage,
      fetchEcommerces,
      closeEditButton,
    );
  } else {
    unitOfWork.ecommerce.create(
      ecommerce.name,
      setToastMessage,
      fetchEcommerces,
      closeEditButton,
    );
  }
};

export default { submitForm };

import facade from '@/domain/models/utils/facade';
import { userService, masterService, stockService } from '@/domain/services';
import noteService from '@/domain/services/note/rule';

export const initializeMasterDataFromToken = {
  onDispatch: async (_) => {
    const token = localStorage.getItem('token');
    let master;
    try {
      master = await masterService.initializeMasterDataFromToken(token);
    } catch (e) {
      throw e;
    }

    return master;
  },
  fulfilled: (state, { payload }) => {
    state.masterData = payload;
  },
};

export const fetchEcommerces = {
  onDispatch: async (_, { getState }) => {
    const {
      state: { user, masterData },
    } = getState();
    const newMasterData = await masterService.fetchEcommerces({
      user,
      masterData,
    });
    return newMasterData;
  },
  fulfilled: (state, { payload }) => {
    state.masterData = payload;
  },
};

export const deleteEcommerce = {
  onDispatch: async ({ id, callback }, { getState }) => {
    const {
      state: { user, masterData },
    } = getState();
    await masterService.deleteEcommerce({ user, id });
    const newMasterData = await masterService.fetchEcommerces({
      user,
      masterData,
    });
    callback?.();

    return newMasterData;
  },
  fulfilled: (state, { payload }) => {
    state.masterData = payload;
  },
};

export const fetchShipServices = {
  onDispatch: async (_, { getState }) => {
    const {
      state: { user, masterData },
    } = getState();
    const newMasterData = await masterService.fetchShipServices({
      user,
      masterData,
    });

    return newMasterData;
  },
  fulfilled: (state, { payload }) => {
    state.masterData = payload;
  },
};

export const deleteShipService = {
  onDispatch: async ({ id, callback }, { getState }) => {
    const {
      state: { user, masterData },
    } = getState();
    await masterService.deleteShipService({ user, id });
    const newMasterData = await masterService.fetchShipServices({
      user,
      masterData,
    });
    callback?.();

    return newMasterData;
  },
  fulfilled: (state, { payload }) => {
    state.masterData = payload;
  },
};

export const getProvinces = {
  onDispatch: async (_, { getState }) => {
    const {
      state: { user },
    } = getState();
    const provinces = await masterService.getProvinces({ user });

    return provinces;
  },
  fulfilled: (state, { payload }) => {
    state.masterData = facade.update(state.master, payload);
  },
};

export const getNoteRules = {
  onDispatch: async (payload, options) => {
    const { getState } = options;
    const {
      state: { masterData, user },
    } = getState();

    const data = noteService.getNoteRules(user, masterData);

    return data;
  },
  fulfilled: (state, { payload }) => {
    const { masterData } = state;
    state.masterData = facade.update(masterData, payload);
  },
};

export const createNoteRule = {
  onDispatch: async (payload, options) => {
    const { getState } = options;
    const {
      state: { user: userState, masterData: masterDataState },
    } = getState();
    const { callback, ...data } = payload;

    await noteService.createNoteRule(data, userState);
    const masterData = await noteService.getNoteRules(
      userState,
      masterDataState,
    );
    callback?.();

    return masterData;
  },
  fulfilled: (state, { payload }) => {
    state.masterData = payload;
  },
};

export const updateNoteRule = {
  onDispatch: async (payload, options) => {
    const { getState } = options;
    const {
      state: { user: userState, masterData: masterDataState },
    } = getState();
    const { onSuccess, onError, ...data } = payload;

    await noteService.updateNoteRule(data, userState);
    const masterData = await noteService.getNoteRules(
      userState,
      masterDataState,
    );
    onSuccess?.();

    return masterData;
  },
  rejected: (state, options) => {
    const {
      payload: error,
      meta: {
        arg: { onError },
      },
    } = options;
    onError(error);
  },
  fulfilled: (state, { payload }) => {
    state.masterData = payload;
  },
};

export const updateProvinces = {
  onDispatch: async ({ data, onSuccess, onError }, { getState }) => {
    try {
      const {
        state: { user },
      } = getState();
      const result = await masterService.updateProvinces({ data, user });
      onSuccess();
      return result;
    } catch (e) {
      onError(e?.response?.data);
    }
  },
  fulfilled: (state, { payload }) => {
    if (payload) {
      state.masterData = facade.update(state.masterData, {
        provinces: payload,
      });
    }
  },
};

export const createProvinceKeyword = {
  onDispatch: async ({ data, onSuccess, onError }, { getState }) => {
    try {
      const {
        state: { user },
      } = getState();
      const result = await masterService.createProvinceKeyword({ data, user });
      onSuccess();
      return result;
    } catch (e) {
      onError(e?.response?.data);
    }
  },
  fulfilled: (state, { payload }) => {
    if (payload) {
      state.masterData = facade.update(state.masterData, {
        provinces: payload,
      });
    }
  },
};

export const deleteProvinceKeyword = {
  onDispatch: async ({ data, onSuccess, onError }, { getState }) => {
    try {
      const {
        state: { user },
      } = getState();
      const result = await masterService.deleteProvinceKeyword({ data, user });
      onSuccess();
      return result;
    } catch (e) {
      onError(e?.response?.data);
    }
  },
  fulfilled: (state, { payload }) => {
    if (payload) {
      state.masterData = facade.update(state.masterData, {
        provinces: payload,
      });
    }
  },
};

export const getAllUsers = {
  onDispatch: async ({ onSuccess, onError } = {}, { getState }) => {
    try {
      const {
        state: { user },
      } = getState();
      if (!user.token) return;
      const result = await userService.getAllUsers({ user });
      onSuccess?.();
      return result;
    } catch (e) {
      onError?.(e?.response?.data);
    }
  },
  fulfilled: (state, { payload }) => {
    if (payload) {
      state.masterData = facade.update(state.masterData, {
        users: payload,
      });
    }
  },
};

export const updateUsers = {
  onDispatch: async ({ data, onSuccess, onError } = {}, { getState }) => {
    try {
      const {
        state: { user },
      } = getState();
      const result = await userService.updateUsers({
        user,
        data,
        onSuccess,
        onError,
      });
      onSuccess?.();
      return result;
    } catch (e) {
      onError?.(e?.response?.data);
    }
  },
  fulfilled: (state, { payload }) => {
    if (payload) {
      state.masterData = facade.update(state.masterData, {
        users: payload,
      });
    }
  },
};

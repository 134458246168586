export default {
  EDITABLE_FIELDS: [
    'name',
    'address',
    'price',
    'paid',
    'productID',
    'shipServiceID',
    'ecommerceID',
    'ecommerceName',
    'phones',
    'note',
    'img',
  ],
  ORDER_STATUS: {
    WAITING: 1,
    COMPLETE: 2,
  },
  SHIP_SERVICE_ID: {
    SHOPEE: 6,
  },
  ECOMMERCE_ID: {
    SHOPEE: 7,
  },
};

import { fetcher } from '../utilities';

const url = '/api/import-bill';

const getByPage = async (cancelSignal, from, to, page, status, perPage) => {
  let query = `?from=${from}&to=${to}&page=${page}&perPage=${perPage}`;

  if (status && status > 0) {
    query += `&statusID=${status}`;
  }

  const result = await fetcher(`${url}/list${query}`, {
    signal: cancelSignal,
  });
  const data = await result.json();

  return data;
};

const create = async (importedBill) => {
  const result = await fetcher(`${url}/create`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify(importedBill),
  });
  const data = await result.json();

  return data;
};

const deleteBill = async (id) => {
  const result = await fetcher(`${url}/delete`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify({ id }),
  });
  const data = await result.json();

  return data;
};

const updateStatus = async (id, statusID) => {
  const result = await fetcher(`${url}/update/status`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify({ id, statusID }),
  });
  const data = await result.json();

  return data;
};

export { getByPage, create, deleteBill, updateStatus };

import {
  IMPORT_ORDER_STATUSES,
  IMPORT_ORDER_STATUS_NAMES,
  PRICE_TYPES,
  PRICE_TYPE_NAMES,
} from '@/config/constants';
import { _p, _pc } from '@/domain/models/utils/model-proto';
import BaseModel from '@/domain/models/entities';
import QtPrice from '@/domain/models/value-object/qtPrice';
import User from '@/domain/models/entities/user';
import QtDate from '@/domain/models/value-object/qtDate';
import ImportOrderDetails from './import-order-details';
import Supplier from '../supplier';
import { ImportOrderSchema } from '@/config/schemas';

class ImportOrder extends BaseModel {
  static properties = {
    id: null,
    createdAt: _pc(QtDate, null),
    updatedAt: _pc(QtDate, null),
    supplierId: null,
    status: null,
    totalPrice: _pc(QtPrice, null),
    picId: null,
    supplier: _p(Supplier, new Supplier()),
    details: _p(ImportOrderDetails, new ImportOrderDetails()),
    pic: _p(User, null),
  };

  constructor(obj) {
    super(obj);
  }

  /*
  id: number | null;
  */

  get displayStatus() {
    return IMPORT_ORDER_STATUS_NAMES[this.status] || '';
  }

  get displayPriceType() {
    return PRICE_TYPE_NAMES[PRICE_TYPES.IMPORT] || '';
  }

  get isComplete() {
    return this.status === IMPORT_ORDER_STATUSES.COMPLETE;
  }

  get isCancel() {
    return this.status === IMPORT_ORDER_STATUSES.CANCEL;
  }

  static validate(val) {
    const data = {
      ...val,
      details: val.details.map(({ productId, displayPrice, count }) => ({
        productId,
        displayPrice: displayPrice?.importPrice,
        count,
      })),
    };
    const { error } = ImportOrderSchema.validate(data, {
      abortEarly: false,
    });

    return error?.details;
  }

  canEdit(user /* :User */) {
    return user.isManager || (user.id === this.picId && this.createdAt.isToday);
  }
}

export default ImportOrder;

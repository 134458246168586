import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { confirmDialog } from 'primereact/confirmdialog';
import clsx from 'clsx';

import styles from './EditProduct.module.scss';
import { Button } from '@components';
import EditOnRight from './EditOnRight';
import { useCancellablePromise } from '@hooks';
import { useEffect } from 'react';
import unitOfWork from '@controllers';
import actions from '@/application/actions';

function EditProduct(props) {
  const {
    actions: { deleteProduct },
  } = props;

  const [data, setData] = React.useState([]);
  const [editButton, setEditButton] = React.useState({
    product: {
      name: '',
    },
    open: false,
  });

  const closeEditButton = React.useCallback(
    () =>
      setEditButton({
        product: {
          name: '',
        },
        open: false,
      }),
    [],
  );
  const openEditButton = React.useCallback(
    (product) => () => setEditButton({ product, open: true }),
    [],
  );
  const onDeleteProduct = useCallback(
    (product) => () =>
      confirmDialog({
        message: `Bạn có chắc chắn xóa ${product.name}?`,
        header: 'Xác nhận xóa',
        icon: 'pi pi-info-circle',
        acceptClassName: 'p-button-danger',
        accept: () =>
          deleteProduct({
            id: product.id,
            onSuccess: () => fetchProductsPages.invoke(),
          }),
      }),
  );

  const fetchProductsPages = useCancellablePromise(
    (signal) => () => {
      unitOfWork.product.getAll(signal, setData);
    },
    [],
  );

  useEffect(() => {
    fetchProductsPages.invoke();

    return () => fetchProductsPages.cancel();
  }, []);

  return (
    <div className={styles.container}>
      <h1 className={styles.header}>Trang thương mại điện tử</h1>

      <div className={styles.content}>
        <div className={styles.contentLeft}>
          <ul className={clsx(styles.contentLeftHeader, styles.row)}>
            <li className={styles.contentLeftHeaderItem}>STT</li>
            <li className={styles.contentLeftHeaderItem}>Tên</li>
            <li className={styles.contentLeftHeaderItem}>Hành động</li>
          </ul>

          <ul className={styles.contentLeftBody}>
            {data.map((item, index) => (
              <li
                key={item.id}
                className={clsx(styles.row, styles.contentLeftBodyItem)}
              >
                <span className={styles.contentLeftBodyItemText}>
                  {index + 1}
                </span>

                <span className={styles.contentLeftBodyItemText}>
                  {item.name}
                </span>

                <div className={clsx(styles.action)}>
                  <i
                    className={clsx(styles.actionIcon, 'fas fa-pen')}
                    onClick={openEditButton({
                      ...item,
                      index,
                    })}
                  />

                  <i
                    className={clsx(styles.actionIcon, 'fas fa-trash')}
                    onClick={onDeleteProduct(item)}
                  />
                </div>
              </li>
            ))}
          </ul>
        </div>

        <div className={styles.contentRight}>
          {!editButton.open ? (
            <Button
              content={
                <React.Fragment>
                  <i className={clsx('fas fa-plus', styles.btnIcon)} />
                  Thêm mới
                </React.Fragment>
              }
              onClick={openEditButton({ name: '' })}
            />
          ) : (
            <EditOnRight
              selectedProduct={editButton.product}
              closeEditButton={closeEditButton}
              setData={setData}
            />
          )}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = ({ state }) => ({ state });

export default connect(mapStateToProps, actions)(EditProduct);

import moment from 'moment';

import { _p } from '@/domain/models/utils/model-proto';
import BaseValueObject from '.';

export default class QtDate extends BaseValueObject {
  static properties = {
    value: new Date(),
  };

  constructor(obj) {
    super(obj);
  }

  /*
  value: number | null;
  */

  get date() {
    return this.value.getDate();
  }

  get month() {
    return this.value.getMonth() + 1;
  }

  get year() {
    return this.value.getFullYear();
  }

  get localDate() {
    return this.value?.toLocaleDateString('vi-VN');
  }

  get fullLocalDate() {
    return this.value?.toLocaleString('vi-VN');
  }

  get fullDateVietnamese() {
    return `ngày ${this.date} tháng ${this.month} năm ${this.year}`;
  }

  get iso() {
    return this.value?.toISOString();
  }

  get isToday() {
    return this.moment.startOf('d').diff(moment().startOf('d'), 'd') === 0;
  }

  get moment() {
    return this.value ? moment(this.value) : null;
  }

  toString() {
    return this.iso;
  }

  valueOf() {
    return this.value;
  }

  static startOf(t) {
    return new QtDate({ value: moment().startOf(t).toDate() });
  }

  static endOf(t) {
    return new QtDate({ value: moment().endOf(t).toDate() });
  }
}

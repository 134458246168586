import { endpoint } from '@/config/endpoint';
import { request } from '@/utilities/request';
import { replaceParams } from '@/utilities';

const deleteProduct = async ({ id, user }) => {
  const options = {
    endpoint: replaceParams(endpoint.productDetail, { id }),
    user,
  };
  console.log("🚀 ~ file: index.js:10 ~ deleteProduct ~ options:", options)
  const result = await request.del(options);
  console.log("🚀 ~ file: index.js:12 ~ deleteProduct ~ result:", result)

  return result;
};

export default { deleteProduct };

import { Table } from '@/components/organisms';
import styles from './style.module.scss';

const headers = {
  categories: [
    {
      field: 'category.name',
      name: 'Tên nhóm hàng',
    },
    {
      key: 'count',
      field: 'count',
      name: 'Số lượng',
    },
    {
      key: 'totalPrice',
      field: 'totalPrice.vnd',
      name: 'Tổng tiền',
    },
  ],
  products: [
    {
      field: 'product.name',
      name: 'Tên sản phẩm',
    },
    {
      key: 'count',
      field: 'count',
      name: 'Số lượng',
    },
    {
      key: 'totalPrice',
      field: 'totalPrice.vnd',
      name: 'Tổng tiền',
    },
  ],
  pics: [
    {
      field: 'pic.username',
      name: 'Tên tài khoản',
    },
    {
      field: 'pic.displayName',
      name: 'Tên nhân viên',
    },
    {
      key: 'count',
      field: 'count',
      name: 'Số lượng',
    },
    {
      key: 'totalPrice',
      field: 'totalPrice.vnd',
      name: 'Tổng tiền',
    },
  ],
};

export const Column = ({ report, columnKey, onOrderChange, orders }) => (
  <div className={styles.columnContainer}>
    <Table
      containerClassName={styles.columnTable}
      headers={headers.categories}
      data={report[columnKey]?.categories?.list || []}
      orders={orders[columnKey].categories}
      onOrderChange={onOrderChange(columnKey, 'categories')}
    />

    <Table
      containerClassName={styles.columnTable}
      headers={headers.products}
      data={report[columnKey]?.products?.list || []}
      orders={orders[columnKey].products}
      onOrderChange={onOrderChange(columnKey, 'products')}
    />

    <Table
      containerClassName={styles.columnTable}
      headers={headers.pics}
      data={report[columnKey]?.pics?.list || []}
      orders={orders[columnKey].pics}
      onOrderChange={onOrderChange(columnKey, 'pics')}
    />
  </div>
);

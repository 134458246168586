import { _p } from '../../../utils/model-proto';
import BaseModel from '../..';
import Category from '../category';

class Categories extends BaseModel {
  static properties = {
    list: _p(Category, new Category()),
  };

  constructor(obj) {
    super(obj);
  }

  /*
  list: Category;
  */

  get listNames() {
    return this.list.map((item) => item.name);
  }

  get options() {
    const listItems = this.list.map(({ id, name }) => ({
      label: name,
      value: id,
    }));
    const selectAll = {
      label: 'Chọn tất cả',
      value: 0,
    };

    return [selectAll, ...listItems];
  }
}

export default Categories;

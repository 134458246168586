export const tabItems = [
  {
    link: '/market#detail',
    name: 'Thống kê chi tiết',
    phone: <i className="fa-solid fa-chart-pie" />,
  },
  {
    link: '/market#total',
    name: 'Thống kê tổng',
    phone: <i className="fa-solid fa-chart-pie" />,
  },
];

import { _p } from '../../utils/model-proto';
import BaseModel from '..';
import DisplayUser from './partial';

class DisplayUsers extends BaseModel {
  static properties = {
    list: _p(DisplayUser, new DisplayUser()),
  };

  constructor(obj) {
    super(obj);
  }

  /*
  list: DisplayUser[];
  */
}

export default DisplayUsers;

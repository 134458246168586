const makeCancellablePromise = (run) => {
    const cancelController = new AbortController();
    const signal = cancelController.signal;

    run(signal);

    return cancelController;
};

export default makeCancellablePromise;

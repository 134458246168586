import { _p } from '@/domain/models/utils/model-proto';
import BaseModel from '@/domain/models/entities';
import Supplier from '../supplier';

class Suppliers extends BaseModel {
  static properties = {
    list: _p(Supplier, new Supplier()),
    count: null,
    totalPage: null,
  };

  constructor(obj) {
    super(obj);
  }

  /*
  list: Supplier[];
  count: number | null;
  */
}

export default Suppliers;

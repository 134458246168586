import { UserContext } from '@contexts/App';
import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

function ProtectedRoute({ children, isInitial }) {
  const location = useLocation();
  const navigate = useNavigate();
  const userContext = useContext(UserContext);

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  useEffect(async () => {
    if (userContext.token) {
      setIsAuthenticated(true);
    } else {
      navigate(`sign-out?redirect=${location.pathname}`);
    }
  }, [userContext]);

  if (!isInitial) return <></>;
  return isAuthenticated && children;
}

export default connect(({ state: { ui } }) => ({ isInitial: ui.isInitial }))(
  ProtectedRoute,
);

import { _p, _pc } from '@/domain/models/utils/model-proto';
import BaseModel from '../..';
import ShipService from '../shipService';

class Province extends BaseModel {
  static properties = {
    id: null,
    name: null,
    slugs: null,
    keywords: null,
    normalizedName: null,
    shipServiceId: null,
    shipService: _p(ShipService, new ShipService()),
  };

  constructor(obj) {
    super(obj);
  }

  /*
  id: number | null;
  name: string;
  slug: string[]
  shipServiceId: number | null
  shipService: ShipService
  */

  isInAddress(slugAddr /*: string*/) /*: boolean */ {
    return this.slugs.find((slug) => slugAddr.includes(slug));
  }
}

export default Province;

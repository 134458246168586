import {useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { ConfirmDialog } from 'primereact/confirmdialog';

import actions from '@/application/actions';
import { Toast } from 'primereact/toast';
import AppRoute from './Route';

function App(props) {
  const {
    actions: { initializeUI },
  } = props;
  const toastRef = useRef(null);

  useEffect(() => {
    if (toastRef.current) {
      initializeUI({ toastRef: toastRef.current });
    }
  }, [toastRef.current]);

  return (
    <>
      <ConfirmDialog />
      <Toast ref={toastRef} />
      <AppRoute />
    </>
  );
}

export default connect(null, actions)(App);

import clsx from 'clsx';
import { memo } from 'react';
import styles from './style.module.scss';

export const TwoColumns = memo(({ children, className }) => {
  return (
    <div
      className={clsx(styles.container, {
        [className]: className,
      })}
    >
      {children}
    </div>
  );
});

import { fetcher } from '../utilities';

const urlOrigin = '/api/store-product';

const getAll = async (cancelSignal, page, perPage, name) => {
  let query = `?page=${page}&perPage=${perPage}`;

  if (name) {
    query += `&name=${name}`;
  }

  const result = await fetcher(`${urlOrigin}/list${query}`, {
    signal: cancelSignal,
  });
  const data = await result.json();

  return data;
};

const create = async (storeProduct) => {
  const result = await fetcher(`${urlOrigin}/create`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify(storeProduct),
  });
  const data = await result.json();

  return data;
};

const update = async (storeProduct) => {
  const result = await fetcher(`${urlOrigin}/update`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify(storeProduct),
  });
  const data = await result.json();

  return data;
};

const deleteById = async (id) => {
  const result = await fetcher(`${urlOrigin}/delete`, {
    method: 'DELETE',
    body: JSON.stringify({ id }),
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  });
  const data = await result.json();

  return data;
};
export { getAll, create, update, deleteById };

import stockService from '@/domain/services/stock';
import { TOAST_SEVERITY } from '@/config/constants';
import facade from '@/domain/models/utils/facade';

export const getItems = {
  onDispatch: async ({ keyword, perPage, page = 1, callback }, options) => {
    const { getState } = options;
    const stock = await stockService.getItems({
      ...getState().state,
      keyword,
      perPage,
      page,
    });
    callback?.();

    return stock;
  },
  fulfilled: (state, { payload }) => {
    state.stock = payload;
  },
};

export const createItem = {
  onDispatch: async ({ data, callback }, options) => {
    const { getState } = options;
    const {
      state: { user },
    } = getState();
    const { categories } = data;
    data.categories = Object.keys(categories)
      .map((id) => id)
      .filter((id) => categories[id]);

    await stockService.createItem({ data, user });
    callback?.();
  },
  rejected: (state, ...options) => {
    state.ui.toast = {
      isOpen: true,
      type: TOAST_SEVERITY.ERROR,
      title: 'Thất bại',
      content: 'Tạo sản phẩm thất bại',
    };
  },
  fulfilled: (state) => {
    state.ui.toast = {
      ...state.ui.toast,
      type: TOAST_SEVERITY.SUCCESS,
      title: 'Thành công',
      content: 'Tạo sản phẩm thành công',
    };
  },
};

export const updateItem = {
  onDispatch: async ({ data, callback }, options) => {
    const { getState } = options;
    const {
      state: { user },
    } = getState();
    const { categories } = data;
    data.categories = Object.keys(categories)
      .map((id) => id)
      .filter((id) => categories[id]);

    await stockService.updateItem({ data, user });
    callback?.();
  },
  rejected: (state, ...options) => {
    state.ui.toast = {
      isOpen: true,
      type: TOAST_SEVERITY.ERROR,
      title: 'Thất bại',
      content: 'Cập nhật sản phẩm thất bại',
    };
  },
  fulfilled: (state) => {
    state.ui.toast = {
      ...state.ui.toast,
      type: TOAST_SEVERITY.SUCCESS,
      title: 'Cập nhật sản phẩm thành công',
    };
  },
};

export const exportQtProducts = {
  onDispatch: async (
    { onSuccess, onError, currentFilter, ...data },
    { getState },
  ) => {
    const {
      state: { user },
    } = getState();
    try {
      await stockService.exportQtProducts({ user, data });
      const qtProducts = await stockService.fetchQtProducts({
        user,
        ...currentFilter,
      });
      onSuccess?.();
      return { qtProducts };
    } catch (e) {
      onError?.(e);
      throw e;
    }
  },
  fulfilled: (state, { payload }) => {
    state.stock = facade.update(state.stock, payload);
  },
};

export const fetchExportOrders = {
  onDispatch: async (_, { getState }) => {
    const {
      state: { user, stock },
    } = getState();
    const newStock = await stockService.fetchExportOrders({
      user,
      stock,
      query: stock.filter.exportOrders.value,
    });

    return newStock;
  },
  fulfilled: (state, { payload }) => {
    state.stock = payload;
  },
};

export const fetchExportOrder = {
  onDispatch: async ({ id, isOpenModal }, { getState }) => {
    const {
      state: { user, stock },
    } = getState();
    const newStock = await stockService.fetchExportOrder({
      user,
      stock,
      id,
    });

    return {
      stock: newStock,
      isOpenModal,
    };
  },
  fulfilled: (state, { payload }) => {
    const { stock, isOpenModal } = payload;
    state.stock = stock;
    state.ui.isExportOrderDetailModalOpen = isOpenModal;
  },
};

export const changeExportOrderStatus = {
  onDispatch: async ({ status, onSuccess, onError }, { getState }) => {
    const {
      state: { user, stock },
    } = getState();

    try {
      const newStock = await stockService.changeExportOrderStatus({
        user,
        stock,
        id: stock.exportOrder.id,
        status,
      });
      const newStockWithExportOrders = await stockService.fetchExportOrders({
        user,
        stock: newStock,
        query: stock.filter.exportOrders.value,
      });
      onSuccess?.();

      return {
        stock: newStockWithExportOrders,
      };
    } catch (e) {
      onError?.(e);
      throw e;
    }
  },
  fulfilled: (state, { payload }) => {
    const { stock } = payload;

    state.stock = stock;
  },
};

export const cancelExportOrder = {
  onDispatch: async (payload, { getState }) => {
    const { onSuccess, onError } = payload || {};

    const {
      state: { user, stock },
    } = getState();

    try {
      const newStock = await stockService.cancelExportOrder({
        user,
        stock,
        id: stock.exportOrder.id,
      });
      const newStockWithExportOrders = await stockService.fetchExportOrders({
        user,
        stock: newStock,
        query: stock.filter.exportOrders.value,
      });
      onSuccess?.();

      return {
        stock: newStockWithExportOrders,
      };
    } catch (e) {
      onError?.(e);
      throw e;
    }
  },
  fulfilled: (state, { payload }) => {
    const { stock } = payload;

    state.stock = stock;
  },
};

export const fetchQtProducts = {
  onDispatch: async (query, { getState }) => {
    const {
      state: { user },
    } = getState();
    const qtProducts = await stockService.fetchQtProducts({
      user,
      ...query,
    });

    return { qtProducts };
  },
  fulfilled: (state, { payload }) => {
    state.stock = facade.update(state.stock, payload);
  },
};

export const fetchQtProduct = {
  onDispatch: async ({ id, isOpenModal }, { getState }) => {
    const {
      state: { user, stock },
    } = getState();
    const newStock = await stockService.fetchQtProduct({
      user,
      stock,
      id,
    });

    return {
      stock: newStock,
      isOpenModal,
    };
  },
  fulfilled: (state, { payload }) => {
    const { stock, isOpenModal } = payload;
    state.stock = stock;
    state.ui.qtProductModal = {
      isOpen: isOpenModal,
      isCreate: false,
    };
  },
};

export const createQtProduct = {
  onDispatch: async ({ data, fromModal }, { getState }) => {
    const {
      state: {
        user,
        stock: {
          filter: { qtProducts: filter },
        },
        stock,
      },
    } = getState();
    delete data.id;
    const qtProduct = await stockService.createQtProduct({
      user,
      data,
    });
    const qtProducts = await stockService.fetchQtProducts({
      user,
      ...filter,
    });
    return {
      stock: { qtProduct, qtProducts },
      fromModal,
    };
  },
  fulfilled: (state, { payload }) => {
    const { stock, fromModal } = payload;
    state.stock = facade.update(state.state, stock);
    state.ui.toastRef.show({
      message: 'Tạo thành công',
      type: TOAST_SEVERITY.SUCCESS,
    });

    if (fromModal) {
      state.ui.qtProductModal = { isOpen: true, isCreate: false };
    }
  },
};

export const updateQtProduct = {
  onDispatch: async ({ data }, { getState }) => {
    const {
      state: {
        user,
        stock: {
          filter: { qtProducts: filter },
        },
        stock,
      },
    } = getState();
    const qtProduct = await stockService.updateQtProduct({
      user,
      data,
    });
    const qtProducts = await stockService.fetchQtProducts({
      user,
      ...filter,
    });
    return {
      qtProduct,
      qtProducts,
    };
  },
  fulfilled: (state, { payload }) => {
    state.stock = facade.update(state.stock, payload);
    state.ui.toastRef.show({
      message: 'Cập nhật thành công',
      type: TOAST_SEVERITY.SUCCESS,
    });
  },
};

export const deleteQtProduct = {
  onDispatch: async ({ id }, { getState }) => {
    const {
      state: {
        user,
        stock: {
          filter: { qtProducts: filter },
        },
        stock,
      },
    } = getState();
    await stockService.deleteQtProduct({
      user,
      id,
    });
    const qtProducts = await stockService.fetchQtProducts({
      user,
      ...filter,
    });

    return { qtProducts };
  },
  fulfilled: (state, { payload }) => {
    state.stock = facade.update(state.stock, payload);
  },
};

export const fetchSuppliers = {
  onDispatch: async (query, { getState }) => {
    const {
      state: { user, stock },
    } = getState();
    const suppliers = await stockService.fetchSuppliers({
      user,
      ...query,
    });

    return { suppliers };
  },
  fulfilled: (state, { payload }) => {
    state.stock = facade.update(state.stock, payload);
  },
};

export const fetchSupplier = {
  onDispatch: async ({ id, isOpenModal }, { getState }) => {
    const {
      state: { user, stock },
    } = getState();
    const newStock = await stockService.fetchSupplier({
      user,
      stock,
      id,
    });

    return {
      stock: newStock,
      isOpenModal,
    };
  },
  fulfilled: (state, { payload }) => {
    const { stock, isOpenModal } = payload;
    state.stock = stock;
    state.ui.supplierModal = {
      isOpen: isOpenModal,
      isCreate: false,
    };
  },
};

export const createSupplier = {
  onDispatch: async ({ data, fromModal }, { getState }) => {
    const {
      state: {
        user,
        stock: {
          filter: { suppliers: filter },
        },
        stock,
      },
    } = getState();
    delete data.id;
    const supplier = await stockService.createSupplier({
      user,
      data,
    });
    const suppliers = await stockService.fetchSuppliers({
      user,
      ...filter,
    });
    return {
      stock: { supplier, suppliers },
      fromModal,
    };
  },
  fulfilled: (state, { payload }) => {
    const { stock, fromModal } = payload;
    state.stock = facade.update(state.stock, stock);
    state.ui.toastRef.show({
      message: 'Tạo thành công',
      type: TOAST_SEVERITY.SUCCESS,
    });

    if (fromModal) {
      state.ui.supplierModal = { isOpen: true, isCreate: false };
    }
  },
};

export const updateSupplier = {
  onDispatch: async ({ data }, { getState }) => {
    const {
      state: {
        user,
        stock: {
          filter: { suppliers: filter },
        },
        stock,
      },
    } = getState();
    const supplier = await stockService.updateSupplier({
      user,
      data,
    });
    const suppliers = await stockService.fetchSuppliers({
      user,
      ...filter,
    });
    return {
      supplier,
      suppliers,
    };
  },
  fulfilled: (state, { payload }) => {
    state.stock = facade.update(state.stock, payload);

    state.ui.toastRef.show({
      message: 'Cập nhật thành công',
      type: TOAST_SEVERITY.SUCCESS,
    });
  },
};

export const deleteSupplier = {
  onDispatch: async ({ id }, { getState }) => {
    const {
      state: {
        user,
        stock: {
          filter: { suppliers: filter },
        },
        stock,
      },
    } = getState();
    await stockService.deleteSupplier({
      user,
      id,
    });
    const suppliers = await stockService.fetchSuppliers({
      user,
      ...filter,
    });

    return { suppliers };
  },
  fulfilled: (state, { payload }) => {
    state.stock = facade.update(state.stock, payload);
  },
};

export const fetchImportOrders = {
  onDispatch: async (_, { getState }) => {
    const {
      state: { user, stock },
    } = getState();
    const importOrders = await stockService.fetchImportOrders({
      user,
      query: stock.filter.importOrders.value,
    });

    return { importOrders };
  },
  fulfilled: (state, { payload }) => {
    state.stock = facade.update(state.stock, payload);
  },
};

export const fetchImportOrder = {
  onDispatch: async ({ id, isOpenModal }, { getState }) => {
    const {
      state: { user, stock },
    } = getState();
    const importOrder = await stockService.fetchImportOrder({
      user,
      id,
    });

    return {
      stock: { importOrder },
      isOpenModal,
    };
  },
  fulfilled: (state, { payload }) => {
    const { stock, isOpenModal } = payload;
    state.stock = facade.update(state.stock, stock);
    state.ui.importOrderDetailModal = {
      isOpen: isOpenModal,
      isCreate: false,
    };
  },
};

export const cancelImportOrder = {
  onDispatch: async (payload, { getState }) => {
    const { onSuccess, onError } = payload || {};

    const {
      state: { user, stock },
    } = getState();

    try {
      const importOrder = await stockService.cancelImportOrder({
        user,
        id: stock.importOrder.id,
      });
      const importOrders = await stockService.fetchImportOrders({
        user,
        query: stock.filter.exportOrders.value,
      });
      onSuccess?.();

      return {
        importOrder,
        importOrders,
      };
    } catch (e) {
      onError?.(e);
      throw e;
    }
  },
  fulfilled: (state, { payload }) => {
    state.stock = facade.update(state.stock, payload);
  },
};

export const completeImportOrder = {
  onDispatch: async (payload, { getState }) => {
    const { onSuccess, onError } = payload || {};

    const {
      state: { user, stock },
    } = getState();

    try {
      const importOrder = await stockService.completeImportOrder({
        user,
        id: stock.importOrder.id,
      });
      const importOrders = await stockService.fetchImportOrders({
        user,
        query: stock.filter.exportOrders.value,
      });
      onSuccess?.();

      return {
        importOrder,
        importOrders,
      };
    } catch (e) {
      onError?.(e);
      throw e;
    }
  },
  fulfilled: (state, { payload }) => {
    state.stock = facade.update(state.stock, payload);
  },
};

export const importQtProducts = {
  onDispatch: async (
    { onSuccess, onError, currentFilter, ...data },
    { getState },
  ) => {
    const {
      state: { user },
    } = getState();
    try {
      await stockService.importQtProducts({ user, data });
      const qtProducts = await stockService.fetchQtProducts({
        user,
        ...currentFilter,
      });
      onSuccess?.();
      return { qtProducts };
    } catch (e) {
      onError?.(e);
      throw e;
    }
  },
  fulfilled: (state, { payload }) => {
    state.stock = facade.update(state.stock, payload);
  },
};

export const fetchStockReport = {
  onDispatch: async ({ onSuccess, onError, currentFilter }, { getState }) => {
    const {
      state: { user },
    } = getState();
    try {
      const report = await stockService.fetchStockReport({
        user,
        ...currentFilter,
      });
      onSuccess?.();
      return { report };
    } catch (e) {
      onError?.(e);
      throw e;
    }
  },
  fulfilled: (state, { payload }) => {
    state.stock = facade.update(state.stock, payload);
  },
};

import { connect } from 'react-redux';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import QtProduct from '@/domain/models/entities/stock/qt-product';
import QtPrice from '@/domain/models/value-object/qtPrice';

import actions from '@/application/actions';
import { TOAST_SEVERITY } from '@/config/constants';
import { FORM } from '@config/ui';
import { Form } from '@/components/organisms';
import QtModal from '../QtModal';
import styles from './styles.module.scss';

const mapStateToProps = ({ state }) => ({ state });

const defaultValue = {
  id: null,
  name: '',
  count: 0,
  importPrice: 0,
  price: 0,
  wholeSalePrice: 0,
};

export const QtProductDetailModal = connect(
  mapStateToProps,
  actions,
)((props) => {
  const {
    state: {
      stock: { qtProduct },
      ui: { qtProductModal, toastRef, isError },
    },
    actions: {
      toggleQtProductModal,
      createQtProduct,
      updateQtProduct,
      toggleError,
    },
  } = props;

  const [newQtProduct, setNewQtProduct] = useState(QtProduct.new(defaultValue));
  const onClose = () =>
    toggleQtProductModal({ isOpen: false, isCreate: false });

  const onFormFieldChange = (e) => {
    const { name } = e.target;

    let { value } = e.target;
    if (QtPrice === newQtProduct[name].constructor) {
      value = parseInt(value.replaceAll('.', ''));
    }

    setNewQtProduct(newQtProduct.setItem({ [name]: value }));
  };

  const fields = [
    {
      type: FORM.TYPE.INPUT,
      label: 'Tên sản phẩm',
      key: 'name',
      name: 'name',
      onChange: onFormFieldChange,
    },
    {
      type: FORM.TYPE.INPUT,
      label: 'Số lượng',
      key: 'count',
      name: 'count',
      onChange: onFormFieldChange,
    },
    {
      type: FORM.TYPE.INPUT,
      label: 'Giá nhập',
      key: 'importPrice.vnd',
      name: 'importPrice',
      onChange: onFormFieldChange,
    },
    {
      type: FORM.TYPE.INPUT,
      label: 'Giá lẻ',
      key: 'price.vnd',
      name: 'price',
      onChange: onFormFieldChange,
    },
    {
      type: FORM.TYPE.INPUT,
      label: 'Giá sỉ',
      key: 'wholeSalePrice.vnd',
      name: 'wholeSalePrice',
      onChange: onFormFieldChange,
    },
  ];

  const onSubmit = () => {
    const error = newQtProduct.validate();
    if (error) {
      toastRef.show({
        type: TOAST_SEVERITY.ERROR,
        message: error.map(({ message }) => <p>{message}</p>),
        callback: () =>
          toggleError({
            isError: true,
            callback: () =>
              setTimeout(() => toggleError({ isError: false }), 1500),
          }),
        life: 1500,
      });
    } else {
      if (qtProductModal.isCreate)
        createQtProduct({
          data: newQtProduct,
          fromModal: true,
        });
      else {
        const data = qtProduct.diff(newQtProduct);

        if (data) {
          updateQtProduct({ data });
        } else {
          toggleQtProductModal({ isOpen: false });
        }
      }
    }
  };

  useEffect(() => {
    setNewQtProduct(
      qtProductModal.isCreate ? QtProduct.new(defaultValue) : qtProduct,
    );
  }, [qtProductModal, qtProduct]);

  return (
    <QtModal
      contentContainer={styles.contentContainer}
      isOpen={qtProductModal.isOpen}
      onClose={onClose}
    >
      <h3 className={styles.header}>
        {qtProductModal.isCreate
          ? 'Tạo mới'
          : `Sản phẩm số #${newQtProduct.id}`}
      </h3>

      <Form
        className={styles.form}
        onSubmit={onSubmit}
        value={newQtProduct}
        fields={fields}
        submitText={qtProductModal.isCreate ? 'Tạo mới' : 'Cập nhật'}
        submitDisabled={isError}
      />
    </QtModal>
  );
});

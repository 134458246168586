import React, { useCallback, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import _ from 'lodash';

import { PaginationBar, ToastMessage } from '@components';
import { useThrottle } from '@hooks';
import {
  PRICE_TYPES,
  PRICE_TYPE_NAMES,
  TOAST_SEVERITY,
} from '@config/constants';
import toastMessageType from '@constant/ToastMessage';
import styles from './ExportQtProducts.module.scss';
import actions from '@/application/actions';
import { useState } from 'react';
import { Select } from '@/components/atoms';
import {
  useCalculatePrice,
  useClearArrayField,
  useOnChangeArrayField,
  useRemoveItem,
} from '@/hooks';
import QtPrice from '@/domain/models/value-object/qtPrice';

const PRICE_LIST = [PRICE_TYPES.RETAIL, PRICE_TYPES.WHOLE_SALE];

const createInitialFormState = () => ({
  details: [],
  priceTypeId: PRICE_TYPES.RETAIL,
});

function ExportQtProducts(props) {
  const {
    state: {
      masterData: { categories },
      stock: { qtProducts, filter },
      user,
      ui: { toastRef, isError },
    },
    actions: {
      fetchQtProducts,
      exportQtProducts,
      updateQtProductFilter,
      toggleError,
      confirmInfo,
    },
  } = props;

  const [toastMessage, setToastMessage] = React.useState({
    isVisible: false,
    type: toastMessageType.ERROR,
    msg: {
      title: '',
      duration: 3000,
      desc: '',
    },
  });

  const [formValue, setFormValue] = useState(createInitialFormState());
  const calcPrice = useCalculatePrice();
  const removeItem = useRemoveItem(setFormValue);
  const onChangeArrayField = useOnChangeArrayField(setFormValue);
  const onClearArrayField = useClearArrayField(setFormValue);

  const totalPrice = useMemo(
    () =>
      formValue.details.reduce(
        (prev, { totalPrice }) => (prev += totalPrice),
        0,
      ),
    [formValue],
  );

  const updateQtProductFilterThrottle = useThrottle(updateQtProductFilter, 500);
  const updateProductPage = (newProductPage) =>
    updateQtProductFilterThrottle({
      page: newProductPage,
    });
  const handleQtProductFilterInputChange = (e) =>
    updateQtProductFilterThrottle({ [e.target.name]: e.target.value });

  const getInvalidProducts = () =>
    formValue.details.filter(
      ({ product, quantity }) => product.count < quantity,
    );

  const onChangePriceType = (newPriceTypeId, withPrice) => () =>
    setFormValue(({ details }) => ({
      priceTypeId: newPriceTypeId,
      details: withPrice
        ? calcPrice(
            details.map((detail) => ({
              ...detail,
              displayPrice: detail.product.displayPrice(newPriceTypeId),
            })),
          )
        : details,
    }));
  const handleSearchPriceTypeChange = useCallback(
    (e) =>
      confirmInfo({
        message: 'Thay đổi cả đơn giá trong đơn hiện tại?',
        onAccept: onChangePriceType(e.target.value, true),
        onReject: onChangePriceType(e.target.value, false),
      }),
    [],
  );

  const handleInsertProduct = useCallback(
    (qtProduct) => () => {
      let newDetails = [...formValue.details];
      const prevDetail = newDetails.find(
        (detail) => detail.product.id === qtProduct.id,
      );

      const displayPrice = qtProduct.displayPrice(formValue.priceTypeId);

      if (prevDetail) {
        prevDetail.quantity += 1;
      } else {
        newDetails.push({
          product: qtProduct,
          displayPrice,
          quantity: 1,
        });
      }

      setFormValue({
        ...formValue,
        details: calcPrice(newDetails),
      });
    },
    [formValue],
  );

  const handleRowBlur = useCallback(
    () =>
      setFormValue((formValue) => ({
        ...formValue,
        details: calcPrice(formValue.details).filter(
          ({ quantity }) => quantity,
        ),
      })),
    [],
  );

  const handleProductQuantityChange = useCallback(
    (qtProduct) => (e) => {
      const newDetails = [...formValue.details];
      const index = newDetails.findIndex(
        (detail) => detail.product.id === qtProduct.id,
      );
      const value = parseInt(e.target.value);
      if (Number.isNaN(value)) {
        newDetails[index].quantity = 0;
        setFormValue({
          ...formValue,
          details: newDetails,
        });
      } else {
        newDetails[index].quantity = value;

        setFormValue({
          ...formValue,
          details: newDetails,
        });
      }
    },
    [formValue],
  );
  const handleProductPriceChange = useCallback(
    ({ id }) =>
      (e) =>
        onChangeArrayField(
          'details',
          'product.id',
          id,
          'displayPrice',
          QtPrice.create(e.target.value),
        ),
  );

  const handleRemoveProduct = useCallback(
    (id) => () => removeItem('details', 'product.id', id),
  );

  const onExportQtProducts = () =>
    exportQtProducts({
      priceTypeId: formValue.priceTypeId,
      details: formValue.details.map((detail) => ({
        productId: detail.product.id,
        count: detail.quantity,
        displayPrice: detail.displayPrice.value,
      })),
      currentFilter: filter.qtProducts.value,
      onSuccess: () => {
        setFormValue(createInitialFormState());
        setToastMessage({
          isVisible: true,
          type: toastMessageType.SUCCESS,
          msg: {
            title: 'Thành công',
            duration: 3000,
            desc: 'Tạo đơn thành công',
          },
        });
      },
      onError: () => {
        setToastMessage({
          isVisible: true,
          type: toastMessageType.ERROR,
          msg: {
            title: 'Thất bại',
            duration: 3000,
            desc: 'Tạo đơn thất bại',
          },
        });
      },
    });

  const handleSaveClick = useCallback(() => {
    const invalidProducts = getInvalidProducts();
    let errorMsg = '';

    if (invalidProducts.length) {
      errorMsg = (
        <>
          <p>Không đủ số lượng sản phẩm</p>
          {invalidProducts.map(({ product }) => (
            <p key={product.id}>
              <b>{product.name}</b>
            </p>
          ))}
        </>
      );
    } else if (!formValue.details.length) {
      errorMsg = 'Chưa chọn sản phẩm';
    }

    if (errorMsg) {
      toastRef.show({
        type: TOAST_SEVERITY.ERROR,
        message: errorMsg,
        timeout: 2000,
        callback: () =>
          toggleError({
            isError: true,
            callback: () =>
              setTimeout(() => toggleError({ isError: false }), 2000),
          }),
      });
      return;
    }

    confirmInfo({
      message: (
        <>
          <h3>Bạn có chắc chắn tạo đơn này không?</h3>
          {formValue.details.map(({ product, quantity }) => (
            <p key={product.id} className={styles.confirmText}>
              <span>{product.name}</span>
              <span>x{quantity}</span>
            </p>
          ))}
        </>
      ),
      onAccept: onExportQtProducts,
    });
  }, [formValue]);

  const handleClearFields = () => onClearArrayField('details');

  useEffect(() => {
    const { qtProducts: qtProductFilter } = filter;
    if (qtProductFilter.name.length === 0 || qtProductFilter.name.length > 1) {
      fetchQtProducts(qtProductFilter.value);
    }
  }, [filter.qtProducts]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.productContainer}>
        <input
          className={styles.searchProduct}
          placeholder="Tìm kiếm theo tên sản phẩm..."
          name="name"
          onChange={handleQtProductFilterInputChange}
        />

        <Select
          className={styles.searchProduct}
          value={filter.qtProducts.categoryId}
          name="categoryId"
          options={categories?.options || []}
          onChange={handleQtProductFilterInputChange}
        />

        <div className={styles.productList}>
          <div className={styles.productResult}>
            {qtProducts.list.map((qtProduct) => (
              <div
                className={styles.productItem}
                key={qtProduct.id}
                onClick={handleInsertProduct(qtProduct)}
              >
                <h3>{qtProduct.name}</h3>

                <div className={styles.productItemFooter}>
                  <span className={styles.productItemFooterPrice}>
                    {qtProduct.displayPrice(formValue.priceTypeId).vnd}
                  </span>
                  <span className={styles.productItemFooterXMark}>x</span>
                  <span
                    className={clsx(styles.productItemFooterCount, {
                      [styles.hasStock]: qtProduct.count,
                    })}
                  >
                    {qtProduct.count} cái {!qtProduct.count && '(Hết hàng)'}
                  </span>
                </div>
              </div>
            ))}
          </div>

          <PaginationBar
            totalPage={qtProducts.totalPage ?? 0}
            noPages={5}
            columnWidth={40}
            currentPage={filter.qtProducts.page}
            setCurrentPage={updateProductPage}
          />
        </div>
      </div>

      <div className={styles.invoiceContainer}>
        <div className={styles.invoiceUser}>
          <h3>Được tạo bởi {user.displayName}</h3>

          <div
            className={clsx(
              styles.invoiceDetailsBodyItem,
              styles.invoiceDetailsBodyItemAction,
            )}
            onDoubleClick={handleClearFields}
          >
            <i
              className={clsx(
                styles.invoiceDetailsBodyItemIcon,
                'fas fa-trash',
              )}
            />
          </div>
        </div>

        <div className={styles.invoiceDetails}>
          <div className={clsx(styles.invoiceDetailsHeader, styles.row)}>
            <div className={styles.invoiceDetailsHeaderItem} />
            <div className={styles.invoiceDetailsHeaderItem}>Tên sản phẩm</div>
            <div className={styles.invoiceDetailsHeaderItem}>Số lượng</div>
            <div className={styles.invoiceDetailsHeaderItem}>Đơn giá</div>
            <div className={styles.invoiceDetailsHeaderItem}>Thành tiền</div>
          </div>

          <div className={clsx(styles.invoiceDetailsBody)}>
            {formValue.details.map((invoiceDetail) => (
              <div
                className={clsx(styles.row)}
                key={`invoice_${invoiceDetail.product.id}`}
                onBlur={handleRowBlur}
              >
                <div
                  className={clsx(
                    styles.invoiceDetailsBodyItem,
                    styles.invoiceDetailsBodyItemAction,
                  )}
                  onDoubleClick={handleRemoveProduct(invoiceDetail.product.id)}
                >
                  <i
                    className={clsx(
                      styles.invoiceDetailsBodyItemIcon,
                      'fas fa-trash',
                    )}
                  />
                </div>
                <div className={styles.invoiceDetailsBodyItem}>
                  {invoiceDetail.product.name}
                </div>

                <div className={styles.invoiceDetailsBodyItem}>
                  <input
                    value={invoiceDetail.quantity}
                    onChange={handleProductQuantityChange(
                      invoiceDetail.product,
                    )}
                  />
                </div>
                <div className={styles.invoiceDetailsBodyItem}>
                  <input
                    value={invoiceDetail.displayPrice.vnd}
                    onChange={handleProductPriceChange(invoiceDetail.product)}
                  />
                </div>
                <div className={styles.invoiceDetailsBodyItem}>
                  {invoiceDetail.totalPrice &&
                    invoiceDetail.totalPrice.toLocaleString('vi-VN', {
                      style: 'currency',
                      currency: 'VND',
                    })}
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className={styles.invoiceInfoTotal} data-label="Tạm tính">
          {totalPrice &&
            totalPrice.toLocaleString('vi-VN', {
              style: 'currency',
              currency: 'VND',
            })}
        </div>
      </div>

      <div className={styles.invoiceInfoContainer}>
        <select
          className={styles.invoiceInfoSelect}
          value={formValue.priceTypeId}
          onChange={handleSearchPriceTypeChange}
        >
          {PRICE_LIST.map((priceTypeId) => (
            <option key={priceTypeId} value={priceTypeId}>
              {PRICE_TYPE_NAMES[priceTypeId]}
            </option>
          ))}
        </select>

        <button
          className={styles.invoiceInfoButton}
          onClick={handleSaveClick}
          disabled={isError}
        >
          <i className={clsx(styles.invoiceInfoButtonIcon, 'fas fa-save')} />
          Tạo đơn
        </button>
      </div>

      <ToastMessage open={toastMessage} setOpen={setToastMessage} />
    </div>
  );
}

const mapStateToProps = ({ state }) => ({ state });

export default connect(mapStateToProps, actions)(ExportQtProducts);

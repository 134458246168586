import { useCallback } from 'react';

export const useDateChange = (
  setter,
  options = {
    keys: {
      from: 'from',
      to: 'to',
    },
    relativeChanges: {},
  },
) => {
  const handleDateChange = (key) => (date) =>
    setter({
      ...options.relativeChanges,
      [key]: date,
    });

  const handleBothDateChange = useCallback(
    (from, to) =>
      setter({
        ...options.relativeChanges,
        [options.keys?.from ?? 'from']: from,
        [options.keys?.to ?? 'to']: to,
      }),
    [],
  );

  return {
    handleDateChange,
    handleBothDateChange,
  };
};

const { useCallback } = require('react');

export const useClearArrayField = (setFormValue) =>
  useCallback(
    (field) =>
      setFormValue((prev) => ({
        ...prev,
        [field]: [],
      })),
    [],
  );

import { statisticService } from '@services';

class StatisticController {
    statisticProfitByType = async (
        cancelSignal,
        selectedType,
        startDate,
        endDate,
        setData,
    ) => {
        try {
            let getter = () => {
                switch (selectedType) {
                    case 'product':
                        return statisticService.statisticProduct(
                            cancelSignal,
                            startDate,
                            endDate,
                        );
                    case 'shipService':
                        return statisticService.statisticShipService(
                            cancelSignal,
                            startDate,
                            endDate,
                        );
                    case 'employee':
                        return statisticService.statisticEmployee(
                            cancelSignal,
                            startDate,
                            endDate,
                        );
                    case 'ecommerce':
                        return statisticService.statisticEcommerce(
                            cancelSignal,
                            startDate,
                            endDate,
                        );
                }

                return null;
            };

            if (getter) {
                const result = await getter();

                if (!result.error) {
                    setData(result);
                }
            }
        } catch (err) {
            if (err.name == 'AbortError') {
                console.log('Aborted!');
            }
        }
    };

    statisticExpenseByType = async (
        cancelSignal,
        selectedType,
        startDate,
        endDate,
        setData,
    ) => {
        try {
            let getter = () => {
                switch (selectedType) {
                    case 'expense':
                        return statisticService.statisticExpense(
                            cancelSignal,
                            startDate,
                            endDate,
                        );
                    case 'employee':
                        return statisticService.statisticEmployeeExpense(
                            cancelSignal,
                            startDate,
                            endDate,
                        );
                }

                return null;
            };

            if (getter) {
                const result = await getter();

                if (!result.error) {
                    setData(result);
                }
            }
        } catch (err) {
            if (err.name == 'AbortError') {
                console.log('Aborted!');
            }
        }
    };

    statisticRevenueDetail = async (
        cancelSignal,
        statisticReference,
        selectedStatisticTime,
        selectedValue,
        setData,
    ) => {
        try {
            const result = await statisticService.statisticRevenueDetail(
                cancelSignal,
                statisticReference,
                selectedStatisticTime,
                selectedValue,
            );

            if (!result.error) {
                setData(result);
            }
        } catch (err) {
            if (err.name == 'AbortError') {
                console.log('Aborted!');
            }
        }
    };

    statisticByProducts = async (
        cancelSignal,
        startDate,
        endDate,
        order,
        setData,
    ) => {
        try {
            const result = await statisticService.statisticProduct(
                cancelSignal,
                startDate,
                endDate,
            );

            if (!result.error) {
                setData(result, order);
            }
        } catch (err) {
            if (err.name == 'AbortError') {
                console.log('Aborted!');
            }
        }
    };

    statisticByShipServices = async (
        cancelSignal,
        startDate,
        endDate,
        order,
        setData,
    ) => {
        try {
            const result = await statisticService.statisticShipService(
                cancelSignal,
                startDate,
                endDate,
            );

            if (!result.error) {
                setData(result, order);
            }
        } catch (err) {
            if (err.name == 'AbortError') {
                console.log('Aborted!');
            }
        }
    };

    statisticByEcommerces = async (
        cancelSignal,
        startDate,
        endDate,
        order,
        setData,
    ) => {
        try {
            const result = await statisticService.statisticEcommerce(
                cancelSignal,
                startDate,
                endDate,
            );

            if (!result.error) {
                setData(result, order);
            }
        } catch (err) {
            if (err.name == 'AbortError') {
                console.log('Aborted!');
            }
        }
    };

    statisticByEmployees = async (
        cancelSignal,
        startDate,
        endDate,
        order,
        setData,
    ) => {
        try {
            const result = await statisticService.statisticEmployee(
                cancelSignal,
                startDate,
                endDate,
            );

            if (!result.error) {
                setData(result, order);
            }
        } catch (err) {
            if (err.name == 'AbortError') {
                console.log('Aborted!');
            }
        }
    };

    statisticByEmployeeExpenses = async (
        cancelSignal,
        startDate,
        endDate,
        order,
        setData,
    ) => {
        try {
            const result = await statisticService.statisticEmployeeExpense(
                cancelSignal,
                startDate,
                endDate,
            );

            if (!result.error) {
                setData(result, order);
            }
        } catch (err) {
            if (err.name == 'AbortError') {
                console.log('Aborted!');
            }
        }
    };

    statisticByExpenses = async (
        cancelSignal,
        startDate,
        endDate,
        order,
        setData,
    ) => {
        try {
            const result = await statisticService.statisticExpense(
                cancelSignal,
                startDate,
                endDate,
            );

            if (!result.error) {
                setData(result, order);
            }
        } catch (err) {
            if (err.name == 'AbortError') {
                console.log('Aborted!');
            }
        }
    };

    statisticByRevenues = async (cancelSignal, startDate, endDate, setData) => {
        try {
            const result = await statisticService.statisticRevenue(
                cancelSignal,
                startDate,
                endDate,
            );

            if (!result.error) {
                setData({
                    totalPrice: parseInt(result[0].totalPrice) || 0,
                    count: parseInt(result[0].count) || 0,
                });
            }
        } catch (err) {
            if (err.name == 'AbortError') {
                console.log('Aborted!');
            }
        }
    };

    statisticByExpenseBills = async (
        cancelSignal,
        startDate,
        endDate,
        setData,
    ) => {
        try {
            const result = await statisticService.statisticExpenseBill(
                cancelSignal,
                startDate,
                endDate,
            );

            if (!result.error) {
                setData({
                    totalPrice: parseInt(result[0].totalPrice) || 0,
                    count: parseInt(result[0].count) || 0,
                });
            }
        } catch (err) {
            if (err.name == 'AbortError') {
                console.log('Aborted!');
            }
        }
    };
}

export default StatisticController;

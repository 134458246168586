import { endpoint } from '@/config/endpoint';
import { replaceParams } from '@/utilities';
import { request } from '@/utilities/request';

const updateBill = async ({ user, bill }) => {
  const { img, ...newBill } = bill;
  const formData = new FormData();

  img && formData.append('image', img);
  formData.append('order', JSON.stringify(newBill));

  const option = {
    endpoint: endpoint.order,
    data: formData,
    user,
  };
  const response = await request.put(option);

  return response;
};

const getOrderById = async ({ id, user }) => {
  const options = {
    endpoint: replaceParams(endpoint.orderDetail, { id }),
    user,
  };
  return request.get(options);
};

export default { updateBill, getOrderById };

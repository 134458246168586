import { connect } from 'react-redux';

import actions from '@/application/actions';
import QtModal from '../QtModal';
import styles from './styles.module.scss';
import { KeyValues, Table } from '@/components/organisms';
import Button from '@/components/Button';

const tableOptions = [
  {
    field: 'id',
    name: 'Mã sản phẩm',
    isPC: true,
  },
  {
    field: 'product.name',
    name: 'Tên sản phẩm',
  },
  {
    field: 'count',
    name: 'Số lượng',
  },
  {
    field: 'price.vnd',
    name: 'Đơn giá',
    isPC: true,
  },
  {
    field: 'totalPrice.vnd',
    name: 'Tổng giá',
  },
];

const orderInfoFields = [
  {
    field: 'id',
    title: 'Mã đơn',
  },
  {
    field: 'displayStatus',
    title: 'Tình Trạng',
    valueClassName: styles.orderInfoText,
  },
  {
    field: 'createdAt.localDate',
    title: 'Ngày tạo',
  },
  {
    field: 'totalPrice.vnd',
    title: 'Tổng giá tiền',
  },
  {
    field: 'supplier.name',
    title: 'Nhà cung cấp',
    valueClassName: styles.orderInfoText,
  },
];

const ImportOrderDetailModal = (props) => {
  const {
    actions: {
      toggleImportOrderDetailModal,
      cancelImportOrder,
      confirmDanger,
    },
    state: {
      user,
      ui: { importOrderDetailModal },
      stock: { importOrder },
    },
  } = props;

  const canCancel = importOrder.canEdit(user) && !importOrder.isCancel;
  const onClose = () => toggleImportOrderDetailModal({ isOpen: false });
  const onCancel = () => () =>
    confirmDanger({
      message: <p>Đơn số #{importOrder?.id} sẽ bị HỦY! Bạn chắc chắn chứ?</p>,
      onAccept: cancelImportOrder,
    });

  return (
    <QtModal
      contentContainer={styles.container}
      isOpen={importOrderDetailModal.isOpen}
      onClose={onClose}
    >
      <h3 className={styles.header}>Đơn số #{importOrder.id}</h3>

      <div className={styles.order}>
        <div className={styles.orderInfo}>
          <KeyValues fields={orderInfoFields} data={importOrder} />
          {canCancel && (
            <div className={styles.btn}>
              <Button
                className={styles.btnChangeStatus}
                content="Hủy đơn hàng"
                onClick={onCancel()}
                role="subMain"
              />
            </div>
          )}
        </div>

        <Table
          containerClassName={styles.orderTable}
          headers={tableOptions}
          data={importOrder.details.list}
        />
      </div>
    </QtModal>
  );
};

const mapStateToProps = ({ state }) => ({ state });

export default connect(mapStateToProps, actions)(ImportOrderDetailModal);

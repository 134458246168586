import {
  validatePaid,
  validatePhone,
  validatePrice,
  validateBill,
} from './validate';

import * as defaultValue from './defaultValue';
import _ from 'lodash';
import { billService } from '@services';
import unitOfWork from '@controllers';

const handleImagePaste = (e, previewImg, setImg, setError) => {
  const data = e.clipboardData;
  if (data.files.length > 0) {
    const urlImage = URL.createObjectURL(data.files[0]);
    previewImg.current.src = urlImage;
    setImg(data.files[0]);
    setError((error) => ({
      ...error,
      img: '',
    }));
    return true;
  }
  return false;
};

// handleTextPaste helper
const splitPhone = (phones) => {
  return phones
    .match(/[0-9]+/g)
    .join('')
    .match(/[0-9]{10}|[0-9]+/g);
};

const handleTextPaste = (
  e,
  inputElems,
  setPhone,
  setBill,
  setError,
  { onAddressChanged },
) => {
  const clipboard = e.clipboardData.getData('text/plain');

  if (clipboard && e.target.tagName === 'INPUT') {
    e.preventDefault();
    const texts = clipboard.split('\n');
    const length = texts.length;
    let index = inputElems
      .map((inputElem) => inputElem.current)
      .indexOf(e.target);
    if (index !== -1) {
      let inputSlices = inputElems.slice(index, index + length);

      let i = 0;
      const firstInputValue = inputSlices[0].current.value;
      const selectionText = document.getSelection().toString();
      texts[0] = selectionText
        ? firstInputValue.replace(selectionText, texts[0])
        : firstInputValue + texts[0];

      for (let input of inputSlices) {
        const { id } = input.current;

        switch (id) {
          case 'phone':
            const listPhones = splitPhone(texts[i++]);
            for (let phone of listPhones) {
              validatePhone(phone, setPhone, setBill, setError);
            }
            break;
          case 'price':
            validatePrice(texts[i++], setBill, setError);
            break;
          case 'paid':
            validatePaid(texts[i++], setBill, setError);
            break;
          case 'address':
            onAddressChanged(texts[i]);
          default:
            const value = texts[i++];
            setBill((bill) => ({
              ...bill,
              [id]: value,
            }));
        }
      }

      inputSlices[length - 1].current.focus();
    }
  }
};

const handleNameChange = (e, setBill) => {
  setBill((bill) => ({
    ...bill,
    name: e.target.value,
  }));
};

const handlePhoneInputChange = (e, setPhone, setBill, setError) => {
  if (validatePhone(e.target.value, setPhone, setBill, setError)) {
    setPhone('');
  }
};

const handlePhoneRemoveClick = (e, setBill) => {
  setBill((bill) => {
    return {
      ...bill,
      phones: bill.phones.filter((phone) => phone !== bill.phones[e.target.id]),
    };
  });
};

const handleAddressChange = (e, setBill) => {
  setBill((bill) => ({
    ...bill,
    address: e.target.value,
  }));
};

const handlePriceChange = (e, setBill, paid, setError) => {
  validatePrice(e.target.value, setBill, paid, setError);
};

const handlePaidChange = (e, setBill, price, setError) => {
  validatePaid(e.target.value, setBill, price, setError);
};

const handleShipServiceChange = (e, setBill) => {
  const id = parseInt(e.target.value);

  setBill((bill) => ({
    ...bill,
    shipService: {
      id,
    },
  }));
};

const handleProductChange = (e, setBill, products) => {
  const id = parseInt(e.target.value);
  const name = products.find((product) => product.id === id)?.name;
  setBill((bill) => ({
    ...bill,
    productID: id,
    product: { name: name },
  }));
};

const handleEcommerceChange = (e, setBill) => {
  setBill((bill) => ({
    ...bill,
    ecommerceID: parseInt(e.target.value),
  }));
};

const handleEcommerceNameChange = (e, setBill) => {
  setBill((bill) => ({
    ...bill,
    ecommerceName: e.target.value,
  }));
};

const handleNoteChange = (e, setBill) => {
  setBill((bill) => ({
    ...bill,
    note: e.target.value,
  }));
};

const submit = (
  bill,
  img,
  setBill,
  setError,
  setSubmitDisabled,
  setLoading,
  callback,
) => {
  billService
    .create(bill, img)
    .then((res) => res.json())
    .then((billReceive) => {
      setError((error) => ({
        ...error,
        formSubmit: {
          ...error.formSubmit,
          error: '',
          msg: `Đăng đơn thành công - Mã đơn: ${billReceive.id}`,
        },
      }));
      if (callback) callback(billReceive);
      setBill({
        ...billReceive,
        phones: _.map(billReceive.phones, (phone) => phone.phone),
      });
    })
    .catch(() =>
      setError((error) => ({
        ...error,
        formSubmit: {
          ...error.formSubmit,
          error: 'Có lỗi xảy ra khi đăng đơn',
        },
      })),
    );
};

const submitForm = (
  bill,
  setBill,
  img,
  setError,
  submitDisabled,
  setSubmitDisabled,
  setLoading,
  callback,
) => {
  setSubmitDisabled('disabled');
  if (!submitDisabled && validateBill(bill, setError, img)) {
    submit(
      bill,
      img,
      setBill,
      setError,
      setSubmitDisabled,
      setLoading,
      callback,
    );
  } else {
    setSubmitDisabled('');
  }
};

const handleFormSubmit = (
  e,
  setBill,
  bill,
  img,
  setError,
  submitDisabled,
  setSubmitDisabled,
  setLoading,
  callback,
) => {
  if (!submitDisabled) {
    submitForm(
      bill,
      setBill,
      img,
      setError,
      submitDisabled,
      setSubmitDisabled,
      setLoading,
      callback,
    );
  }
};

const handleRemoveImage = (previewImg, setImg) => {
  previewImg.current.src = '';
  setImg(null);
};

const handleClearForm = (
  setBill,
  setImg,
  previewImg,
  setError,
  firstInput,
  setPhone,
  setSubmitDisabled,
) => {
  setBill(defaultValue.bill);
  setPhone('');
  setImg(null);
  setError(defaultValue.error);
  previewImg.current.src = '';
  firstInput.current.focus();
  setSubmitDisabled('');
};

const handleHintNameClick = (setBill, name) => {
  setBill((bill) => ({ ...bill, name: name }));
};

const handleHintAddressClick = (setBill, address) => {
  setBill((bill) => ({ ...bill, address: address }));
};

const handleHintEcommerceClick = (setBill, ecommerce) => {
  setBill((bill) => ({
    ...bill,
    ecommerceName: ecommerce.ecommerceName,
    ecommerceID: ecommerce.ecommerceID,
  }));
};

const handleHintPhone = (hints, setHints, phones) => {
  const len = phones.length;
  if (!len) {
    setHints({ names: [], addresses: [], ecommerces: [] });
    return;
  }
  if (hints.names.length < 3 || hints.addresses.length < 3) {
    billService
      .getHint(phones[len - 1])
      .then((res) => res.json())
      .then((data) => {
        setHints({
          names: _.uniq([...hints.names, ...data.names]).slice(0, 3),
          addresses: _.uniq([...hints.addresses, ...data.addresses]).slice(
            0,
            3,
          ),
          ecommerces: _.uniq([...hints.ecommerces, ...data.ecommerces]).slice(
            0,
            3,
          ),
        });
      });
  }
};

export {
  handleImagePaste,
  handleTextPaste,
  handleNameChange,
  handlePhoneInputChange,
  handlePhoneRemoveClick,
  handleAddressChange,
  handlePriceChange,
  handlePaidChange,
  handleShipServiceChange,
  handleProductChange,
  handleEcommerceChange,
  handleEcommerceNameChange,
  handleNoteChange,
  submitForm,
  handleFormSubmit,
  handleRemoveImage,
  handleClearForm,
  handleHintNameClick,
  handleHintAddressClick,
  handleHintEcommerceClick,
  handleHintPhone,
};

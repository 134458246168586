import { _p } from '@/domain/models/utils/model-proto';
import BaseModel from '../..';

class Category extends BaseModel {
  static properties = {
    id: null,
    name: null,
  };

  constructor(obj) {
    super(obj);
  }

  /*
  id: number | null;
  name: string;
  */
}

export default Category;

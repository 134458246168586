import React from 'react';
import clsx from 'clsx';

import styles from './MarketStatistic.module.scss';
import {
  MarketInfoDetailTable,
  ToastMessage,
  CustomMonthPicker,
} from '@components';
import toastMessageType from '@constant/ToastMessage';
import { useThrottle } from '@hooks';
import unitOfWork from '@controllers';

// Redux;
import { connect, useDispatch, useSelector } from 'react-redux';

function MarketStatistic({}) {
  const [details, setDetails] = React.useState({});
  const [ecommercePages, setEcommercePages] = React.useState([]);
  const [month, setMonth] = React.useState(new Date());
  const [toastMessage, setToastMessage] = React.useState({
    isVisible: false,
    type: toastMessageType.SUCCESS,
    msg: {
      title: 'Lỗi',
      duration: 3000,
      desc: 'Chọn ngày nhỏ hơn ngày hiện tại',
    },
  });

  const selectedPages = React.useMemo(() => {
    let selectedIds = [];

    for (const ecommerce of ecommercePages) {
      if (ecommerce.check) {
        selectedIds.push(ecommerce.id);
      }
    }

    const total = {
      orders: 0,
      paid: 0,
      msgQuantity: 0,
    };
    const selectedDetails = Object.keys(details).reduce((prev, date) => {
      prev[date] = details[date].filter((page) => {
        const isChecked = selectedIds.includes(parseInt(page.id));

        if (isChecked) {
          total.orders += parseInt(page.orders);
          total.paid += parseInt(page.paid);
          total.msgQuantity += parseInt(page.msgQuantity);
        }

        return isChecked;
      });

      return prev;
    }, {});

    return {
      details: selectedDetails,
      total,
    };
  }, [details, ecommercePages]);

  const checkValidMonthChange = React.useCallback((selectedMonth) => {
    if (selectedMonth.getMonth() > new Date().getMonth()) {
      setToastMessage({
        isVisible: true,
        type: toastMessageType.ERROR,
        msg: {
          title: 'Lỗi',
          duration: 3000,
          desc: 'Thời điểm thống kê phải trước thời điểm hiện tại',
        },
      });

      return false;
    }

    return true;
  }, []);

  const dispatch = useDispatch();

  const fetchMarketInfo = () => {
    if (month) {
      const controller = new AbortController();

      unitOfWork.market.reportDetail(
        controller.signal,
        month,
        setDetails,
        setEcommercePages,
        setToastMessage,
      );

      return () => {
        controller.abort();
      };
    }
  };

  React.useEffect(useThrottle(fetchMarketInfo, 1000), [month]);

  return (
    <React.Fragment>
      <div className={clsx(styles.header)}>
        <CustomMonthPicker
          label="Ngày thống kê"
          month={month}
          setMonth={setMonth}
          checkValid={checkValidMonthChange}
        />

        <div className={styles.rightSide}>
          <div className={styles.total}>
            <h3 className={styles.totalLabel}>Tổng số đơn:</h3>
            <span className={styles.totalValue}>
              {selectedPages.total.orders || 0}
            </span>
          </div>

          <div className={styles.total}>
            <h3 className={styles.totalLabel}>Tổng số tiền:</h3>
            <span className={styles.totalValue}>
              {(selectedPages.total.paid &&
                selectedPages.total.paid.toLocaleString('vi-VN', {
                  style: 'currency',
                  currency: 'VND',
                })) ||
                0}
            </span>
          </div>

          <div className={styles.total}>
            <h3 className={styles.totalLabel}>Tổng số tin nhắn:</h3>
            <span className={styles.totalValue}>
              {selectedPages.total.msgQuantity || 0}
            </span>
          </div>
        </div>
      </div>

      <div className={clsx(styles.marketInfoTable)}>
        <MarketInfoDetailTable
          setPages={setDetails}
          ecommercePages={ecommercePages}
          setEcommercePages={setEcommercePages}
          pages={selectedPages.details}
          inputDisabled={{ value: true, style: false }}
        />
      </div>

      <ToastMessage open={toastMessage} setOpen={setToastMessage} />
    </React.Fragment>
  );
}

export default connect()(MarketStatistic);

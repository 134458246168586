import { mapAsyncActions } from './utils/async-actions-helper';
import { asyncActions as stockAsyncActions } from '../reducer/stock';
import facade from '@/domain/models/utils/facade';
import Stock from '@/domain/models/entities/stock';

export const initialState = facade.create(Stock, {});
export { actions as reducers } from '../reducer/stock';
export const { asyncActions, extraReducers } = mapAsyncActions(
  'stock',
  stockAsyncActions,
);

import clsx from 'clsx';
import React, { memo, useEffect, useRef, useState } from 'react';
import styles from './StatisticDatePicker.module.scss';
import * as constant from './constant';
import * as event from './event';

function StatisticDatePicker({
    statisticReference,
    statisticTime,
    selectedStatisticTime,
    setStatisticReference,
    setSelectedStatisticTime,
}) {
    const [hideCatalogs, setHideCatalogs] = useState(true);
    const [hideTimes, setHideTimes] = useState(true);
    const [hideModal, setHideModal] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        const cb = (e) => {
            setHideCatalogs(true);
            setHideTimes(true);
            setHideModal(true);
        };
        document.addEventListener('click', cb);

        return () => {
            document.removeEventListener('click', cb);
        };
    }, []);

    return (
        <React.Fragment>
            <div className={styles.wrapper}>
                <h3
                    className={styles.catalogSelected}
                    onClick={(e) => {
                        e.stopPropagation();
                        setHideCatalogs((hideCatalogs) => !hideCatalogs);
                    }}
                >
                    {statisticReference === constant.MONTH
                        ? 'Theo tháng'
                        : 'Theo năm'}
                </h3>

                <ul
                    className={clsx(styles.options, {
                        [styles.hide]: hideCatalogs,
                    })}
                >
                    <li
                        className={styles.option}
                        onClick={() => setStatisticReference(constant.MONTH)}
                    >
                        Tháng
                    </li>

                    <li
                        className={styles.option}
                        onClick={() => setStatisticReference(constant.YEAR)}
                    >
                        Năm
                    </li>
                </ul>
            </div>

            <div className={styles.wrapper}>
                <h3
                    className={styles.timeSelected}
                    onClick={(e) => {
                        e.stopPropagation();
                        setHideTimes((hideTimes) => !hideTimes);
                    }}
                >
                    {(statisticReference === constant.MONTH
                        ? 'Tháng '
                        : 'Năm ') + selectedStatisticTime}
                </h3>

                <ul
                    className={clsx(styles.options, {
                        [styles.hide]: hideTimes,
                    })}
                >
                    {statisticTime.map((time, index) => (
                        <li
                            className={styles.option}
                            onClick={(e) =>
                                event.handleSetSelectedStatisticTime(
                                    e,
                                    statisticReference,
                                    setSelectedStatisticTime,
                                    index,
                                    setHideTimes,
                                    setHideModal,
                                )
                            }
                            key={index}
                        >
                            {time}
                        </li>
                    ))}
                </ul>
            </div>

            <div
                className={clsx(styles.modal, {
                    [styles.hide]: hideModal,
                })}
            >
                <div className={styles.overlay}></div>

                <div
                    className={clsx(styles.formInput, {
                        [styles.error]: error,
                    })}
                    onClick={(e) => e.stopPropagation()}
                    error={error}
                >
                    <input
                        type="text"
                        className={styles.input}
                        value={selectedStatisticTime}
                        onChange={(e) =>
                            event.handleCustomYearChange(
                                e,
                                setSelectedStatisticTime,
                                setError,
                            )
                        }
                    />
                </div>
            </div>
        </React.Fragment>
    );
}

export default StatisticDatePicker;

import { useLayoutEffect } from 'react';
import { Navigate } from 'react-router';
import { useLocation } from 'react-router-dom';

function SignOut({ updateUser }) {
    const location = useLocation();

    useLayoutEffect(() => {
        fetch('/api/auth/sign-out');
        localStorage.removeItem('token');
        updateUser();
    }, []);

    return <Navigate to={'/sign-in' + location.search || ''}></Navigate>;
}

export default SignOut;

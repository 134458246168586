import _ from 'lodash';
import * as constantDate from '@components/StatisticDatePicker/constant';
import unitOfWork from '@controllers';

const handleSelectedValue = (e, setSelectedValue) => {
    setSelectedValue(e.target.value);
};

const getLabel = (statisticReference, selectedStatisticTime, data) => {
    if (statisticReference === constantDate.MONTH) {
        const now = new Date();
        now.setMonth(selectedStatisticTime);
        now.setDate(0);
        const labels = Array(now.getDate())
            .fill()
            .map((_, index) => `${index + 1}`);

        const bills = _.keyBy(data.bills, (bill) =>
            new Date(bill.moment).getDate(),
        );

        const expenseBills = _.keyBy(data.expenseBills, (expenseBill) =>
            new Date(expenseBill.moment).getDate(),
        );

        return { labels, bills, expenseBills };
    } else if (statisticReference === constantDate.YEAR) {
        const labels = Array(12)
            .fill()
            .map((_, index) => `${index + 1}`);

        const bills = _.keyBy(data.bills, (bill) => `${bill.moment + 1}`);

        const expenseBills = _.keyBy(
            data.expenseBills,
            (expenseBill) => `${expenseBill.moment + 1}`,
        );

        return { labels, bills, expenseBills };
    }
};

const setNewData = (
    selectedValue,
    statisticReference,
    selectedStatisticTime,
    setData,
    received,
) => {
    const { labels, bills, expenseBills } = getLabel(
        statisticReference,
        selectedStatisticTime,
        received,
    );

    const billsData =
        selectedValue === 'count'
            ? labels.map((label) => (bills[label] ? bills[label].count : 0))
            : labels.map((label) =>
                  bills[label] ? bills[label].totalPrice : 0,
              );

    const expenseBillsData =
        selectedValue === 'count'
            ? labels.map((label) =>
                  expenseBills[label] ? expenseBills[label].count : 0,
              )
            : labels.map((label) =>
                  expenseBills[label] ? expenseBills[label].totalPrice : 0,
              );

    setData({
        labels,
        datasets: [
            {
                label: 'Thu',
                data: billsData,
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
            },
            {
                label: 'Chi',
                data: expenseBillsData,
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
            },
        ],
    });
};

const handleStatistic = (
    selectedValue,
    statisticReference,
    selectedStatisticTime,
    setData,
) => {
    const cancelController = new AbortController();

    const handleReceived = (result) =>
        setNewData(
            selectedValue,
            statisticReference,
            selectedStatisticTime,
            setData,
            result,
        );

    unitOfWork.statistic.statisticRevenueDetail(
        cancelController.signal,
        statisticReference,
        selectedStatisticTime,
        selectedValue,
        handleReceived,
    );

    return cancelController;
};

export { handleSelectedValue, handleStatistic };

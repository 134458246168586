import { Select } from '@/components/atoms';
import clsx from 'clsx';
import { memo } from 'react';

import styles from './styles.module.scss';

export const SelectField = memo((props) => {
  const { containerClassName, label, labelClassName, ...inputProps } = props;

  return (
    <div
      className={clsx(styles.container, {
        [containerClassName]: containerClassName,
      })}
    >
      <label
        className={clsx(styles.label, {
          [labelClassName]: labelClassName,
        })}
      >
        {label}
      </label>
      <Select {...inputProps} />
    </div>
  );
});

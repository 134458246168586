import { useEffect, useRef, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import '@assets/image/fontawesome-free-6.0.0-web/css/all.min.css';

import clsx from 'clsx';
import _ from 'lodash';
import { Promise } from 'bluebird';

import * as event from './event';

import {
  BillTable,
  NotifyModal,
  ConfirmModal,
  CustomDatePicker,
  SideBar,
} from '@components';
import styles from './ListBill.module.scss';
import { initDate } from './defaultValue';
import * as notifyConstant from '@components/NotifyModal/constant';
import actions from '@/application/actions';
import { connect } from 'react-redux';

function ListBill(props) {
  const {
    state: {
      masterData: { ecommerces, shipServices },
    },
    actions: { updateBill, updateBillStatus },
  } = props;

  // First fetch
  const [products, setProducts] = useState([]);
  // Data handler
  const [bills, setBills] = useState([]);
  const [selectedBill, setSelectedBill] = useState({
    bill: null,
    sideBarShown: false,
  });
  const [statuses, setStatuses] = useState([]);
  const [checked, setChecked] = useState({});
  const [startDate, setStartDate] = useState(initDate(true));
  const [endDate, setEndDate] = useState(initDate(false));
  const [searchTypes] = useState(['Mã đơn', 'Tên khách', 'SĐT', 'Trang TMĐT']);
  const [order, setOrder] = useState({
    id: '',
    createdAt: 'DESC',
    name: '',
    price: '',
  });
  const [modalShow, setModalShow] = useState(false);
  const [modal, setModal] = useState({
    actionTitle: null,
    actionConfirm: null,
    actionConduct: null,
  });
  const [errorModalShow, setErrorModalShow] = useState(false);
  const [errorModal, setErrorModal] = useState({
    type: notifyConstant.ERROR,
    title: '',
    content: '',
  });
  const [fullSize, setFullSize] = useState(false);

  // Event handler state
  const [page, setPage] = useState(1);
  let [stopScrollEvent, setStopScrollEvent] = useState(true);
  const billTableRef = useRef();
  const [currentBillCount, setCurrentBillCount] = useState(0);
  const [totalBillCount, setTotalBillCount] = useState(0);

  const [mousePressed, setMousePressed] = useState(false);
  const [isBillSelected, setIsBillSelected] = useState(false);
  const [checkedAll, setCheckedAll] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(0);
  const [selectedSearchType, setSelectedSearchType] = useState(2);
  const [selectedShipService, setSelectedShipService] = useState(0);
  const [selectedProduct, setSelectedProduct] = useState(0);
  const [actionHide, setActionHide] = useState(true);
  const [searchText, setSearchText] = useState('');

  const [error, setError] = useState({
    unSelectedBill: '',
    unSelectedShipService: '',
  });

  const [shipServiceInfo, setShipServiceInfo] = useState('');
  const shipServiceInfoFile = useRef(null);

  const fetchBills = () =>
    event.fetchBills(
      page,
      startDate,
      endDate,
      selectedShipService,
      selectedStatus,
      selectedProduct,
      order,
      bills,
      setBills,
      setTotalBillCount,
      setStopScrollEvent,
    );

  const onUpdateBill = (bill) => {
    updateBill({
      bill,
      onSuccess: () => {
        fetchBills();
        event.closeSideBar(setSelectedBill);
        event.showErrorModal(
          'Cập nhật đơn',
          'Cập nhật đơn thành công',
          setErrorModalShow,
          setErrorModal,
          2,
        );
      },
      onError: (msg) => {
        event.closeSideBar(setSelectedBill);
        event.showErrorModal(
          'Cập nhật đơn',
          msg || 'Lỗi hệ thống',
          setErrorModalShow,
          setErrorModal,
        );
      },
    });
  };

  const onChangeStatus = (bill) => {
    updateBillStatus({
      bill,
      onSuccess: () => {
        fetchBills();
        event.showErrorModal(
          'Cập nhật đơn',
          'Cập nhật đơn thành công',
          setErrorModalShow,
          setErrorModal,
          2,
        );
      },
      onError: (msg) =>
        event.showErrorModal(
          'Cập nhật đơn',
          msg || 'Lỗi hệ thống',
          setErrorModalShow,
          setErrorModal,
        ),
    });
  };

  // Effect: Start
  useEffect(() => {
    // First fetch
    const cancelController = event.fetchSelectionOptions(
      setProducts,
      setStatuses,
    );

    // Event region
    const callbackMouseUp = () => {
      setMousePressed(false);
    };
    const callbackClick = () => {
      setActionHide(true);
    };
    document.addEventListener('mouseup', callbackMouseUp);
    document.addEventListener('click', callbackClick);

    return () => {
      if (document) {
        document.removeEventListener('mouseup', callbackMouseUp);
        document.removeEventListener('click', callbackClick);
      }

      cancelController.abort();
    };
  }, []);

  useEffect(() => {
    if (page > 1) {
      const cancelController = fetchBills();

      return () => {
        if (cancelController) {
          cancelController.abort();
        }
      };
    }
  }, [page]);

  // Effect for update check list when the list of bill change
  useEffect(() => {
    const newChecked = {};
    bills.forEach((bill, index) => {
      newChecked[bill.id] = false;
    });
    setCurrentBillCount(bills.length);
    setChecked(newChecked);
  }, [bills]);

  // Effect for set check all button and
  // Print action (Ctrl + Alt + P) when check list change
  useEffect(() => {
    const checkValues = Object.values(checked);
    setIsBillSelected(checkValues.some(Boolean));

    if (checkValues.every(Boolean)) {
      setCheckedAll(true);
    } else {
      setCheckedAll(false);
    }

    if (checkValues.some(Boolean))
      event.removeError('unSelectedBill', setError);

    const callback = (e) => {
      if (e.ctrlKey && e.altKey && e.key === 'p') {
        event.handlePrint(
          checked,
          bills,
          setError,
          setModal,
          setActionHide,
          setModalShow,
          fetchBills,
        );
      }
    };

    document.addEventListener('keyup', callback);

    return () => {
      document.removeEventListener('keyup', callback);
    };
  }, [checked]);

  // Effect for export excel (Ctrl + Alt + E)
  // when ship service and check list change
  useEffect(() => {
    const callback = (e) => {
      if (e.ctrlKey && e.altKey && e.key === 'e') {
        event.exportExcel(checked);
      }
    };

    document.addEventListener('keyup', callback);

    return () => {
      document.removeEventListener('keyup', callback);
    };
  }, [selectedShipService, checked]);

  // Effect for search when search type change
  useEffect(() => {
    setPage((page) => {
      if (page === 1) {
        fetchBills();
      }

      return 1;
    });
  }, [
    startDate,
    endDate,
    selectedShipService,
    selectedStatus,
    selectedProduct,
  ]);

  useEffect(() => {
    const cbScroll = (e) => {
      const maxDistanceFromBot = e.target.offsetHeight;
      if (
        e.target.scrollHeight - e.target.scrollTop <= maxDistanceFromBot &&
        !stopScrollEvent
      ) {
        setStopScrollEvent(true);
        setPage((page) => page + 1);
      }
    };
    const handleScroll = _.throttle(cbScroll, 500);

    billTableRef.current.addEventListener('scroll', handleScroll);

    return () => {
      if (billTableRef && billTableRef.current) {
        billTableRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, [stopScrollEvent, billTableRef]);

  // Effect for remove error
  useEffect(() => {
    event.removeError('unSelectedShipService', setError);
  }, [selectedShipService]);

  useEffect(() => {
    event.sortBills(setBills, order);
  }, [order]);
  // Effect: End

  useEffect(() => {
    if (shipServiceInfo) {
      const actionTitle = 'Cập nhật';
      const actionConfirm =
        `Mã đơn sẽ được cập nhật dựa trên tệp ${shipServiceInfo.name}. ` +
        `Bạn có chắc chắn không?`;
      const actionConduct = () =>
        event.updateShipService(
          shipServiceInfo,
          setShipServiceInfo,
          setErrorModalShow,
          setErrorModal,
          shipServiceInfoFile,
        );
      const actionCancel = () => {
        setShipServiceInfo('');
        shipServiceInfoFile.current.value = '';
      };
      event.showModal(
        {
          actionTitle,
          actionConfirm,
          actionConduct,
          actionCancel,
        },
        setActionHide,
        setModal,
        setModalShow,
      );
    }
  }, [shipServiceInfo]);

  return (
    <div
      className={clsx(styles.wrapper, {
        [styles.sideBarShown]: selectedBill.sideBarShown,
      })}
    >
      <div className={styles.mainContent}>
        <div
          className={clsx(styles.tableWrapper, {
            [styles.fullSize]: fullSize,
          })}
        >
          <div className={styles.filterBar}>
            <div>
              <select
                className={styles.filterSelect}
                value={selectedStatus}
                onChange={(e) =>
                  event.handleSelectedStatusChange(e, setSelectedStatus)
                }
              >
                <option value="0">Tình trạng</option>
                {statuses.map((status) => (
                  <option value={status.id} key={status.id}>
                    {status.name}
                  </option>
                ))}
              </select>
            </div>

            <div
              className={clsx({
                [styles.error]: error.unSelectedShipService,
              })}
              error={error.unSelectedShipService}
            >
              <select
                className={styles.filterSelect}
                value={selectedShipService}
                onChange={(e) =>
                  event.handleSelectShipServiceChange(e, setSelectedShipService)
                }
              >
                <option value="0">Đơn vị vận chuyển</option>
                {shipServices.list.map((shipService) => (
                  <option value={shipService.id} key={shipService.id}>
                    {shipService.name}
                  </option>
                ))}
              </select>
            </div>

            <div
              className={clsx({
                [styles.error]: error.unSelectedShipService,
              })}
              error={error.unSelectedShipService}
            >
              <select
                className={styles.filterSelect}
                value={selectedProduct}
                onChange={(e) =>
                  event.handleProductChange(e, setSelectedProduct)
                }
              >
                <option value="0">Sản phẩm</option>
                {products.map((product) => (
                  <option value={product.id} key={product.id}>
                    {product.name}
                  </option>
                ))}
              </select>
            </div>

            <CustomDatePicker
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
            />

            <div className={styles.billCount}>
              Số lượng đơn:
              <h3 className={styles.billCountContent}>
                {currentBillCount} / {totalBillCount}
              </h3>
            </div>
          </div>

          <div className={clsx(styles.actionBar)}>
            <div className={clsx(styles.selectAllBtn)}>
              <input
                type="checkbox"
                id="checkAll"
                checked={checkedAll}
                onChange={() =>
                  event.handleCheckedAll(
                    setCheckedAll,
                    setChecked,
                    currentBillCount,
                    totalBillCount,
                    startDate,
                    endDate,
                    selectedShipService,
                    selectedStatus,
                    selectedProduct,
                    order,
                    setBills,
                  )
                }
              />
              <label htmlFor="checkAll">Chọn tất cả</label>

              <div className={styles.billCheckedCount}>
                Đã chọn:
                <h3 className={styles.billCheckedCountContent}>
                  {_.values(checked).filter(Boolean).length}
                </h3>
              </div>
            </div>

            {/* Search: Start */}
            <div className={styles.searchWrapper}>
              <input
                type="text"
                className={clsx(styles.searchBar)}
                placeholder="Nhập nội dung tìm kiếm"
                value={searchText}
                onChange={(e) =>
                  setSearchText((oldValue) => {
                    if (oldValue && !e.target.value) {
                      fetchBills();
                    }
                    return e.target.value;
                  })
                }
                onKeyUp={(e) =>
                  event.handleSearchInputKeyUp(
                    e,
                    setBills,
                    searchText,
                    selectedSearchType,
                    order,
                  )
                }
              />
              <i
                className="fas fa-search"
                onClick={() =>
                  event.searchBills(setBills, searchText, selectedSearchType)
                }
              />
              <select
                className={styles.searchTypes}
                value={selectedSearchType}
                onChange={(e) =>
                  setSelectedSearchType((oldValue) => {
                    if (oldValue && e.target.value != oldValue) {
                      fetchBills();
                    }
                    return e.target.value;
                  })
                }
              >
                {searchTypes.map((type, index) => (
                  <option value={index} key={index}>
                    {type}
                  </option>
                ))}
              </select>
            </div>
            {/* Search: End*/}

            {/* Action Button: Start */}
            <div className={styles.action}>
              <div
                className={styles.toggleButtonBar}
                onClick={(e) => event.handleActionClick(e, setActionHide)}
              >
                <i className={clsx(styles.iconMenu, 'fas fa-list')} />
                Hành động
              </div>

              <div
                className={clsx(styles.actionBtnWrapper, {
                  [styles.actionBtnHide]: actionHide,
                })}
                onClick={(e) => e.stopPropagation()}
              >
                <button
                  className={clsx(styles.actionBtn, {
                    [styles.inActive]: !isBillSelected,
                  })}
                  onClick={(e) =>
                    event.handlePrint(
                      checked,
                      bills,
                      setError,
                      setModal,
                      setActionHide,
                      setModalShow,
                      fetchBills,
                    )
                  }
                  disabled={isBillSelected ? '' : 'disabled'}
                >
                  In Đơn
                </button>

                <button
                  className={clsx(styles.actionBtn, {
                    [styles.inActive]: !isBillSelected,
                  })}
                  onClick={() => event.handleExportExcelClick(checked)}
                  disabled={!isBillSelected}
                >
                  Xuất Excel
                </button>

                <button
                  className={clsx(styles.actionBtn, {
                    [styles.inActive]: !isBillSelected,
                  })}
                  onClick={() =>
                    event.handleRetailPrint(
                      checked,
                      bills,
                      setError,
                      setModal,
                      setActionHide,
                      setModalShow,
                      fetchBills,
                    )
                  }
                  disabled={!isBillSelected}
                >
                  In đơn bán lẻ
                </button>

                <label htmlFor="uploadExcel" className={clsx(styles.actionBtn)}>
                  Cập nhật ĐVVC
                </label>
                <input
                  id="uploadExcel"
                  type="file"
                  style={{
                    display: 'none',
                  }}
                  onChange={(e) =>
                    event.handleShipServiceInfoChange(e, setShipServiceInfo)
                  }
                  ref={shipServiceInfoFile}
                />

                <button
                  className={clsx(styles.actionBtn, {
                    [styles.inActive]: !isBillSelected,
                  })}
                  onClick={() =>
                    event.handleDeleteBills(
                      checked,
                      setError,
                      setActionHide,
                      setModal,
                      setModalShow,
                      fetchBills,
                      setErrorModalShow,
                      setErrorModal,
                    )
                  }
                  disabled={!isBillSelected}
                >
                  Xóa đơn
                </button>
              </div>
            </div>
            {/* Action Button: End*/}
          </div>

          <BillTable
            bills={bills}
            checked={checked}
            handleEditClick={(bill) =>
              event.handleEditClick(bill, setSelectedBill)
            }
            handleSelectMouseDown={(sid) =>
              event.handleSelectMouseDown(sid, setChecked)
            }
            handleSelectMouseEnter={(e) =>
              event.handleSelectMouseEnter(e, mousePressed, setChecked)
            }
            handleSortOrderChange={(col) =>
              event.handleSortOrderChange(col, setOrder)
            }
            mousePressed={mousePressed}
            setMousePressed={setMousePressed}
            error={error}
            order={order}
            fullSize={fullSize}
            setFullSize={setFullSize}
            ref={billTableRef}
          />
        </div>
      </div>

      <ConfirmModal
        modal={modal}
        show={modalShow}
        closeModal={() => setModalShow(false)}
      />

      <NotifyModal
        modal={errorModal}
        show={errorModalShow}
        closeModal={() => setErrorModalShow(false)}
      />

      <SideBar
        show={selectedBill.sideBarShown}
        bill={selectedBill.bill}
        closeSideBar={() => event.closeSideBar(setSelectedBill)}
        shipServices={shipServices.list}
        showModal={event.showModal}
        handleSideBarUpdateClick={(billTemp, img, setBillTemp) =>
          event.handleSideBarUpdateClick(
            billTemp,
            img,
            setBillTemp,
            setModal,
            setActionHide,
            setModalShow,
            fetchBills,
            setErrorModalShow,
            setErrorModal,
            setSelectedBill,
            { actionConduct: () => onUpdateBill({ ...billTemp, img }) },
          )
        }
        handleChangeStatusClick={(bill, setBillTemp) =>
          event.handleChangeStatusClick(
            bill,
            setActionHide,
            setModal,
            setModalShow,
            fetchBills,
            setBillTemp,
            { actionConduct: () => onChangeStatus(bill) },
          )
        }
        ecommerces={ecommerces.list}
        products={products}
      />
    </div>
  );
}

const mapStateToProps = ({ state }) => ({ state });
const mapDispatchToProps = actions;

export default connect(mapStateToProps, mapDispatchToProps)(ListBill);

import { storeProductService } from '@services';
import toastMessageType from '@constant/ToastMessage';

class StoreProductController {
  #onSuccess = (page, result, setStoreProducts, setCount) => {
    if (result.success) {
      if (parseInt(page) === 1) {
        setStoreProducts(result.data.rows);
        setCount(result.data.count);
      } else {
        setStoreProducts(result.data);
      }
    }
  };

  getByPage = async (
    cancelSignal,
    page,
    perPage,
    setStoreProducts,
    setCount,
  ) => {
    try {
      const result = await storeProductService.getAll(
        cancelSignal,
        page,
        perPage,
      );

      this.#onSuccess(page, result, setStoreProducts, setCount);
    } catch (error) {
      if (error.name === 'AbortError') {
        console.log('Cancelled request');
      }
    }
  };

  queryByPage = async (
    cancelSignal,
    name,
    page,
    perPage,
    setStoreProducts,
    setCount,
  ) => {
    try {
      const result = await storeProductService.getAll(
        cancelSignal,
        page,
        perPage,
        name,
      );

      this.#onSuccess(page, result, setStoreProducts, setCount);
    } catch (error) {
      if (error.name === 'AbortError') {
        console.log('Cancelled request');
      }
    }
  };

  create = async (
    storeProduct,
    perPage,
    setCount,
    data,
    setStoreProducts,
    setToastMessage,
  ) => {
    try {
      const result = await storeProductService.create(storeProduct);

      if (result.success) {
        let message = 'Thêm thành công';

        if (data.length === perPage) {
          message += ' vào trang cuối';
        } else {
          setStoreProducts((data) => [
            ...data,
            {
              ...storeProduct,
              id: result.id,
            },
          ]);
        }

        setCount((count) => count + 1);
        setToastMessage({
          isVisible: true,
          type: toastMessageType.SUCCESS,
          msg: {
            title: 'Thành công',
            duration: 3000,
            desc: message,
          },
        });
      } else {
        throw new Error();
      }
    } catch (error) {
      if (error.name === 'AbortError') {
        console.log('Cancelled request');
      } else {
        setToastMessage({
          isVisible: true,
          type: toastMessageType.ERROR,
          msg: {
            title: 'Thất bại',
            duration: 3000,
            desc: 'Thêm thất bại',
          },
        });
      }
    }
  };

  update = async (
    storeProduct,
    setStoreProducts,
    setToastMessage,
    closeEditButton,
  ) => {
    try {
      const result = await storeProductService.update(storeProduct);

      if (result.success) {
        setStoreProducts((data) =>
          [...data].map((item) =>
            item.id === storeProduct.id ? storeProduct : item,
          ),
        );
        setToastMessage({
          isVisible: true,
          type: toastMessageType.SUCCESS,
          msg: {
            title: 'Thành công',
            duration: 3000,
            desc: 'Cập nhật thành công',
          },
        });

        closeEditButton();
      } else {
        throw new Error();
      }
    } catch (error) {
      if (error.name === 'AbortError') {
        console.log('Cancelled request');
      } else {
        setToastMessage({
          isVisible: true,
          type: toastMessageType.ERROR,
          msg: {
            title: 'Thất bại',
            duration: 3000,
            desc: 'Cập nhật thất bại',
          },
        });
      }
    }
  };

  delete = async (storeProduct, setStoreProducts, setToastMessage) => {
    try {
      const result = await storeProductService.deleteById(storeProduct);

      if (result.success) {
        setToastMessage({
          isVisible: true,
          type: toastMessageType.SUCCESS,
          msg: {
            title: 'Thành công',
            duration: 3000,
            desc: 'Thêm thành công',
          },
        });
        setStoreProducts((data) =>
          [...data].filter((item) => item.id !== storeProduct.id),
        );
      } else {
        throw new Error();
      }
    } catch (error) {
      if (error.name === 'AbortError') {
        console.log('Cancelled request');
      } else {
        setToastMessage({
          isVisible: true,
          type: toastMessageType.ERROR,
          msg: {
            title: 'Thất bại',
            duration: 3000,
            desc: 'Thêm thất bại',
          },
        });
      }
    }
  };
}

export default StoreProductController;

import React from 'react';
import clsx from 'clsx';

import styles from './EditShipService.module.scss';
import { ToastMessage } from '@components';
import toastMessageType from '@constant/ToastMessage';

import event from './event';

function EditOnRight({
  selectedShipService,
  closeEditButton,
  fetchShipServices,
}) {
  const [shipService, setShipService] = React.useState({
    name: '',
  });
  const [toastMessage, setToastMessage] = React.useState({
    isVisible: false,
    type: toastMessageType.SUCCESS,
    msg: {
      title: 'Lỗi',
      duration: 3000,
      desc: '',
    },
  });
  const handleInputChange = React.useCallback((e) =>
    setShipService({
      ...shipService,
      name: e.target.value,
    }),
  );
  const saveNewData = React.useCallback(
    (e) => {
      e.preventDefault();

      if (!shipService.name) {
        setToastMessage({
          isVisible: true,
          type: toastMessageType.ERROR,
          msg: {
            title: 'Lỗi',
            duration: 3000,
            desc: 'Tên không được để trống',
          },
        });
      } else {
        event.submitForm(
          shipService,
          setToastMessage,
          fetchShipServices,
          closeEditButton,
        );
      }
    },
    [shipService],
  );
  const canSubmit = React.useMemo(() => {
    const trimName = shipService.name.trim();

    return (
      !trimName || trimName === selectedShipService.name || trimName === ''
    );
  }, [shipService.name, selectedShipService.name]);

  React.useLayoutEffect(() => {
    setShipService(selectedShipService);
  }, [selectedShipService]);

  React.useEffect(() => {
    return () => {};
  }, []);

  return (
    <form
      className={clsx(styles.form)}
      onSubmit={saveNewData}
      aftercontent={shipService.id ? 'Chỉnh sửa' : 'Tạo mới'}
    >
      <input
        className={clsx(styles.formInput)}
        value={shipService.name}
        onChange={handleInputChange}
        placeholder="Nhập tên đơn vị vận chuyển..."
      />

      <div className={clsx(styles.formAction)}>
        <button
          type="button"
          className={clsx(
            styles.formActionItem,
            styles.formActionItem_LowPriority,
          )}
          onClick={closeEditButton}
        >
          Hủy bỏ
        </button>

        <input
          className={clsx(styles.formActionItem, {
            [styles.disabled]: canSubmit,
          })}
          type="submit"
          value="Lưu"
          disabled={canSubmit}
        />
      </div>

      <ToastMessage open={toastMessage} setOpen={setToastMessage} />
    </form>
  );
}

export default EditOnRight;

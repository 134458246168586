import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { confirmDialog } from 'primereact/confirmdialog';

import actions from '@/application/actions';
import { Button } from '@components';
import { Table } from '@/components/organisms';
import { QtProductDetailModal } from '@components/Modal';
import { useThrottle } from '@hooks';
import styles from './EditQtProduct.module.scss';
import { Input } from '@/components/atoms';

function EditStoreProduct(props) {
  const {
    state: {
      stock: {
        qtProducts,
        filter: { qtProducts: filter },
      },
    },
    actions: {
      toggleQtProductModal,
      fetchQtProducts,
      fetchQtProduct,
      deleteQtProduct,
      updateQtProductFilter,
    },
  } = props;

  const onEditButton = useCallback(
    ({ id }) =>
      () =>
        fetchQtProduct({ id, isOpenModal: true }),
    [],
  );
  const onDeleteButton = useCallback(
    ({ id, name }) =>
      () =>
        confirmDialog({
          message: `Bạn có chắc chắn xóa ${name}?`,
          header: 'Xác nhận xóa',
          icon: 'pi pi-info-circle',
          acceptClassName: 'p-button-danger',
          accept: () => deleteQtProduct({ id }),
        }),
  );
  const updateQtProductFilterThrottle = useThrottle(updateQtProductFilter, 500);
  const updateFilterInput = (e) => {
    const { name, value } = e.target;

    updateQtProductFilterThrottle({ [name]: value });
  };
  const updatePage = (newPage) =>
    updateQtProductFilterThrottle({ page: newPage });

  useEffect(() => {
    if (filter.name === '' || filter.name.length > 3) {
      fetchQtProducts(filter);
    }
  }, [filter]);

  const Action = ({ item }) => (
    <div className={styles.customAction}>
      <button onClick={onEditButton(item)}>
        <i className="pi pi-pencil"></i>
      </button>

      <button onClick={onDeleteButton(item)}>
        <i className="pi pi-trash"></i>
      </button>
    </div>
  );

  const tableOptions = [
    {
      field: 'id',
      name: 'Mã SP',
      className: styles.tableFieldSmall,
    },
    {
      field: 'count',
      name: 'Số lượng',
      className: styles.tableFieldSmall,
    },
    {
      field: 'name',
      name: 'Tên sản phẩm',
      className: styles.tableFieldLarge,
    },
    {
      field: 'importPrice.vnd',
      name: 'Giá nhập',
      className: styles.tableFieldMedium,
    },
    {
      field: 'price.vnd',
      name: 'Giá lẻ',
      className: styles.tableFieldMedium,
    },
    {
      field: 'wholeSalePrice.vnd',
      name: 'Giá sỉ',
      className: styles.tableFieldMedium,
    },
    {
      name: 'Hành động',
      custom: Action,
      className: styles.tableFieldSmall,
    },
  ];

  return (
    <div className={styles.container}>
      <h1 className={styles.header}>Danh sách sản phẩm</h1>

      <div className={styles.content}>
        <div className={styles.contentHeader}>
          <Input
            name="name"
            placeholder="Tim kiếm bởi tên sản phẩm"
            onChange={updateFilterInput}
          />

          <Button
            content={
              <React.Fragment>
                <i className={clsx('fas fa-plus', styles.btnIcon)} />
                Thêm mới
              </React.Fragment>
            }
            onClick={() =>
              toggleQtProductModal({ isOpen: true, isCreate: true })
            }
          />
        </div>

        <Table
          headers={tableOptions}
          data={qtProducts.list}
          containerClassName={styles.tableContainer}
          paginate={{
            totalPage: qtProducts.totalPage,
            currentPage: filter.page,
            updatePage,
            noPages: 9,
          }}
        />
      </div>

      <QtProductDetailModal />
    </div>
  );
}

const mapStateToProps = ({ state }) => ({ state });

export default connect(mapStateToProps, actions)(EditStoreProduct);

import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { confirmDialog } from 'primereact/confirmdialog';

import actions from '@/application/actions';
import { Button } from '@components';
import { Table } from '@/components/organisms';
import { SupplierDetailModal } from '@components/Modal';
import { useThrottle } from '@hooks';
import styles from './styles.module.scss';

const EditSupplier = (props) => {
  const {
    state: {
      stock: {
        suppliers,
        filter: { suppliers: filter },
      },
    },
    actions: {
      toggleSupplierModal,
      fetchSuppliers,
      fetchSupplier,
      deleteSupplier,
      updateSupplierFilter,
    },
  } = props;

  const onEditButton = useCallback(
    ({ id }) =>
      () =>
        fetchSupplier({ id, isOpenModal: true }),
    [],
  );
  const onDeleteButton = useCallback(
    ({ id, name }) =>
      () =>
        confirmDialog({
          message: `Bạn có chắc chắn xóa ${name}?`,
          header: 'Xác nhận xóa',
          icon: 'pi pi-info-circle',
          acceptClassName: 'p-button-danger',
          accept: () => deleteSupplier({ id }),
        }),
  );
  const updatePage = useThrottle(
    (newPage) => updateSupplierFilter({ page: newPage }),
    500,
  );

  useEffect(() => {
    fetchSuppliers(filter);
  }, [filter]);

  const Action = ({ item }) => (
    <div className={styles.customAction}>
      <button onClick={onEditButton(item)}>
        <i className="pi pi-pencil"></i>
      </button>

      <button onClick={onDeleteButton(item)}>
        <i className="pi pi-trash"></i>
      </button>
    </div>
  );

  const tableOptions = [
    {
      field: 'id',
      name: 'Mã NCC',
      className: styles.tableFieldSmall,
    },
    {
      field: 'name',
      name: 'Tên NCC',
      className: styles.tableFieldLarge,
    },
    {
      field: 'address',
      name: 'Địa chỉ',
      className: styles.tableFieldLarge,
      isPC: true,
    },
    {
      field: 'phone.display',
      name: 'SĐT',
      className: styles.tableFieldMedium,
    },
    {
      name: 'Hành động',
      custom: Action,
      className: styles.tableFieldSmall,
    },
  ];

  return (
    <div className={styles.container}>
      <h1 className={styles.header}>Danh sách nhà cung cấp</h1>

      <div className={styles.content}>
        <div className={styles.contentHeader}>
          <Button
            content={
              <React.Fragment>
                <i className={clsx('fas fa-plus', styles.btnIcon)} />
                Thêm mới
              </React.Fragment>
            }
            onClick={() =>
              toggleSupplierModal({ isOpen: true, isCreate: true })
            }
          />
        </div>

        <Table
          headers={tableOptions}
          data={suppliers.list}
          containerClassName={styles.tableContainer}
          paginate={{
            totalPage: suppliers.totalPage,
            currentPage: filter.page,
            updatePage,
            noPages: 9,
          }}
        />
      </div>

      <SupplierDetailModal />
    </div>
  );
};

const mapStateToProps = ({ state }) => ({ state });

export default connect(mapStateToProps, actions)(EditSupplier);

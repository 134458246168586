import Marketter from '@/domain/models/entities/master/ecommerce/marketter';
import { _p } from '@/domain/models/utils/model-proto';
import BaseModel from '../..';

class Ecommerce extends BaseModel {
  static properties = {
    id: null,
    name: null,
    markettingByUser: _p(Marketter, null),
  };

  constructor(obj) {
    super(obj);
  }

  /*
  id: number | null;
  name: string;
  markettingByUser: Marketter | null;
  */
}

export default Ecommerce;

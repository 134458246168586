export const formatUser = (user) => {
  return {
    ...user,
    hasPermission: user.permissions.reduce(
      (prev, permission) => ({
        ...prev,
        [permission.permissionId]: true,
      }),
      {},
    ),
  };
};

export const formatUsers = (users) => users.map((user) => formatUser(user));

import React from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';

import styles from './MarketDetail.module.scss';
import { stopPropagation } from '@/utilities';
import {
  pagesAll,
  detailsBySelectedPage,
  toggleSelectedEcommerceID,
  selectSelectedPageIds,
  selectPageById,
  selectFilterDates,
  toggleAllDates,
  toggleSelectedDate,
  selectSelectedPages,
  toggleAllPages,
} from '../slice';
import _ from 'lodash';

function MarketDetail({ className = '' }) {
  const dispatch = useDispatch();

  const ecommercePages = useSelector(pagesAll);
  const pages = useSelector(detailsBySelectedPage);
  const pageByIds = useSelector(selectPageById);
  const filterDates = useSelector(selectFilterDates);
  const visiblePages = useSelector(selectSelectedPages);

  // Handle filter by ecommerce
  const [filterEcommerceVisible, setFilterEcommerceVisible] =
    React.useState(false);
  const toggleFilterEcommerceVisible = React.useCallback(() => {
    setFilterEcommerceVisible((item) => !item);
  }, []);

  const selectedPageIds = useSelector(selectSelectedPageIds);

  const onSelectFilterPage = React.useCallback(
    (id) => () => {
      dispatch(toggleSelectedEcommerceID(id));
    },
    [],
  );

  const checkedAll = React.useMemo(
    () => selectedPageIds.length === ecommercePages.length,
    [selectedPageIds],
  );

  const onCheckAll = React.useCallback(() => {
    dispatch(toggleAllPages(!checkedAll));
  }, [checkedAll]);

  const [filterDateVisible, setFilterDateVisible] = React.useState(false);
  const toggleFilterDateVisible = React.useCallback(() => {
    setFilterDateVisible((item) => !item);
  }, []);
  const checkedAllDates = React.useMemo(() => {
    return filterDates.every((filterDate) => filterDate.checked);
  }, [filterDates]);

  const onCheckAllDates = React.useCallback(() => {
    dispatch(toggleAllDates(!checkedAllDates));
  }, [checkedAllDates]);

  const onSelectFilterDate = React.useCallback(
    (date) => () => {
      dispatch(toggleSelectedDate(date));
    },
    [filterDates],
  );

  React.useEffect(() => {
    const closeFilterOptions = () => {
      setFilterDateVisible(false);
      setFilterEcommerceVisible(false);
    };

    document.addEventListener('click', closeFilterOptions);

    return () => document.removeEventListener('click', closeFilterOptions);
  }, []);

  return (
    <div className={`${className} mx-[16px]`}>
      <div className={clsx(styles.header, styles.row)}>
        <div
          className={clsx(styles.cell, styles.cellWithIcon)}
          onClick={(e) => e.stopPropagation()}
        >
          Ngày
          <i
            className="fa-solid fa-filter"
            onClick={toggleFilterDateVisible}
          ></i>
          <div
            className={clsx(styles.filter, {
              [styles.show]: filterDateVisible,
            })}
          >
            <ul
              className={clsx(
                styles.filterList,
                'overflow-y-auto max-h-[400px]',
              )}
            >
              <li className={clsx(styles.filterItem)}>
                <input
                  type="checkbox"
                  id="ecommerce-all"
                  checked={checkedAllDates}
                  onChange={onCheckAllDates}
                />
                <label htmlFor="ecommerce-all">Chọn tất cả</label>
              </li>
              {filterDates.map((selectedFilterDate) => (
                <li className={styles.filterItem} key={selectedFilterDate.date}>
                  <input
                    type="checkbox"
                    id={`filter-date-${selectedFilterDate.date}`}
                    checked={selectedFilterDate.checked}
                    onChange={onSelectFilterDate(selectedFilterDate.date)}
                  />

                  <label htmlFor={`filter-date-${selectedFilterDate.date}`}>
                    {selectedFilterDate.date}
                  </label>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div
          className={clsx(styles.cell, styles.cellWithIcon)}
          onClick={(e) => e.stopPropagation()}
        >
          Kênh TMĐT
          <i
            className="fa-solid fa-filter"
            onClick={toggleFilterEcommerceVisible}
          ></i>
          <div
            className={clsx(styles.filter, {
              [styles.show]: filterEcommerceVisible,
            })}
          >
            <ul className={styles.filterList}>
              <li className={styles.filterItem}>
                <input
                  type="checkbox"
                  id="ecommerce-all"
                  checked={checkedAll}
                  onChange={onCheckAll}
                />
                <label htmlFor="ecommerce-all">Chọn tất cả</label>
              </li>
              {ecommercePages.map((ecommercePage) => (
                <li className={styles.filterItem} key={ecommercePage.id}>
                  <input
                    type="checkbox"
                    id={`ecommerce-${ecommercePage.id}`}
                    checked={ecommercePage.checked}
                    onChange={onSelectFilterPage(ecommercePage.id)}
                  />

                  <label htmlFor={`ecommerce-${ecommercePage.id}`}>
                    {ecommercePage.name}
                  </label>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className={styles.cell}>Số đơn</div>
      </div>

      <div className={clsx(styles.body)}>
        {Object.keys(visiblePages).map((date, dateIndex) => (
          <React.Fragment key={`table_${date}`}>
            {Object.keys(pages[date]).map((pageId, index) => (
              <div className={clsx(styles.row)} key={`${pageId}_${date}`}>
                <div
                  className={clsx(styles.cell, {
                    [styles.upperline]: index === 0,
                  })}
                >
                  {index === 0 ? date : ''}
                </div>

                <div
                  className={clsx(styles.cell, {
                    [styles.upperline]: dateIndex !== 0 || index !== 0,
                    'bg-red-200':
                      pages[date][pageId] && pages[date][pageId] > 0,
                  })}
                >
                  {pageByIds[pageId]?.name}
                </div>

                <div
                  className={clsx(styles.cell, {
                    [styles.upperline]: dateIndex !== 0 || index !== 0,
                    'bg-red-200 font-semibold':
                      pages[date][pageId] && pages[date][pageId] > 0,
                  })}
                >
                  {pages[date][pageId] || 0}
                </div>
              </div>
            ))}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
}

export default MarketDetail;

import Stock from '@/domain/models/entities/stock';
import facade from '@/domain/models/utils/facade';

export * as asyncActions from './async/stock';
const updateFilter = (state, key, value) => {
  const { filter } = state.stock;
  const newValue = facade.update(filter[key], value);
  const newFilter = facade.update(filter, { [key]: newValue });

  return facade.update(state.stock, { filter: newFilter });
};

export const actions = {
  updateImportOrderFilter: (state, { payload }) => {
    state.stock = updateFilter(state, 'importOrders', payload);
  },
  updateExportOrderFilter: (state, { payload }) => {
    state.stock = updateFilter(state, 'exportOrders', payload);
  },
  updateQtProductFilter: (state, { payload }) => {
    state.stock = updateFilter(state, 'qtProducts', payload);
  },
  updateStatisticFilter: (state, { payload }) => {
    state.stock = updateFilter(state, 'statistic', payload);
  },
};

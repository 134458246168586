import BaseModel from '../..';

class Phone extends BaseModel {
  static properties = {
    id: null,
    phone: null,
  };

  constructor(obj) {
    super(obj);
  }

  /*
id: number | null;
type: string | null;
name: string | null;
*/
}

export default Phone;

import React from 'react';
import clsx from 'clsx';

import styles from './ToastMessage.module.scss';
import Constant from '@constant/ToastMessage';

const ToastMessage = ({ open, setOpen }) => {
    // open = {isVisible, type, msg: {title, duration, desc}}

    React.useEffect(() => {
        let timeout = 0;

        if (open.isVisible) {
            timeout = setTimeout(() => {
                timeout = 0;
                handleClose();
            }, open.msg.duration);
        }

        return () => {
            if (timeout) {
                clearTimeout(timeout);
            }
        };
    }, [open]);

    const handleClose = () => {
        setOpen({
            isVisible: false,
            type: '',
            msg: {},
        });
    };

    return (
        <React.Fragment>
            {open.isVisible && (
                <div
                    className={clsx(styles.toast, {
                        [styles.error]: open.type === Constant.ERROR,
                        [styles.success]: open.type === Constant.SUCCESS,
                    })}
                >
                    <div className={styles.toast__left}>
                        {open.type === Constant.ERROR ? (
                            <i
                                className={clsx(
                                    'fa-solid fa-circle-exclamation',
                                    styles.toast__icon,
                                )}
                            />
                        ) : open.type === Constant.SUCCESS ? (
                            <i
                                className={clsx(
                                    'fa-solid fa-circle-info',
                                    styles.toast__icon,
                                )}
                            />
                        ) : (
                            ''
                        )}
                        <div className={styles.toast__content}>
                            <div className={styles.toast__title}>
                                {open.msg.title}
                            </div>
                            <div className={styles.toast__desc}>
                                {open.msg.desc}
                            </div>
                        </div>
                    </div>
                    <div className={styles.toast__right}>
                        <i
                            className={clsx(
                                'fa-solid fa-xmark',
                                styles.toast__close,
                            )}
                            onClick={handleClose}
                        />
                    </div>
                </div>
            )}
        </React.Fragment>
    );
};

export default React.memo(ToastMessage);

class Facade {
  upsert(Type, objValue, prev) {
    return prev ? this.update(prev, objValue) : this.create(Type, objValue);
  }

  create(Type, objValue) {
    if (!objValue) {
      return null;
    }

    const { properties } = Type;

    if (Array.isArray(objValue)) {
      return new Type({ list: this._makeList(Type, objValue) });
    }

    const result = Object.keys(properties).reduce((prev, key) => {
      const propertyValue = properties[key];
      let targetValue = null;

      const value =
        objValue[key]?.defaultValue && objValue[key]?.type
          ? objValue[key]?.defaultValue
          : objValue[key];

      if (typeof value === 'undefined') {
        if (propertyValue && typeof propertyValue === 'object') {
          const { type: PropertyType, defaultValue, primitive } = propertyValue;
          if (key === 'list') {
            targetValue = [];
          } else {
            if (primitive) {
              targetValue = defaultValue;
            } else {
              targetValue = this.create(PropertyType, defaultValue);
            }
          }
        } else {
          if (key === 'phone') console.log(propertyValue);
          targetValue = propertyValue;
        }
        if (key === 'phone') console.log(targetValue);
      } else if (Array.isArray(value)) {
        if (propertyValue && typeof propertyValue === 'object') {
          const { type: PropertyType } = propertyValue;
          if (key === 'list') {
            targetValue = this._makeList(Type, value);
          } else {
            targetValue = new PropertyType({
              list: this._makeList(PropertyType, value),
            });
          }
        } else {
          targetValue = value;
        }
      } else {
        if (propertyValue && typeof propertyValue === 'object') {
          const { type: PropertyType, primitive } = propertyValue;
          if (key === 'list') {
            targetValue = [];
          } else {
            targetValue = primitive
              ? this._getPrimitiveValue(propertyValue, value)
              : this.create(PropertyType, value);
          }
        } else {
          targetValue = value;
        }
      }

      return {
        ...prev,
        [key]: targetValue,
      };
    }, {});

    return new Type(result);
  }

  _makeList(Type, value) {
    const { properties } = Type;
    let targetValue = properties.list
      ? value.filter(Boolean).map((v) => {
          const itemType = properties.list;

          if (itemType && typeof itemType === 'object') {
            const { primitive, type: PropertyType } = itemType;

            return primitive
              ? this._getPrimitiveValue(itemType, v)
              : this.create(PropertyType, v);
          }

          return itemType;
        })
      : value;

    return targetValue;
  }

  _getPrimitiveValue(itemType, rawValue) {
    const PRIMITIVE_TYPE = [String, Number, Boolean];

    const { defaultValue, type: PropertyType } = itemType;
    const { value: defaultItemValue } = PropertyType.properties;

    let val = rawValue;
    if (!rawValue && rawValue !== 0 && rawValue !== '')
      val = defaultValue?.value;
    else if (rawValue.constructor === PropertyType) return rawValue;
    else if (
      defaultItemValue &&
      PRIMITIVE_TYPE.every((type) => type !== defaultItemValue.constructor) &&
      defaultItemValue.constructor !== rawValue.constructor
    ) {
      // non-primitive case
      const DefaultValueType = defaultItemValue.constructor;
      val = new DefaultValueType(rawValue);
    }

    return new PropertyType({ value: val });
  }

  update(target, objValue) {
    const Type = target.constructor;
    const result = { ...target };
    const { properties } = Type;
    const propertiesKeys = Object.keys(properties).reduce(
      (prev, cur) => ({ ...prev, [cur]: true }),
      {},
    );

    Object.keys(objValue).forEach((key) => {
      if (!propertiesKeys[key]) {
        return;
      }
      const value = objValue[key];
      const propertyValue = properties[key];

      if (propertyValue && typeof propertyValue === 'object') {
        const { type: PropertyType, primitive } = propertyValue;
        if (key === 'list') {
          if (Array.isArray(value)) {
            result[key] = value.map((v) => {
              if (primitive) {
                result[key] = this._getPrimitiveValue(propertyValue, value);
              } else {
                result[key] = this.create(PropertyType, v);
              }
            });
          } else {
            result[key] = null;
          }
        } else {
          if (primitive) {
            result[key] = this._getPrimitiveValue(propertyValue, value);
          } else {
            result[key] = this.create(PropertyType, value);
          }
        }
      } else {
        result[key] = value;
      }
    }, {});

    return new Type(result);
  }
}

export default new Facade();

import { IMPORT_ORDER_STATUSES } from '@/config/constants';
import { _p, _pc } from '@/domain/models/utils/model-proto';
import BaseModel from '@/domain/models/entities/list-filter';
import QtDate from '@/domain/models/value-object/qtDate';

class ImportOrderListFilter extends BaseModel {
  static properties = {
    ...BaseModel.properties,
    status: IMPORT_ORDER_STATUSES.COMPLETE,
    from: _pc(QtDate, QtDate.startOf('d')),
    to: _pc(QtDate, QtDate.endOf('d')),
  };

  constructor(obj) {
    super(obj);
  }

  /*
  page: number | null;
  */

  get value() {
    const result = { ...this };
    const status = parseInt(result.status);

    if (Number.isNaN(status) || !status) {
      delete result.status;
    }

    return result;
  }
}

export default ImportOrderListFilter;

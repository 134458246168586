import orderService from '@/domain/services/order';
import ORDER_CONST from '@/config/order';

export const updateBill = {
  onDispatch: async ({ bill, onSuccess, onError }, options) => {
    const { getState } = options;
    try {
      const newBill = ORDER_CONST.EDITABLE_FIELDS.reduce(
        (prev, field) => {
          prev[field] = bill[field];

          return prev;
        },
        { id: bill.id },
      );
      await orderService.updateBill({
        ...getState().state,
        bill: newBill,
      });
      onSuccess();
    } catch (e) {
      onError(e?.response?.data);
    }
  },
  fulfilled: (state, { payload }) => {},
};

export const updateBillStatus = {
  onDispatch: async ({ bill, onSuccess, onError }, options) => {
    const { getState } = options;
    try {
      await orderService.updateBill({
        ...getState().state,
        bill: {
          statusID:
            bill.statusID === ORDER_CONST.ORDER_STATUS.COMPLETE
              ? ORDER_CONST.ORDER_STATUS.WAITING
              : ORDER_CONST.ORDER_STATUS.COMPLETE,
          id: bill.id,
        },
      });
      onSuccess();
    } catch (e) {
      onError(e?.response?.data);
    }
  },
  fulfilled: (state, { payload }) => {},
};

import { _p } from '@/domain/models/utils/model-proto';
import BaseModel from '../../..';
import QtProductReport from './item';

class QtProductsReport extends BaseModel {
  static properties = {
    list: _p(QtProductReport, new QtProductReport()),
  };

  constructor(obj) {
    super(obj);
  }
}

export default QtProductsReport;

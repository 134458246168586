import { marketService } from '@services';
import toastMessageType from '@constant/ToastMessage';

class MarketController {
  fetchByDate = async (cancelSignal, date, selectedUser, setData) => {
    try {
      const result = await marketService.fetchByDate(
        cancelSignal,
        selectedUser,
        date,
      );

      if (result.success) {
        // Set Data
        if (result.notFound) {
          let totalOrders = 0;

          setData(
            Object.values(result.details).map((detail) => {
              totalOrders += detail.orders;
              return {
                ...detail,
                name: detail.ecommerceName,
              };
            }),
          );
        } else {
          setData(
            Object.values(result.data.details).map((detail) => ({
              msgQuantity: 0,
              paid: 0,
              ...detail,
              name: detail.ecommerceName,
            })),
          );
        }
      }
    } catch (err) {
      if (err.name === 'AbortError') {
        console.log('Aborted!');
      }
    }
  };

  fetchByCustomDate = async (cancelSignal, month, year, setData, setTotal) => {
    try {
      const result = await marketService.fetchByCustomDate(
        cancelSignal,
        month,
        year,
      );

      if (result && !result.error) {
        // Set Data
        if (result.notFound) {
          let totalOrders = 0;

          setData(
            Object.values(result.details).map((detail) => {
              totalOrders += detail.orders;
              return {
                ...detail,
                name: detail.ecommerceName,
              };
            }),
          );

          if (setTotal) {
            setTotal({
              orders: totalOrders,
            });
          }
        } else {
          let totalOrders = 0;

          setData(
            Object.values(result.details).map((detail) => {
              totalOrders += detail.orders;
              return {
                ...detail,
                name: detail.ecommerceName,
              };
            }),
          );

          if (setTotal) {
            setTotal({
              paid: result.totalPaid,
              msgQuantity: result.totalMsgQuantity,
              orders: totalOrders,
            });
          }
        }
      }
    } catch (err) {
      if (err.name === 'AbortError') {
        console.log('Aborted!');
      }
    }
  };

  updatePages = async (
    cancelSignal,
    date,
    selectedUser,
    pages,
    setToastMessage,
  ) => {
    try {
      const result = await marketService.updatePages(
        cancelSignal,
        date,
        selectedUser,
        pages,
      );

      if (result && result.success) {
        setToastMessage({
          isVisible: true,
          type: toastMessageType.SUCCESS,
          msg: {
            title: 'Lưu',
            duration: 3000,
            desc: 'Cập nhật thông tin thành công',
          },
        });
      } else {
        setToastMessage({
          isVisible: true,
          type: toastMessageType.ERROR,
          msg: {
            title: 'Lỗi',
            duration: 3000,
            desc: result.error || result.message || 'Không thể cập nhật',
          },
        });
      }
    } catch (err) {
      if (err.name === 'AbortError') {
        console.log('Aborted!');
      }
    }
  };

  exportReport = async (
    cancelSignal,
    date,
    setDownloadUrl,
    setReportDone,
    setToastMessage,
  ) => {
    try {
      const { file, fileName } = await marketService.exportReport(
        cancelSignal,
        date,
      );
      const url = URL.createObjectURL(file);
      setDownloadUrl({ href: url, fileName });
    } catch (err) {
      console.log(err);
      if (err.name === 'AbortError') {
        console.log('Aborted!');
      } else {
        setToastMessage({
          isVisible: true,
          type: toastMessageType.ERROR,
          msg: {
            title: 'Lỗi',
            duration: 3000,
            desc: 'Không thể lấy báo cáo',
          },
        });
      }
    } finally {
      setReportDone(true);
    }
  };

  reportDetail = async (
    cancelSignal,
    date,
    setDetails,
    setEcommercePages,
    setToastMessage,
  ) => {
    try {
      const result = await marketService.reportDetail(cancelSignal, date);

      setDetails(result.details || {});
      setEcommercePages(
        (result.ecommerces || []).map((ecommercePage) => ({
          ...ecommercePage,
          check: true,
        })),
      );
    } catch (error) {
      setToastMessage({
        isVisible: true,
        type: toastMessageType.ERROR,
        msg: {
          title: 'Lỗi',
          duration: 3000,
          desc: 'Không thể load dữ liệu',
        },
      });
    }
  };
}

export default MarketController;

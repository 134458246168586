import { ROLE_ID } from '@/config/constants';
import PERMISSIONS from '@/config/permissions';
import { _p } from '../../utils/model-proto';
import BaseModel from '..';
import Role from './role';
import Permissions from './permissions';
import NoteGroups from '../master/noteGroups';

const { MANAGER, ADMIN } = ROLE_ID;
const ADMIN_ID = [MANAGER, ADMIN];

class User extends BaseModel {
  static properties = {
    id: null,
    username: null,
    displayName: null,
    email: null,
    role: _p(Role, new Role()),
    token: null,
    noteGroups: _p(NoteGroups, new NoteGroups()),
    permissions: _p(Permissions, new Permissions()),
    hasPermission: null,
  };

  constructor(obj) {
    super(obj);
  }

  /*
  username: string;
  name: string;
  email: string;
  role: Role;
  token: string;
  */

  get canInsertProvinceKeyword() {
    return this.permissions.can(PERMISSIONS.INSERT_PROVINCE_KEYWORD);
  }

  get canDeleteProvinceKeyword() {
    return this.permissions.can(PERMISSIONS.DELETE_PROVINCE_KEYWORD);
  }

  get canImportQtProduct() {
    return this.permissions.can(PERMISSIONS.IMPORT_QT_PRODUCT);
  }

  get isAdmin() {
    return ADMIN_ID.some((id) => this.role?.id === id);
  }

  get isManager() {
    return MANAGER === this.role?.id;
  }
}

export default User;

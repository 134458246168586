import { homeImageService } from '@services';
import unitOfWork from '@controllers';
import toastMessageType from '@constant/ToastMessage';

const getDays = (signal, setDays) => {
    unitOfWork.site.getAllHomeImages(signal, setDays);
};

const handleImagePaste = (e, currentDay, setDays, setChangeLists) => {
    const data = e.clipboardData;
    if (data.files.length > 0) {
        setChangeLists((changeLists) => [
            ...changeLists.filter(
                (changeList) => changeList.day !== currentDay.day,
            ),
            {
                day: currentDay.day,
                img: data.files[0],
                oldLink: currentDay.link,
            },
        ]);
        const urlImage = URL.createObjectURL(data.files[0]);
        setDays((days) =>
            [
                ...days.filter((day) => day.day !== currentDay.day),
                {
                    day: currentDay.day,
                    link: urlImage,
                },
            ].sort((first, second) => first.day - second.day),
        );
        return true;
    }
    return false;
};

const handleRemoveImage = (
    currentDay,
    changeLists,
    setDays,
    setChangeLists,
) => {
    const changedDay = changeLists.find(
        (changeList) => changeList.day === currentDay.day,
    );
    if (changedDay) {
        setDays((days) =>
            days.map((day) =>
                day.day === currentDay.day
                    ? {
                          ...day,
                          link: changedDay.oldLink,
                      }
                    : day,
            ),
        );

        setChangeLists((changeLists) => [
            ...changeLists.filter(
                (changeList) => changeList.day !== currentDay.day,
            ),
        ]);
    }
};

const handleSaveClick = (changeLists, setChangeLists, setToastMessage) => {
    homeImageService
        .update(
            changeLists.map((changeList) => ({
                day: changeList.day,
                img: changeList.img,
            })),
        )
        .then((res) => res.json())
        .then(() => {
            setChangeLists([]);
            setToastMessage({
                isVisible: true,
                type: toastMessageType.SUCCESS,
                msg: {
                    title: 'Thành công',
                    duration: 3000,
                    desc: 'Lưu thành công',
                },
            });
        });
};

export { getDays, handleImagePaste, handleRemoveImage, handleSaveClick };

import { mapAsyncActions } from './utils/async-actions-helper';
import { asyncActions as masterAsyncActions } from '../reducer/master';
import facade from '@/domain/models/utils/facade';
import MasterData from '@/domain/models/entities/master';

export const initialState = facade.create(MasterData, {});
export { actions as reducers } from '../reducer/master';
export const { asyncActions, extraReducers } = mapAsyncActions(
  'ui',
  masterAsyncActions,
);

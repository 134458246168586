import { fetcher } from '../utilities';

const urlOrigin = '/api/status';

const getAll = async (cancelSignal) => {
    const result = await fetcher(`${urlOrigin}/list`, {
        signal: cancelSignal,
    });

    return await result.json();
};

export { getAll };

import BaseModel from '../..';
import Permission from '../permission';
import { _p } from '@/domain/models/utils/model-proto';

class Permissions extends BaseModel {
  static properties = {
    list: _p(Permission, new Permission()),
  };

  constructor(obj) {
    super(obj);
  }

  /*
  type: string | null;
  name: string | null;
  */

  can(permission) {
    return this.list.some(({ permissionId }) => permissionId === permission);
  }
}

export default Permissions;

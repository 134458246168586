import { _p } from '../../../utils/model-proto';
import BaseModel from '../..';
import ShipService from '../shipService';

class ShipServices extends BaseModel {
  static properties = {
    list: _p(ShipService, new ShipService()),
  };

  constructor(obj) {
    super(obj);
  }

  /*
  list: ShipService;
  */
}

export default ShipServices;

import { _p, _pc } from '@/domain/models/utils/model-proto';
import BaseModel from '@/domain/models/entities';
import QtDate from '@/domain/models/value-object/qtDate';

class StatisticFilter extends BaseModel {
  static properties = {
    from: _pc(QtDate, QtDate.startOf('d')),
    to: _pc(QtDate, QtDate.endOf('d')),
  };

  constructor(obj) {
    super(obj);
  }

  /*
  page: number | null;
  */

  get value() {
    return this;
  }
}

export default StatisticFilter;

import { fetcher } from '../utilities';

const url = '/api/import-status';

const getAll = async (cancelSignal) => {
  const result = await fetcher(`${url}/list`, {
    signal: cancelSignal,
  });
  const data = await result.json();

  return data;
};

export { getAll };

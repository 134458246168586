import styles from './ConfirmModal.module.scss';
import logo from '@assets/image/logo.ico';
import clsx from 'clsx';
import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';

function ConfirmModal({ modal, show, closeModal }, ref) {
    const acceptBtn = useRef(null);

    useEffect(() => {
        const cb = (e) => e.key === 'Escape' && closeModal();
        document.addEventListener('keydown', cb);
        return () => document.removeEventListener('keydown', cb);
    }, []);

    useImperativeHandle(ref, () => ({
        acceptFocus: function () {
            acceptBtn.current.focus();
        },
    }));

    return (
        <div
            className={clsx(styles.modalWrapper, {
                [styles.hide]: !show,
            })}
            onClick={(e) => e.stopPropagation()}
        >
            <div className={styles.overlay} onClick={() => closeModal()}></div>

            <div className={styles.modalBody}>
                <div className={styles.heading}>
                    <img src={logo} className={styles.logo} alt="Logo" />
                    <h3>Xác nhận</h3>
                </div>

                <div className={styles.body}>
                    <div className={styles.bodyTitle}>
                        <i className="fas fa-circle-question" />
                        <span>{modal.actionTitle}</span>
                    </div>
                    <div className={styles.bodyContent}>
                        {modal.actionConfirm &&
                            modal.actionConfirm
                                .split('\n')
                                .map((msg, index) => <div key={index}>{msg}</div>)}
                    </div>

                    <div className={styles.bodyFooter}>
                        <button
                            className={styles.btn}
                            onClick={() => {
                                modal.actionConduct();
                                closeModal();
                            }}
                        >
                            Thực hiện
                        </button>
                        <button
                            className={styles.btn}
                            onClick={() => {
                                if (modal.actionCancel) modal.actionCancel();
                                closeModal();
                            }}
                            ref={acceptBtn}
                        >
                            Hủy bỏ
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default forwardRef(ConfirmModal);

import _ from 'lodash';
import { expenseBillService, statisticService } from '@services';
import unitOfWork from '@controllers';

const sort = (arr, col) => {
    if (col.count === 'ASC') {
        return arr.sort((first, second) => first.count - second.count);
    } else if (col.count === 'DESC') {
        return arr.sort((first, second) => second.count - first.count);
    }

    if (col.totalPrice === 'ASC') {
        return arr.sort(
            (first, second) => first.totalPrice - second.totalPrice,
        );
    } else if (col.totalPrice === 'DESC') {
        return arr.sort(
            (first, second) => second.totalPrice - first.totalPrice,
        );
    }
    return arr;
};

const sortAvailable = (col) => col.totalPrice || col.count;

const statistic = (
    startDate,
    endDate,
    order,
    setProducts,
    setShipServices,
    setEcommerces,
    setRevenues,
    setEmployees,
    setExpenses,
    setEmployeeExpenses,
    setExpenseBills,
) => {
    const controller = new AbortController();

    unitOfWork.statistic.statisticByProducts(
        controller.signal,
        startDate,
        endDate,
        order.product,
        (result, _order) => setBillsValue(result, setProducts, _order),
    );

    unitOfWork.statistic.statisticByShipServices(
        controller.signal,
        startDate,
        endDate,
        order.shipService,
        (result, _order) => setBillsValue(result, setShipServices, _order),
    );

    unitOfWork.statistic.statisticByEcommerces(
        controller.signal,
        startDate,
        endDate,
        order.ecommerce,
        (result, _order) => setBillsValue(result, setEcommerces, _order),
    );

    unitOfWork.statistic.statisticByRevenues(
        controller.signal,
        startDate,
        endDate,
        setRevenues,
    );

    unitOfWork.statistic.statisticByEmployees(
        controller.signal,
        startDate,
        endDate,
        order.employee,
        (result, _order) => setBillsValue(result, setEmployees, _order),
    );

    unitOfWork.statistic.statisticByEmployeeExpenses(
        controller.signal,
        startDate,
        endDate,
        order.employeeExpense,
        (result, _order) =>
            setExpenseBillsValue(result, setEmployeeExpenses, _order),
    );

    unitOfWork.statistic.statisticByExpenses(
        controller.signal,
        startDate,
        endDate,
        order.expense,
        (result, _order) => setExpenseBillsValue(result, setExpenses, _order),
    );

    unitOfWork.statistic.statisticByExpenseBills(
        controller.signal,
        startDate,
        endDate,
        setExpenseBills,
    );

    return controller;
};

const setBillsValue = (data, setData, order) => {
    data = data.map((value) => {
        if (value.bills.length === 0)
            return { ...value, count: 0, totalPrice: 0 };
        return {
            ...value,
            count: parseInt(value.bills[0].count),
            totalPrice: parseInt(value.bills[0].totalPrice),
        };
    });
    setData(sort(data, order));
};

const setExpenseBillsValue = (data, setData, order) => {
    data = data.map((value) => {
        if (value.expenseBills.length === 0)
            return { ...value, count: 0, totalPrice: 0 };
        return {
            ...value,
            count: parseInt(value.expenseBills[0].count),
            totalPrice: parseInt(value.expenseBills[0].totalPrice),
        };
    });
    setData(sort(data, order));
};

const handleSortClick = (col, type, setOrder) => {
    setOrder((order) => {
        const types = ['ASC', 'DESC', ''];
        const index = _.indexOf(types, order[col][type]);
        const next = types[(index + 1) % types.length];

        return {
            ...order,
            [col]: {
                count: '',
                totalPrice: '',
                [type]: next,
            },
        };
    });
};

const sortAll = (
    order,
    setProducts,
    setShipServices,
    setEcommerces,
    setEmployees,
    setExpenses,
    setEmployeeExpenses,
) => {
    if (sortAvailable(order.product)) {
        setProducts((products) => sort([...products], order.product));
    }
    if (sortAvailable(order.shipService)) {
        setShipServices((shipServices) =>
            sort([...shipServices], order.shipService),
        );
    }
    if (sortAvailable(order.ecommerce)) {
        setEcommerces((ecommerces) => sort([...ecommerces], order.ecommerce));
    }
    if (sortAvailable(order.employee)) {
        setEmployees((employees) => sort([...employees], order.employee));
    }

    if (sortAvailable(order.expense)) {
        setExpenses((expenses) => sort([...expenses], order.expense));
    }

    if (sortAvailable(order.employeeExpense)) {
        setEmployeeExpenses((employeeExpenses) =>
            sort([...employeeExpenses], order.employeeExpense),
        );
    }
};

const handleExportExcel = (startDate, endDate) => {
    expenseBillService.exportExcel(startDate.getTime(), endDate.getTime());
};

export { statistic, handleSortClick, handleExportExcel, sortAll };

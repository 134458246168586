import { default as styles } from './PreviewBill.module.scss';
import clsx from 'clsx';
import '@assets/image/fontawesome-free-6.0.0-web/css/all.min.css';
import { shopConfig } from '@/config/shop-config';

function PreviewBill({ rotate = false, bill }) {
  return (
    <div
      className={clsx(styles.wrapper, {
        [styles.wrapperRotate]: rotate,
      })}
    >
      <div className={styles.previewHeaderWrapper}>
        <div className={styles.previewHeader}>
          <div className={styles.heading}>
            <img
              src={shopConfig.images.billHeader}
              alt="Khắc Dấu Quốc Tiến"
              className={styles.logo}
            />
          </div>

          <ul className={styles.contactList}>
            <li className={styles.contactItem}>
              <i className="fa-solid fa-globe"></i>
              <span>{shopConfig.website}</span>
            </li>
            {shopConfig.facebook && (
              <li className={styles.contactItem}>
                <i className="fa-brands fa-facebook"></i>
                <span>{shopConfig.facebook}</span>
              </li>
            )}
            <li className={styles.contactItem}>
              <i className="fa-solid fa-square-phone"></i>
              <span>{shopConfig.phone}</span>
            </li>
            <li className={styles.contactItem}>
              <i className="fa-solid fa-location-dot"></i>
              <span>{shopConfig.address}</span>
            </li>
          </ul>
        </div>
        <div className={styles.moreInfo}>
          <p className={styles.receiveNote}>Phiếu thu</p>
          <p>
            {bill.createdAt &&
              new Date(bill.createdAt).toLocaleDateString('vi-VN')}
          </p>
          <p>
            <span>Số:</span> {bill.id}
          </p>
        </div>
      </div>

      {/* Content: Start */}
      <div className={styles.receive}>
        <span className={styles.labelReceive}>Người nhận:</span>
        <div className={styles.price}>
          <span className={styles.labelPrice}>Số tiền cần thu:</span>
          <span className={styles.totalPrice}>
            {(bill.price - bill.paid).toLocaleString('vi-VN')}
          </span>
        </div>
      </div>

      <div className={styles.info}>
        <p>{bill.name}</p>
        <p>{bill.phones.join(' - ')}</p>
        <p>{bill.address}</p>
      </div>

      <div className={styles.contentFooter}>
        <div className={styles.product}>
          <span className={styles.productLabel}>Nội dung:</span>
          <span className={styles.productContent}>{bill.product.name}</span>
        </div>
      </div>
      {/* Content: End */}

      <div className={styles.previewFooter}>
        <div className={styles.previewFooterLeft}>
          <p className={styles.note}>
            <span>Ghi chú: </span>
            {bill.note}
          </p>

          <div className={styles.shipContainer}>
            {bill.shipService.name}
            {bill.shipServiceBillID && ` - ${bill.shipServiceBillID}`}
          </div>
        </div>

        <div className={styles.previewAndTest}>{shopConfig.preview}</div>
      </div>
    </div>
  );
}

export default PreviewBill;

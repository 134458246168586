const MONTH = 0;
const months = [
    'Tháng 1',
    'Tháng 2',
    'Tháng 3',
    'Tháng 4',
    'Tháng 5',
    'Tháng 6',
    'Tháng 7',
    'Tháng 8',
    'Tháng 9',
    'Tháng 10',
    'Tháng 11',
    'Tháng 12',
];

const YEAR = 1;
const years = ['Năm nay', 'Năm ngoái', '2 năm trước', 'Tùy chỉnh'];

export { MONTH, YEAR, months, years };

import { _p } from '@/domain/models/utils/model-proto';
import BaseValueObject from '.';

class QtPrice extends BaseValueObject {
  static properties = {
    value: null,
  };

  constructor(obj) {
    super(obj);
    if (this.value) this.value = parseInt(this.value);
  }

  /*
  value: number | null;
  */

  get vnd() {
    return `${
      this.value?.toLocaleString('vi-VN', {
        styles: 'currency',
      }) || 0
    }`;
  }

  setValue(val) {
    this.value = parseInt(val.replaceAll('.', ''));

    return this;
  }

  totalPrice(count) {
    return this.value ? new QtPrice({ value: this.value * count }) : null;
  }

  isDiff(qtPrice) {
    return qtPrice.value === this.value;
  }

  toString() {
    return this.vnd;
  }

  valueOf() {
    return this.value;
  }

  toJSON() {
    return this.value;
  }

  static create(val) {
    let value = parseInt(val.replaceAll ? val.replaceAll('.', '') : val);
    if (Number.isNaN(value)) value = 0;

    return new QtPrice({ value });
  }
}

export default QtPrice;

const _p = (type, defaultValue) => ({
  type,
  defaultValue,
});

const _pc = (type, defaultValue) => ({
  type,
  defaultValue,
  primitive: true,
});

export { _p, _pc };

import React from 'react';
import clsx from 'clsx';
import styles from './Input.module.scss';

function Input(
  {
    onChange,
    value,
    label,
    labelClassName = [],
    error,
    children,
    childAfterInput = false,
    className,
    customInputStyle,
    stopPropagation = false,
    ...props
  },
  ref,
) {
  return (
    <div
      className={clsx(styles.formContent, {
        [styles.error]: error,
        [className]: className,
      })}
      error={error}
      onClick={(e) => {
        stopPropagation && e.stopPropagation();
      }}
    >
      {label && (
        <label
          htmlFor={label.id}
          className={clsx(styles.formLabel, ...labelClassName)}
        >
          {label.name}
        </label>
      )}

      {!childAfterInput && children}

      <input
        id={label?.id}
        className={clsx(styles.formInput, customInputStyle)}
        value={value}
        onChange={onChange}
        type="text"
        ref={ref}
        {...props}
      />

      {childAfterInput && children}
    </div>
  );
}

export default React.forwardRef(Input);

import { endpoint } from '@/config/endpoint';
import Stock from '@/domain/models/entities/stock';
import facade from '@/domain/models/utils/facade';
import { request } from '@/utilities/request';

const getItems = async ({ user, stock, keyword, page, perPage }) => {
  const option = {
    endpoint: endpoint.items,
    query: { keyword, perPage, page },
    user,
  };
  const qtProducts = await request.get(option);

  return facade.upsert(Stock, { qtProducts }, stock);
};

const createItem = async ({ user, data }) => {
  const option = {
    endpoint: endpoint.item,
    data,
    user,
  };
  await request.post(option);
};

const updateItem = async ({ user, data }) => {
  const option = {
    endpoint: endpoint.item,
    data,
    user,
  };
  await request.put(option);
};

const fetchQtProducts = ({ user, ...query }) => {
  const options = {
    endpoint: endpoint.qtProducts,
    user,
    query,
  };

  return request.get(options);
};

const fetchQtProduct = async ({ user, stock, id }) => {
  const options = {
    endpoint: endpoint.qtProductsDetail.replace(/:id/, id),
    user,
  };
  const qtProduct = await request.get(options);

  return facade.update(stock, { qtProduct });
};

const createQtProduct = async ({ data, stock, user }) => {
  const options = {
    endpoint: endpoint.qtProducts,
    data,
    user,
  };

  return await request.post(options);
};

const updateQtProduct = ({ data, stock, user }) => {
  const options = {
    endpoint: endpoint.qtProducts,
    data,
    user,
  };

  return request.put(options);
};

const deleteQtProduct = async ({ user, id }) => {
  const options = {
    endpoint: endpoint.qtProductsDetail.replace(/:id/, id),
    data: { id },
    user,
  };
  await request.del(options);
};

const fetchSuppliers = ({ user, page, name, limit = 12 }) => {
  const options = {
    endpoint: endpoint.suppliers,
    user,
    query: {
      limit,
      page,
      name,
    },
  };
  return request.get(options);
};

const fetchSupplier = async ({ user, stock, id }) => {
  const options = {
    endpoint: endpoint.supplierDetail.replace(/:id/, id),
    user,
  };
  const supplier = await request.get(options);

  return facade.update(stock, { supplier });
};

const createSupplier = ({ data, user }) => {
  const options = {
    endpoint: endpoint.suppliers,
    data,
    user,
  };
  return request.post(options);
};

const updateSupplier = ({ data, user }) => {
  const options = {
    endpoint: endpoint.suppliers,
    data,
    user,
  };
  return request.put(options);
};

const deleteSupplier = ({ user, id }) => {
  const options = {
    endpoint: endpoint.supplierDetail.replace(/:id/, id),
    data: { id },
    user,
  };
  return request.del(options);
};

const exportQtProducts = async ({ user, data }) => {
  const options = {
    endpoint: endpoint.exportQtProducts,
    user,
    data,
  };

  await request.post(options);
};

const importQtProducts = async ({ user, data }) => {
  const options = {
    endpoint: endpoint.importQtProducts,
    user,
    data,
  };

  await request.post(options);
};

const fetchExportOrders = async ({ user, stock, query }) => {
  const options = {
    endpoint: endpoint.exportQtProducts,
    user,
    query,
  };
  const exportOrders = await request.get(options);

  return facade.update(stock, {
    exportOrders,
  });
};

const fetchExportOrder = async ({ user, stock, id }) => {
  const options = {
    endpoint: endpoint.exportQtProductDetail.replace(/:id/, id),
    user,
  };
  const exportOrder = await request.get(options);

  return facade.update(stock, { exportOrder });
};

const changeExportOrderStatus = async ({ user, stock, id, status }) => {
  const options = {
    endpoint: endpoint.exportQtProductDetail.replace(/:id/, id),
    user,
    data: {
      status,
    },
  };
  const exportOrder = await request.put(options);

  return facade.update(stock, { exportOrder });
};

const cancelExportOrder = async ({ user, stock, id }) => {
  const options = {
    endpoint: endpoint.cancelExportOrder,
    user,
    data: { id },
  };
  const exportOrder = await request.put(options);

  return facade.update(stock, { exportOrder });
};

const fetchImportOrders = ({ user, query }) => {
  const options = {
    endpoint: endpoint.importQtProducts,
    user,
    query,
  };
  return request.get(options);
};

const fetchImportOrder = async ({ user, id }) => {
  const options = {
    endpoint: endpoint.importQtProductDetail.replace(/:id/, id),
    user,
  };
  return request.get(options);
};

const cancelImportOrder = ({ user, id }) => {
  const options = {
    endpoint: endpoint.cancelImportOrder,
    user,
    data: { id },
  };
  return request.put(options);
};

const completeImportOrder = ({ user, id }) => {
  const options = {
    endpoint: endpoint.completeImportOrder,
    user,
    data: { id },
  };
  return request.put(options);
};

const fetchStockReport = ({ user, ...query }) => {
  const options = {
    endpoint: endpoint.reportQtProduct,
    user,
    query,
  };
  return request.get(options);
};

export default {
  getItems,
  createItem,
  updateItem,
  fetchQtProducts,
  fetchQtProduct,
  createQtProduct,
  updateQtProduct,
  deleteQtProduct,
  fetchSuppliers,
  fetchSupplier,
  createSupplier,
  updateSupplier,
  deleteSupplier,
  exportQtProducts,
  fetchExportOrders,
  fetchExportOrder,
  changeExportOrderStatus,
  cancelExportOrder,
  importQtProducts,
  fetchImportOrders,
  fetchImportOrder,
  cancelImportOrder,
  completeImportOrder,
  fetchStockReport,
};

import {
    ArcElement,
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    Title,
    Tooltip,
} from 'chart.js';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Link } from 'react-router-dom';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import { StatisticDatePicker, Navigation } from '@components';
import * as event from './event';
import styles from './StatisticRectangle.module.scss';
import * as constant from './constant';
import * as constantDate from '@components/StatisticDatePicker/constant';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels,
);

function StatisticRectangle() {
    const [statisticReference, setStatisticReference] = useState(
        constantDate.MONTH,
    );
    const [statisticTime, setStatisticTime] = useState(constantDate.months);
    const [selectedStatisticTime, setSelectedStatisticTime] = useState(1);

    const [selectedValue, setSelectedValue] = useState('count');

    const [data, setData] = useState({
        labels: [],
        datasets: [
            {
                label: 'Thu',
                data: [],
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
            },
            {
                label: 'Chi',
                data: [],
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
            },
        ],
    });

    useEffect(() => {
        if (statisticReference === constantDate.MONTH) {
            setStatisticTime(constantDate.months);
            setSelectedStatisticTime(new Date().getMonth() + 1);
        } else {
            setStatisticTime(constantDate.years);
            setSelectedStatisticTime(new Date().getFullYear());
        }
    }, [statisticReference]);

    useEffect(() => {
        const controller = event.handleStatistic(
            selectedValue,
            statisticReference,
            selectedStatisticTime,
            setData,
        );

        return () => controller.abort();
    }, [selectedValue, selectedStatisticTime]);

    return (
        <React.Fragment>
            <div className={styles.container}>
                <div className={styles.containerHeader}>
                    <div className={styles.datePickerWrapper}>
                        <label className={styles.datePickerLabel}>
                            Ngày thống kê
                        </label>

                        <StatisticDatePicker
                            statisticReference={statisticReference}
                            statisticTime={statisticTime}
                            setStatisticReference={setStatisticReference}
                            selectedStatisticTime={selectedStatisticTime}
                            setSelectedStatisticTime={setSelectedStatisticTime}
                        />
                    </div>

                    <Link
                        className={clsx(styles.btn, styles.link)}
                        to="/statistic"
                    >
                        Xem dạng danh sách
                    </Link>
                </div>

                <div className={styles.containerContent}>
                    <Link
                        className={clsx(styles.btn, styles.link)}
                        to="/statistic/circle"
                    >
                        Dạng tròn
                    </Link>

                    <div className={styles.barWrapper}>
                        <Bar options={constant.options} data={data} />
                    </div>

                    <select
                        className={clsx(styles.selectType)}
                        value={selectedValue}
                        onChange={(e) =>
                            event.handleSelectedValue(e, setSelectedValue)
                        }
                    >
                        <option value="count">Số đơn</option>
                        <option value="totalPrice">Tổng tiền</option>
                    </select>
                </div>
            </div>
        </React.Fragment>
    );
}

export default StatisticRectangle;

import { expenseBillService, expenseService } from '@services';
import * as constant from './constant';
import * as constantNotify from '@components/NotifyModal/constant';
import * as validate from './validate';
import _, { get } from 'lodash';

const removeError = (type, setError) => {
    setError((error) => ({
        ...error,
        [type]: '',
    }));
};

const clearForm = (setPrice, setNote) => {
    setPrice(0);
    setNote('');
};

const getExpenses = (setExpenses) => {
    expenseService
        .getAll()
        .then((res) => res.json())
        .then((data) => {
            if (!data.error) setExpenses(data);
        })
        .catch((error) => console.log(error));
};

const getExpenseBills = (
    isAdmin,
    page,
    startDate,
    endDate,
    order,
    selectedExpense,
    setExpenseBills,
    setNoExpense,
    setTotalExpense,
    setStopScrollEvent,
    setNewData,
    setFilterExpenseIDs,
) => {
    const func = isAdmin
        ? expenseBillService.filter(
              page,
              startDate.getTime(),
              endDate.getTime(),
              order,
              selectedExpense,
          )
        : expenseBillService.getAll(page, order);
    func.then((res) => res.json())
        .then((data) => {
            if (!data.error) {
                if (page > 1) {
                    setExpenseBills((expenseBills) => {
                        const newExpenseBills = _.uniqBy(
                            [...expenseBills, ...data.rows],
                            'id',
                        );
                        return newExpenseBills;
                    });
                } else {
                    setExpenseBills(data.rows);
                    setTotalExpense(data.sum || 0);
                    setFilterExpenseIDs(data.expense || []);
                }

                setNoExpense((old) => {
                    if (old !== 0 && old !== data.count && page > 1) {
                        setNewData('Có dữ liệu mới!');
                    }
                    return data.count;
                });
                if (data.rows.length > 0) {
                    setStopScrollEvent(false);
                }
            }
        })
        .catch((error) => console.log(error));
};

const handleExpenseChange = (e, setExpense, setError) => {
    removeError('expense', setError);
    setExpense(e.target.value);
};

const handleExpenseBlur = (expense, setError) => {
    removeError('expense', setError);
    validate.validateExpense(expense, setError);
};

const handlePriceChange = (e, setPrice, setError) => {
    removeError('price', setError);

    const checkedValue = e.target.value
        ? e.target.value.replace(/\./g, '')
        : '0';

    if (validate.validatePrice(checkedValue, setError)) {
        setPrice(parseInt(checkedValue));
    }
};

const handleRefreshClick = (setNewData, setPage) => {
    setNewData('');
    setPage(1);
};

const handleSubmitForm = (
    e,
    expense,
    price,
    note,
    setPrice,
    setNote,
    setExpenseBills,
    setNoExpense,
    setTotalExpense,
    setNotifyModalShow,
    setNotifyModal,
    setError,
) => {
    e.preventDefault();
    if (validate.validateForm(expense, price, setError)) {
        expenseBillService
            .create({
                expense: expense,
                price: price,
                note: note,
            })
            .then((res) => res.json())
            .then((data) => {
                if (data.error) {
                    setNotifyModal({
                        type: constantNotify.ERROR,
                        title: 'Thêm',
                        content: data.error,
                    });
                    setNotifyModalShow(true);
                } else {
                    clearForm(setPrice, setNote);
                    setExpenseBills((expenseBills) => [data, ...expenseBills]);
                    setNoExpense((no) => no + 1);
                    setTotalExpense((total) => total + data.price);
                }
            });
    }
};

const showConfirmModal = (modal, setConfirmModal, setConfirmModalShow) => {
    setConfirmModal(modal);
    setConfirmModalShow(true);
};

const showNotifyModal = (modal, setNotifyModal, setNotifyModalShow) => {
    setNotifyModal(modal);
    setNotifyModalShow(true);
};

const handleUpdateDateChange = (
    date,
    setUpdateDate,
    setNotifyModal,
    setNotifyModalShow,
) => {
    if (date <= new Date()) {
        setUpdateDate(date);
    } else {
        showNotifyModal(
            {
                type: constantNotify.ERROR,
                title: 'Chọn ngày',
                content: 'Chọn ngày nhỏ hơn ngày hiện tại',
            },
            setNotifyModal,
            setNotifyModalShow,
        );
    }
};

const handleUpdateSubmit = (
    e,
    selectedExpenseBillID,
    updatedDate,
    setSelectedExpenseBillID,
    setExpenseBills,
    setNotifyModal,
    setNotifyModalShow,
) => {
    e.preventDefault();
    expenseBillService
        .update(selectedExpenseBillID, updatedDate)
        .then((res) => res.json())
        .then((data) => {
            if (data.error) {
                showNotifyModal(
                    {
                        type: constantNotify.ERROR,
                        title: 'Cập nhật',
                        content: data.error,
                    },
                    setNotifyModal,
                    setNotifyModalShow,
                );
            } else {
                setExpenseBills((expenseBills) => {
                    _.find(
                        expenseBills,
                        (expenseBill) =>
                            expenseBill.id === selectedExpenseBillID,
                    ).createdAt = updatedDate;
                    return expenseBills;
                });
                setSelectedExpenseBillID(0);
            }
        });
};

const handleSelectedExpenseChange = (e, setSelectedExpense) => {
    setSelectedExpense(e.target.value);
};

export {
    handlePriceChange,
    handleSelectedExpenseChange,
    handleExpenseChange,
    handleExpenseBlur,
    handleRefreshClick,
    handleSubmitForm,
    getExpenses,
    getExpenseBills,
    showConfirmModal,
    showNotifyModal,
    handleUpdateDateChange,
    handleUpdateSubmit,
};

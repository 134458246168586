import { endpoint } from '@/config/endpoint';
import facade from '@/domain/models/utils/facade';
import { request } from '@/utilities/request';

const getNoteRules = async (user, master, query = {}) => {
  const option = {
    endpoint: endpoint.noteRule,
    query,
    user,
  };
  const noteGroupRules = await request.get(option);

  return { noteGroupRules };
};

const createNoteRule = async (data, user) => {
  const option = {
    endpoint: endpoint.noteRule,
    data,
    user,
  };
  await request.post(option);
};

const updateNoteRule = async (data, user) => {
  const option = {
    endpoint: endpoint.noteRule,
    data,
    user,
  };
  await request.put(option);
};

const deleteNoteRule = async (data, user) => {
  const option = {
    endpoint: endpoint.noteRule,
    data,
    user,
  };
  await request.delete(option);
};

export default {
  getNoteRules,
  createNoteRule,
  updateNoteRule,
  deleteNoteRule,
};

import React from 'react';
import ReactDOM from 'react-dom';

import './assets/css/index.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css'; //theme
import 'primereact/resources/primereact.min.css'; //core css
import 'primeicons/primeicons.css'; //icons
import { GlobalStyle } from './components';
import './index.scss';
import store from '@/application/store';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

ReactDOM.render(
  <BrowserRouter>
    <GlobalStyle>
      <Provider store={store}>
        <App />
      </Provider>
    </GlobalStyle>
  </BrowserRouter>,

  document.getElementById('root'),
);

import React, { useState } from 'react';
import clsx from 'clsx';

import styles from './AccountForm.module.scss';

import * as event from './event';

function AccountForm({ submitForm, user, setUser }) {
    const [error, setError] = useState({
        username: '',
        password: '',
        newPassword: '',
        rePassword: '',
        name: '',
    });

    return (
        <form
            className={styles.form}
            onSubmit={(e) =>
                event.handleFormSubmit(e, user, setError, submitForm)
            }
        >
            {/* Username: Start */}
            <div
                className={clsx(styles.formInputWrapper, {
                    [styles.error]: error.username,
                })}
                error={error.username}
            >
                <label htmlFor="username" className={styles.formLabel}>
                    Tên đăng nhập
                </label>
                <input
                    type="text"
                    className={clsx(styles.formInput, styles.disabled)}
                    id="username"
                    value={user.username}
                    onChange={(e) =>
                        event.handleUsernameChange(e, setUser, setError)
                    }
                    onBlur={(e) =>
                        event.handleUsernameBlur(e, setUser, setError)
                    }
                    disabled
                />
            </div>
            {/* Username: End */}

            {/* Password: Start */}
            <div
                className={clsx(styles.formInputWrapper, {
                    [styles.error]: error.password,
                })}
                error={error.password}
            >
                <label htmlFor="password" className={styles.formLabel}>
                    Mật khẩu cũ
                </label>
                <input
                    type="password"
                    className={styles.formInput}
                    id="password"
                    value={user.password}
                    onChange={(e) =>
                        event.handlePasswordChange(e, setUser, setError)
                    }
                    onBlur={(e) => event.handlePasswordBlur(user, setError)}
                />
            </div>
            {/* Password: End */}

            {/* New Password: Start */}
            <div
                className={clsx(styles.formInputWrapper, {
                    [styles.error]: error.newPassword,
                })}
                error={error.newPassword}
            >
                <label htmlFor="newPassword" className={styles.formLabel}>
                    Mật khẩu mới
                </label>
                <input
                    type="password"
                    className={styles.formInput}
                    id="newPassword"
                    value={user.newPassword}
                    onChange={(e) =>
                        event.handleNewPasswordChange(e, setUser, setError)
                    }
                    onBlur={() => event.handleNewPasswordBlur(user, setError)}
                />
            </div>
            {/* New Password: End */}

            {/* Re-Password: Start */}
            <div
                className={clsx(styles.formInputWrapper, {
                    [styles.error]: error.rePassword,
                })}
                error={error.rePassword}
            >
                <label htmlFor="rePassword" className={styles.formLabel}>
                    Nhập lại mật khẩu
                </label>
                <input
                    type="password"
                    className={styles.formInput}
                    id="rePassword"
                    value={user.rePassword}
                    onChange={(e) =>
                        event.handleRepasswordChange(e, setUser, setError)
                    }
                    onBlur={() => event.handleRepasswordBlur(user, setError)}
                />
            </div>
            {/* Re-Password: End */}

            {/* Display Name: Start */}
            <div
                className={clsx(styles.formInputWrapper, {
                    [styles.error]: error.name,
                })}
                error={error.name}
            >
                <label htmlFor="displayName" className={styles.formLabel}>
                    Tên hiển thị
                </label>
                <input
                    type="text"
                    className={styles.formInput}
                    id="displayName"
                    value={user.name}
                    onChange={(e) =>
                        event.handleNameChange(e, setUser, setError)
                    }
                    onBlur={(e) => event.handleNameBlur(user, setError)}
                />
            </div>
            {/* Display Name: End */}

            {/* Email: Start */}
            <div className={styles.formInputWrapper}>
                <label htmlFor="email" className={styles.formLabel}>
                    Email
                </label>
                <input
                    type="email"
                    className={styles.formInput}
                    id="email"
                    value={user.email}
                    onChange={(e) => event.handleEmailChange(e, setUser)}
                />
            </div>
            {/* Email: End */}

            {/* Submit Button */}
            <div className={styles.formInputWrapper}>
                <input
                    className={styles.formSubmitBtn}
                    type="submit"
                    value="Cập nhật"
                />
            </div>
        </form>
    );
}

export default AccountForm;

import authService from '@/domain/services/auth';
import noteService from '@/domain/services/note';

export const login = {
  onDispatch: async ({ username, password, onSuccess, onFailure }) => {
    try {
      const user = await authService.login({ username, password });
      onSuccess(user);

      return user;
    } catch (e) {
      onFailure();
    }
  },
  fulfilled: (state, { payload }) => {
    if (payload) {
      state.user = payload;
    }
  },
};

export const initializeFromToken = {
  onDispatch: async () => {
    const token = localStorage.getItem('token');
    const user = await authService.initializeFromToken(token);

    return user;
  },
  pending: (params) => {
    params.ui.loading = {
      isOpen: true,
      color: '#000',
    };
  },
  fulfilled: (state, { payload }) => {
    state.user = payload;
    state.ui.isInitial = true;
    state.ui.loading.isOpen = true;
  },
};

export const getNoteGroups = {
  onDispatch: async (_, options) => {
    const { getState } = options;
    const {
      state: { user: userState },
    } = getState();
    const user = await noteService.getNoteGroups(userState);

    return user;
  },
  fulfilled: (state, { payload }) => {
    state.user = payload;
  },
};

export const createNoteGroup = {
  onDispatch: async (data, options) => {
    const { getState } = options;
    const {
      state: { user: userState },
    } = getState();

    await noteService.createNoteGroup(data, userState);
    const user = await noteService.getNoteGroups(userState);

    return user;
  },
  fulfilled: (state, { payload }) => {
    state.user = payload;
  },
};

export const updateNoteGroup = {
  onDispatch: async (payload, options) => {
    const { getState } = options;
    const {
      state: { user: userState },
    } = getState();
    const { callback, ...data } = payload;

    await noteService.updateNoteGroup(data, userState);
    const user = await noteService.getNoteGroups(userState);
    callback?.();

    return user;
  },
  fulfilled: (state, { payload }) => {
    state.user = payload;
  },
};

export const deleteNoteGroup = {
  onDispatch: async (payload, options) => {
    const { getState } = options;
    const {
      state: { user: userState },
    } = getState();

    await noteService.deleteNoteGroup(payload, userState);
    const user = await noteService.getNoteGroups(userState);

    return user;
  },
  fulfilled: (state, { payload }) => {
    state.user = payload;
  },
};

export const createNote = {
  onDispatch: async (payload, options) => {
    const { getState } = options;
    const {
      state: { user: userState },
    } = getState();
    const { callback, ...data } = payload;

    await noteService.createNote(data, userState);
    const user = await noteService.getNoteGroups(userState);
    callback?.();

    return user;
  },
  fulfilled: (state, { payload }) => {
    state.user = payload;
  },
};

export const updateNote = {
  onDispatch: async (payload, options) => {
    const { getState } = options;
    const {
      state: { user: userState },
    } = getState();
    const { callback, ...data } = payload;

    await noteService.updateNote(data, userState);
    const user = await noteService.getNoteGroups(userState);
    callback?.();

    return user;
  },
  fulfilled: (state, { payload }) => {
    state.user = payload;
  },
};

export const deleteNote = {
  onDispatch: async (payload, options) => {
    const { getState } = options;
    const {
      state: { user: userState },
    } = getState();

    await noteService.deleteNote(payload, userState);
    const user = await noteService.getNoteGroups(userState);

    return user;
  },
  fulfilled: (state, { payload }) => {
    state.user = payload;
  },
};

import { fetcher } from '@utilities';

const urlOrigin = '/api/product';

const getAll = async (cancelSignal) => {
    const result = await fetcher(`${urlOrigin}/list`, {
        signal: cancelSignal,
    });

    return await result.json();
};

const create = async (name) => {
    const result = await fetcher(`${urlOrigin}/create`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
        },
        body: JSON.stringify({ name }),
    });
    const response = await result.json();

    return response;
};

const update = async (ecommerce) => {
    const result = await fetcher(`${urlOrigin}/update`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
        },
        body: JSON.stringify({
            id: ecommerce.id,
            name: ecommerce.name,
        }),
    });
    const response = await result.json();

    return response;
};

export { getAll, create, update };

import { combineReducers, configureStore } from '@reduxjs/toolkit';
import markettingReducer from '@/features/marketting/slice';
import { reducer as state } from './slice';

const middleware = [];

if (process.env.NODE_ENV === `development`) {
  const { logger } = require(`redux-logger`);

  middleware.push(logger);
}

export default configureStore({
  reducer: combineReducers({
    marketting: markettingReducer,
    state,
  }),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(middleware),
});

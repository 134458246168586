import { _p } from '@/domain/models/utils/model-proto';
import BaseModel from '..';
import Order from './order';

class Domain extends BaseModel {
  static properties = {
    order: _p(Order, new Order()),
  };

  constructor(obj) {
    super(obj);
  }

  /*
  qtProducts: QtProducts;
  exportOrders: ExportOrders;
  filter: StockFilter;
  */
}

export default Domain;

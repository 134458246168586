import { memo } from 'react';
import { connect } from 'react-redux';

import { UI } from '@config/constants';
import actions from '@/application/actions';
import { useDateChange } from '@/hooks';
import { BaseLayout, TwoColumns } from '@/components/templates';
import { QtDatePickerField, Table } from '@/components/organisms';
import styles from './style.module.scss';
import { useEffect } from 'react';
import { Column } from './Column';
import { useState } from 'react';

const {
  SORT_ORDER: { ASC, DESC, NO_SORT },
} = UI;

const mapStateToProps = ({ state }) => ({ state });

export const StockStatistic = connect(
  mapStateToProps,
  actions,
)((props) => {
  const {
    state: {
      stock: {
        filter: { statistic: statisticFilter },
        report,
      },
    },
    actions: { updateStatisticFilter, fetchStockReport },
  } = props;

  const [orders, setOrders] = useState({
    exportOrders: {
      categories: {
        totalPrice: NO_SORT,
        count: NO_SORT,
      },
    },
    importOrders: {
      categories: {
        totalPrice: NO_SORT,
        count: NO_SORT,
      },
    },
  });

  const onOrderChange = (key, field) => (columnKey) => () => {};

  const { handleDateChange, handleBothDateChange } = useDateChange(
    updateStatisticFilter,
  );

  useEffect(() => {
    fetchStockReport({ currentFilter: statisticFilter.value });
  }, [statisticFilter]);

  return (
    <BaseLayout className={styles.container}>
      <div className={styles.filterContainer}>
        <QtDatePickerField
          label="NGÀY THỐNG KÊ"
          startDate={statisticFilter.from.value}
          endDate={statisticFilter.to.value}
          setStartDate={handleDateChange('from')}
          setEndDate={handleDateChange('to')}
          setBothDate={handleBothDateChange}
        />
      </div>

      <TwoColumns className={styles.contentContainer}>
        <Column
          report={report}
          columnKey="exportOrders"
          onOrderChange={onOrderChange}
          orders={orders}
        />

        <Column
          report={report}
          columnKey="importOrders"
          onOrderChange={onOrderChange}
          orders={orders}
        />
      </TwoColumns>
    </BaseLayout>
  );
});

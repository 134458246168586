import React from 'react';
import clsx from 'clsx';

import styles from './EditProduct.module.scss';
import { ToastMessage } from '@components';
import toastMessageType from '@constant/ToastMessage';

import event from './event';

function EditOnRight({ selectedProduct, closeEditButton, setData }) {
    const [product, setProduct] = React.useState({
        name: '',
    });
    const [toastMessage, setToastMessage] = React.useState({
        isVisible: false,
        type: toastMessageType.SUCCESS,
        msg: {
            title: 'Lỗi',
            duration: 3000,
            desc: '',
        },
    });
    const handleInputChange = React.useCallback((e) =>
        setProduct({
            ...product,
            name: e.target.value,
        }),
    );
    const saveNewData = React.useCallback(
        (e) => {
            e.preventDefault();

            if (!product.name) {
                setToastMessage({
                    isVisible: true,
                    type: toastMessageType.ERROR,
                    msg: {
                        title: 'Lỗi',
                        duration: 3000,
                        desc: 'Tên không được để trống',
                    },
                });
            } else {
                event.submitForm(
                    product,
                    setToastMessage,
                    setData,
                    closeEditButton,
                );
            }
        },
        [product],
    );
    const canSubmit = React.useMemo(() => {
        const trimName = product.name.trim();

        return (
            !trimName || trimName === selectedProduct.name || trimName === ''
        );
    }, [product.name, selectedProduct.name]);

    React.useLayoutEffect(() => {
        setProduct(selectedProduct);
    }, [selectedProduct]);

    React.useEffect(() => {
        return () => {};
    }, []);

    return (
        <form
            className={clsx(styles.form)}
            onSubmit={saveNewData}
            aftercontent={product.id ? 'Chỉnh sửa' : 'Tạo mới'}
        >
            <input
                className={clsx(styles.formInput)}
                value={product.name}
                onChange={handleInputChange}
                placeholder="Nhập tên sản phẩm..."
            />

            <div className={clsx(styles.formAction)}>
                <button
                    type="button"
                    className={clsx(
                        styles.formActionItem,
                        styles.formActionItem_LowPriority,
                    )}
                    onClick={closeEditButton}
                >
                    Hủy bỏ
                </button>

                <input
                    className={clsx(styles.formActionItem, {
                        [styles.disabled]: canSubmit,
                    })}
                    type="submit"
                    value="Lưu"
                    disabled={canSubmit}
                />
            </div>

            <ToastMessage open={toastMessage} setOpen={setToastMessage} />
        </form>
    );
}

export default EditOnRight;

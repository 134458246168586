import { useEffect, useLayoutEffect, useState } from 'react';
import useQuery from '@hooks/useQuery';
import styles from './PrintBill.module.scss';
import { billService } from '@services';

function PrintBill({ Sample }) {
  const query = useQuery();
  const [bills, setBills] = useState([]);

  useEffect(() => {
    const ids = query.get('id');
    billService
      .getBills(ids)
      .then((res) => res.json())
      .then((data) => setBills(data))
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    if (bills.length > 0) {
      setTimeout(window.print, 1000);
    }
  }, [bills]);

  return (
    <div className={styles.wrapper}>
      {bills.map((bill) => (
        <Sample
          key={bill.id}
          bill={{
            ...bill,
            phones: bill.phones.map((phone) => phone.phone),
          }}
        />
      ))}
    </div>
  );
}

export default PrintBill;

import { useCallback } from 'react';

export const useCalculatePrice = () =>
  useCallback(
    (details) =>
      details.map(({ product, displayPrice, quantity }) => ({
        product,
        displayPrice,
        quantity,
        totalPrice: displayPrice.totalPrice(quantity),
      })),
    [],
  );

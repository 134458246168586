import React from 'react';
import { connect } from 'react-redux';
import { Outlet } from 'react-router-dom';
import {
  AdminNavigation,
  ManagerNavigation,
  MarketterNavigation,
  StaffNavigation,
} from '.';

function Navigation({ user }) {
  const [NavigationComponent, setNavigationComponent] = React.useState(null);

  React.useEffect(() => {
    if (user && user.role?.name) {
      const roleName = user.role.name.toLowerCase();

      setNavigationComponent(roleName);
    } else {
      setNavigationComponent('unauthenticated');
    }
  }, [user]);

  return (
    <React.Fragment>
      {NavigationComponent === 'quản lý' ? (
        <ManagerNavigation user={user} />
      ) : NavigationComponent === 'admin' ? (
        <AdminNavigation user={user} />
      ) : NavigationComponent === 'marketter' ? (
        <MarketterNavigation user={user} />
      ) : NavigationComponent === 'nhân viên' ? (
        <StaffNavigation user={user} />
      ) : (
        <React.Fragment />
      )}
      <Outlet />
    </React.Fragment>
  );
}

export default connect(({ state }) => ({
  user: state.user,
}))(Navigation);

import { exportBillService } from '@services';
import toastMessageType from '@constant/ToastMessage';

class ExportBillController {
  create = async (
    priceTypeID,
    statusID,
    details,
    setToastMessage,
    setStoreProducts,
  ) => {
    try {
      if (priceTypeID === 0) {
        setToastMessage({
          isVisible: true,
          type: toastMessageType.ERROR,
          msg: {
            title: 'Lỗi',
            duration: 3000,
            desc: 'Vui lòng chọn bảng giá',
          },
        });
        return;
      }
      const exportedBill = {
        priceTypeID,
        statusID,
        details,
      };
      const result = await exportBillService.create(exportedBill);

      if (result.success) {
        const quantity = details.reduce((prev, cur) => {
          prev[cur.storeProductID] = cur.quantity;

          return prev;
        }, {});
        setToastMessage({
          isVisible: true,
          type: toastMessageType.SUCCESS,
          msg: {
            title: 'Thành công',
            duration: 3000,
            desc: 'Tạo đơn thành công',
          },
        });
        setStoreProducts((storeProducts) =>
          storeProducts.map((storeProduct) => {
            if (quantity[storeProduct.id]) {
              storeProduct.stock -= quantity[storeProduct.id];
            }

            return storeProduct;
          }),
        );
      } else {
        throw new Error();
      }
    } catch (err) {
      console.log(err);
      setToastMessage({
        isVisible: true,
        type: toastMessageType.ERROR,
        msg: {
          title: 'Thất bại',
          duration: 3000,
          desc: 'Tạo đơn thất bại',
        },
      });
    }
  };

  getByPage = async (
    cancelSignal,
    from,
    to,
    page,
    status,
    perPage,
    setExportBills,
  ) => {
    try {
      const result = await exportBillService.getByPage(
        cancelSignal,
        from.getTime(),
        to.getTime(),
        page,
        status,
        perPage,
      );

      if (result.success) {
        const data = result.data.map((exportBill) => {
          const total = exportBill.exportBillDetails.reduce(
            (prev, cur) => ({
              totalPrice: prev.totalPrice + cur.unitPrice * cur.quantity,
              totalQuantity: prev.totalQuantity + cur.quantity,
            }),
            { totalPrice: 0, totalQuantity: 0 },
          );

          return { ...exportBill, ...total };
        });

        setExportBills(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  delete = async (id, setToastMessage, fetchExportBillsPage) => {
    try {
      const result = await exportBillService.deleteBill(id);

      if (result.success) {
        fetchExportBillsPage();

        setToastMessage({
          isVisible: true,
          type: toastMessageType.SUCCESS,
          msg: {
            title: 'Thành công',
            duration: 0,
            desc: 'Xóa đơn thành công',
          },
        });
      } else {
        throw new Error();
      }
    } catch (error) {
      setToastMessage({
        isVisible: true,
        type: toastMessageType.ERROR,
        msg: {
          title: 'Lỗi',
          duration: 3000,
          desc: 'Không thể xóa đơn',
        },
      });
    }
  };

  updateStatus = async (id, statusID, setToastMessage) => {
    try {
      const result = await exportBillService.updateStatus(id, statusID);

      if (result.success) {
        setToastMessage({
          isVisible: true,
          type: toastMessageType.SUCCESS,
          msg: {
            title: 'Thành công',
            duration: 3000,
            desc: 'Cập nhật trạng thái thành công',
          },
        });
      } else {
        throw new Error();
      }
    } catch (error) {
      setToastMessage({
        isVisible: true,
        type: toastMessageType.ERROR,
        msg: {
          title: 'Lỗi',
          duration: 3000,
          desc: 'Không thể cập nhật trạng thái',
        },
      });
    }
  };

  getBillByID = async (cancelSignal, id, setExportBill) => {
    try {
      const result = await exportBillService.getBillByID(cancelSignal, id);

      if (result.success) {
        const total = result.data.exportBillDetails.reduce(
          (prev, cur) => {
            return {
              totalPrice: prev.totalPrice + cur.unitPrice * cur.quantity,
              totalQuantity: prev.totalQuantity + cur.quantity,
            };
          },
          { totalPrice: 0, totalQuantity: 0 },
        );

        setExportBill({ ...result.data, ...total });
      }
    } catch (error) {}
  };
}

export default ExportBillController;

import { memo } from 'react';
import clsx from 'clsx';
import styles from './QtModal.module.scss';
import { useEffect } from 'react';

const QtModal = (props) => {
  const {
    isOpen,
    onClose,
    closeOnEscape = true,
    containerClassName,
    contentContainer,
    overlayClassName,
    children,
  } = props;
  const stopPropagation = (e) => e.stopPropagation();

  useEffect(() => {
    if (closeOnEscape) {
      const close = (e) => {
        if (e.key === 'Escape') {
          onClose();
        }
      };
      document.addEventListener('keyup', close);
      return () => document.removeEventListener('keyup', close);
    }
  }, []);

  return (
    <div
      className={clsx(styles.container, {
        [containerClassName]: containerClassName,
        [styles.isOpen]: isOpen,
      })}
    >
      <div
        className={clsx(styles.contentContainer, {
          [contentContainer]:contentContainer, 
          [styles.slideUp]: isOpen,
          [styles.slideDown]: !isOpen,
        })}
        onClick={stopPropagation}
      >
        {isOpen && children}
        <i
          className={clsx('pi pi-times', styles.closeIcon)}
          onClick={onClose}
        />
      </div>
      <div
        className={clsx(styles.overlay, overlayClassName)}
        onClick={onClose}
      ></div>
    </div>
  );
};

export default memo(QtModal);

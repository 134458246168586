import { fetcher } from '@utilities';

const urlOrigin = '/api/ecommerce';

const getAll = async (cancelSignal) => {
  const result = await fetcher(`${urlOrigin}/list`, {
    signal: cancelSignal,
  });

  return await result.json();
};

const create = async (name) => {
  const result = await fetcher(`${urlOrigin}/create`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify({ name }),
  });
  const response = await result.json();

  return response;
};

const update = async (ecommerce) => {
  const result = await fetcher(`${urlOrigin}/update`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify({
      id: ecommerce.id,
      name: ecommerce.name,
    }),
  });
  const response = await result.json();

  return response;
};

const getMarkettingPage = async (cancelSignal, markettingBy) => {
  let query = '';
  if (markettingBy) {
    query += `?markettingBy=${markettingBy}`;
  }

  const result = await fetcher(`${urlOrigin}/marketting${query}`, {
    signal: cancelSignal,
  });

  return await result.json();
};

const updateMarketting = async (markettingBy, ecommerces) => {
  const result = await fetcher(`${urlOrigin}/marketting`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify({
      markettingBy,
      ecommerces,
    }),
  });
  const data = await result.json();

  return data;
};

export { getAll, create, update, getMarkettingPage, updateMarketting };

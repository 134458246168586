import { siteService } from '@services';

class SiteController {
    get = async (cancelController, setHomeImg) => {
        try {
            const result = await siteService.get(cancelController);

            if (!result.error) {
                setHomeImg(result.link);
            }
        } catch (err) {
            if (err.name == 'AbortError') {
                console.log('Aborted!');
            }
        }
    };

    getAllHomeImages = async (cancelController, setHomeImgList) => {
        try {
            const result = await siteService.getAllHomeImages(cancelController);

            if (!result.error) {
                setHomeImgList(result);
            }
        } catch (err) {
            if (err.name == 'AbortError') {
                console.log('Aborted!');
            }
        }
    };
}

export default SiteController;

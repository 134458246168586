import { supplierService } from '@services';

class SupplierController {
  #onSuccess = (page, result, setSuppliers, setCount) => {
    if (result.success) {
      if (parseInt(page) === 1) {
        setSuppliers(result.data.rows);
        setCount(result.data.count);
      } else {
        setSuppliers(result.data);
      }
    }
  };

  getByPage = async (cancelSignal, page, perPage, setSuppliers, setCount) => {
    try {
      const result = await supplierService.getAll(cancelSignal, page, perPage);

      if (result.success) {
        if (parseInt(page) === 1) {
          setSuppliers(result.data.rows);
          setCount(result.data.count);
        } else {
          setSuppliers(result.data);
        }
      }
    } catch (error) {
      if (error.name === 'AbortError') {
        console.log('Cancelled request');
      }
    }
  };

  queryByPage = async (
    cancelSignal,
    search,
    page,
    perPage,
    setSuppliers,
    setCount,
  ) => {
    try {
      const result = await supplierService.getAll(
        cancelSignal,
        page,
        perPage,
        search,
      );

      this.#onSuccess(page, result, setSuppliers, setCount);
    } catch (error) {
      if (error.name === 'AbortError') {
        console.log('Cancelled request');
      }
    }
  };
}

export default SupplierController;

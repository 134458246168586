import * as constant from './constant';

const expense = (expense) =>
    expense && expense > 0 ? '' : constant.EMPTY_EXPENSE;

const price = (value) =>
    value.search(/[^0-9]/g) > -1
        ? constant.ONLY_NUMBER
        : value.length > 11
        ? constant.OUT_OF_RANGE
        : value === '0'
        ? constant.LARGER_THAN_0
        : '';

const validateExpense = (value, setError) => {
    const err = expense(value);
    if (err) {
        setError((error) => ({ ...error, expense: err }));
    }
};

const validatePrice = (value, setError) => {
    const err = price(value);
    if (err) {
        if (err === constant.LARGER_THAN_0) return true;
        setError((error) => ({ ...error, price: err }));
        return false;
    }
    return true;
};

const validateForm = (expenseValue, priceValue, setError) => {
    const errExpense = expense(`${expenseValue}`);
    const errPrice = price(`${priceValue}`);
    if (errExpense || errPrice) {
        setError({ expense: errExpense, price: errPrice });
        return false;
    }
    return true;
};

export { validateExpense, validatePrice, validateForm };

export default class BaseModel {
  constructor(obj) {
    const assignee = obj || this.constructor.properties;
    Object.assign(this, assignee);
  }

  diffKeys(comparedItem, ignoreKeys = []) {
    return Object.keys(comparedItem).filter((key) => {
      if (ignoreKeys.some((ignoreKey) => ignoreKey === key)) return false;

      return comparedItem[key]?.value
        ? comparedItem[key].value !== this[key].value
        : comparedItem[key] !== this[key];
    });
  }
}

import React from 'react';
import clsx from 'clsx';

import { PaginationBar, ToastMessage } from '@components';
import { UserContext } from '@contexts/App';
import { useThrottle, useCancellablePromise } from '@hooks';
import styles from './ImportStoreProduct.module.scss';
import toastMessageType from '@constant/ToastMessage';

import unitOfWork from '@controllers';
import _ from 'lodash';

function ImportStoreProduct() {
  const [toastMessage, setToastMessage] = React.useState({
    isVisible: false,
    type: toastMessageType.ERROR,
    msg: {
      title: '',
      duration: 3000,
      desc: '',
    },
  });

  const user = React.useContext(UserContext);

  const [searchProduct, setSearchProduct] = React.useState('');
  const [storeProducts, setStoreProducts] = React.useState([
    {
      id: 1,
      name: 'Con dấu T3 đỏ',
      importPrice: '100000',
      stock: 20,
    },
  ]);
  const [productPage, setProductPage] = React.useState(1);
  const [countProduct, setCountProduct] = React.useState(0);
  const perProductPage = 8;
  const totalProductPage = React.useMemo(
    () => Math.floor((countProduct + perProductPage) / perProductPage),
    [countProduct],
  );

  const [invoiceDetails, setInvoiceDetails] = React.useState([]);

  const [statuses, setStatuses] = React.useState([]);
  const [selectedStatus, setSelectedStatus] = React.useState(2);

  const [searchSupplier, setSearchSupplier] = React.useState('');
  const [suppliers, setSuppliers] = React.useState([]);
  const [selectedSupplier, setSelectedSupplier] = React.useState({
    id: 0,
    name: 'Chọn nhà cung cấp...',
  });
  const [supplierSearchVisible, setSupplierSearchVisible] =
    React.useState(false);
  const [supplierPage, setSupplierPage] = React.useState(1);
  const [countSupplier, setCountSupplier] = React.useState(0);
  const perSupplierPage = 5;
  const totalSupplierPage = React.useMemo(
    () => Math.floor((countSupplier + perSupplierPage) / perSupplierPage),
    [countSupplier],
  );

  const totalPrice = React.useMemo(() => {
    let tmpTotalPrice = 0;

    invoiceDetails.forEach((detail) => (tmpTotalPrice += detail.totalPrice));

    return tmpTotalPrice;
  }, [invoiceDetails]);

  const updateProductPage = useThrottle(
    (newProductPage) => setProductPage(newProductPage),
    500,
  );

  const updateSupplierPage = useThrottle(
    (newSupplierPage) => setSupplierPage(newSupplierPage),
    500,
  );

  const stopPropagation = React.useCallback((e) => {
    e.stopPropagation();
  });
  const toggleSupplierSearchVisible = React.useCallback((e) => {
    setSupplierSearchVisible((supplierSearchVisible) => !supplierSearchVisible);
  }, []);

  const handleStatusChange = React.useCallback((e) => {
    setSelectedStatus(e.target.value);
  }, []);

  const handleSupplierChange = React.useCallback((supplier) => () => {
    setSelectedSupplier(supplier);
  });

  const handleInsertProduct = React.useCallback(
    (storeProduct) => () => {
      setInvoiceDetails((invoiceDetails) => {
        let newDetails = [...invoiceDetails];
        const index = newDetails.findIndex(
          (detail) => detail.product.id === storeProduct.id,
        );

        if (index === -1) {
          newDetails.push({
            storeProductID: storeProduct.id,
            product: storeProduct,
            quantity: 1,
            totalPrice: storeProduct.importPrice,
          });
        } else {
          newDetails[index].quantity += 1;
          newDetails[index].totalPrice =
            newDetails[index].quantity * newDetails[index].product.importPrice;
        }

        return newDetails;
      });
    },
    [],
  );

  const handleRemoveProduct = React.useCallback((id) => () => {
    setInvoiceDetails((prevDetails) => {
      let newDetails = prevDetails.filter((detail) => detail.product.id !== id);

      return newDetails;
    });
  });

  const handleSearchProductChange = React.useCallback((e) => {
    setSearchProduct(e.target.value);
  }, []);

  const handleSearchSupplierChange = React.useCallback((e) => {
    setSearchSupplier(e.target.value);
  }, []);

  const handleQuantityChange = React.useCallback(
    (index) => (e) => {
      setInvoiceDetails((prevDetails) => {
        let newDetails = [...prevDetails];
        newDetails[index].quantity = e.target.value;
        newDetails[index].totalPrice =
          newDetails[index].quantity * newDetails[index].product.importPrice;

        return newDetails;
      });
    },
    [],
  );

  const handleSaveClick = React.useCallback(() => {
    unitOfWork.importBill.create(
      selectedSupplier.id,
      selectedStatus,
      invoiceDetails,
      setToastMessage,
    );
  }, [selectedSupplier, invoiceDetails, selectedStatus]);

  const fetchStoreProducts = useCancellablePromise(
    (signal) =>
      _.throttle(
        (searchProduct) =>
          unitOfWork.storeProduct.queryByPage(
            signal,
            searchProduct,
            productPage,
            perProductPage,
            setStoreProducts,
            setCountProduct,
          ),
        1000,
      ),
    [productPage, perProductPage],
  );

  const fetchSuppliers = useCancellablePromise(
    (signal) =>
      _.throttle(
        (searchSupplier) =>
          unitOfWork.supplier.queryByPage(
            signal,
            searchSupplier,
            supplierPage,
            perSupplierPage,
            setSuppliers,
            setCountSupplier,
          ),
        1000,
      ),
    [supplierPage, perSupplierPage],
  );

  const fetchStatuses = useCancellablePromise(
    (signal) => () => unitOfWork.importStatus.getAll(signal, setStatuses),
    [],
  );

  React.useEffect(() => {
    if (searchProduct === '' || searchProduct.length > 2) {
      fetchStoreProducts.invoke(searchProduct);
    }
  }, [productPage, searchProduct]);

  React.useEffect(() => {
    if (searchSupplier === '' || searchSupplier.length > 2) {
      fetchSuppliers.invoke(searchSupplier);
    }
  }, [supplierPage, searchSupplier]);

  React.useEffect(() => {
    fetchStatuses.invoke();

    const onClickDocument = () => {
      setSupplierSearchVisible(false);
    };
    document.addEventListener('click', onClickDocument);

    return () => {
      fetchStatuses.cancel();
      document.removeEventListener('click', onClickDocument);
    };
  }, []);

  return (
    <div className={styles.container}>
      <section className={styles.invoice}>
        <header className={styles.invoiceCreatedBy}>
          Người tạo: <span>{user?.name}</span>
        </header>

        <div className={styles.invoiceList}>
          <header className={styles.table}>
            <header className={clsx(styles.tableHeader, styles.row)}>
              <div className={styles.cell}></div>
              <div className={styles.cell}>Tên sản phẩm</div>
              <div className={styles.cell}>Số lượng</div>
              <div className={styles.cell}>Đơn giá</div>
              <div className={styles.cell}>Tổng</div>
            </header>

            <div className={clsx(styles.tableBody)}>
              {invoiceDetails.map((invoiceDetail, index) => (
                <div
                  className={clsx(styles.row)}
                  key={`invoice_${invoiceDetail.product.id}`}
                >
                  <div
                    className={styles.cell}
                    onClick={handleRemoveProduct(invoiceDetail.id)}
                  >
                    <i className={clsx(styles.icon, 'fas fa-trash')} />
                  </div>

                  <div className={styles.cell}>
                    {invoiceDetail.product.name}
                  </div>
                  <input
                    value={invoiceDetail.quantity}
                    onChange={handleQuantityChange(index)}
                    className={styles.cell}
                  ></input>
                  <div className={styles.cell}>
                    {invoiceDetail.product.importPrice &&
                      invoiceDetail.product.importPrice.toLocaleString(
                        'vi-VN',
                        { style: 'currency', currency: 'VND' },
                      )}
                  </div>
                  <div className={styles.cell}>
                    {invoiceDetail.totalPrice &&
                      invoiceDetail.totalPrice.toLocaleString('vi-VN', {
                        style: 'currency',
                        currency: 'VND',
                      })}
                  </div>
                </div>
              ))}
            </div>
          </header>

          <footer className={styles.invoiceInTotal}>
            <h3 className={styles.invoiceInTotalLabel}>Tổng tiền phải chi: </h3>

            <span className={styles.invoiceInTotalValue}>
              {totalPrice &&
                totalPrice.toLocaleString('vi-VN', {
                  style: 'currency',
                  currency: 'VND',
                })}
            </span>
          </footer>
        </div>
      </section>

      <section className={styles.require}>
        <div className={styles.requireSupplier}>
          <div
            className={styles.requireSupplierContainer}
            onClick={stopPropagation}
          >
            <div
              className={styles.requireSupplierSelected}
              onClick={toggleSupplierSearchVisible}
            >
              <span className={styles.requireSupplierSelectedText}>
                {selectedSupplier.name}
              </span>
              <i
                className={clsx(
                  styles.requireSupplierSelectedIcon,
                  'fas fa-angle-right',
                  {
                    [styles.rotate]: supplierSearchVisible,
                  },
                )}
              />
            </div>

            <div
              className={clsx(styles.requireSupplierSelection, {
                [styles.close]: !supplierSearchVisible,
              })}
            >
              <header className={styles.requireSupplierSearch}>
                <input
                  className={styles.requireSupplierSearchInput}
                  value={searchSupplier}
                  onChange={handleSearchSupplierChange}
                  placeholder="Tìm kiếm theo tên nhà cung cấp..."
                />
                <i
                  className={clsx(
                    styles.requireSupplierSearchIcon,
                    'fas fa-search',
                  )}
                />
              </header>

              <div className={styles.requireSupplierItems}>
                {suppliers.map((supplier) => (
                  <div
                    key={supplier.id}
                    className={styles.requireSupplierItem}
                    onClick={handleSupplierChange(supplier)}
                  >
                    <h3>{supplier.name}</h3>
                    <h4>{supplier.company}</h4>
                  </div>
                ))}
              </div>

              <PaginationBar
                totalPage={totalSupplierPage}
                noPages={5}
                columnWidth={40}
                currentPage={supplierPage}
                setCurrentPage={updateSupplierPage}
              />
            </div>
          </div>
        </div>

        <div className={styles.requireProduct}>
          <header className={clsx(styles.requireProductSearch)}>
            <input
              className={styles.requireProductSearchInput}
              placeholder="Tìm kiếm theo tên sản phẩm..."
              value={searchProduct}
              onChange={handleSearchProductChange}
            />
            <i
              className={clsx('fas fa-search', styles.requireProductSearchIcon)}
            />
          </header>

          <div className={clsx(styles.requireProductList)}>
            <div className={styles.requireProductItems}>
              {storeProducts.map((storeProduct) => (
                <div
                  className={styles.requireProductItem}
                  key={storeProduct.id}
                  onClick={handleInsertProduct(storeProduct)}
                >
                  <div className={styles.requireProductItemInfo}>
                    <h3>{storeProduct.name}</h3>
                    <h4>
                      {storeProduct.importPrice &&
                        storeProduct.importPrice.toLocaleString('vi-VN', {
                          style: 'currency',
                          currency: 'VND',
                        })}

                      <span>x</span>
                      {storeProduct.stock}
                    </h4>
                  </div>

                  <button className={styles.requireProductItemAdd}>
                    <i className="fas fa-plus" />
                  </button>
                </div>
              ))}
            </div>

            <PaginationBar
              totalPage={totalProductPage}
              noPages={5}
              columnWidth={40}
              currentPage={productPage}
              setCurrentPage={updateProductPage}
            />
          </div>
        </div>

        <div className={styles.requireStatus}>
          <select
            className={styles.requireStatusItems}
            value={selectedStatus}
            onChange={handleStatusChange}
          >
            {statuses.map((status) => (
              <option key={status.id} value={status.id}>
                {status.name}
              </option>
            ))}
          </select>
        </div>

        <div className={styles.btns}>
          <button className={styles.saveBtn} onClick={handleSaveClick}>
            Tạo đơn
          </button>
        </div>
      </section>

      <ToastMessage open={toastMessage} setOpen={setToastMessage} />
    </div>
  );
}

export default ImportStoreProduct;

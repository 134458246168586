import { Div } from '@/components/atoms';
import clsx from 'clsx';
import React, { memo, useEffect, useRef, useState } from 'react';
import NoteItem from '../NoteItem';
import styles from './style.module.scss';

function NoteFolder(props) {
  const {
    noteGroupInit,
    actions: { getNoteGroups, createNote, updateNoteGroup, deleteNoteGroup },
  } = props;
  const [newNoteBox, setNewNoteBox] = useState(false);
  const [newNote, setNewNote] = useState('');

  const [selectedItem, setSelectedItem] = useState(0);
  const [noteGroupHeading, setNoteGroupHeading] = useState(noteGroupInit);

  const [isNoteGroupChanged, setIsNoteGroupChanged] = useState(false);

  const inputNewNoteRef = useRef(null);
  const noteListRef = useRef(null);

  const saveNoteGroup = () => {
    const { name } = noteGroupHeading;
    if (!name) {
      getNoteGroups();
      setIsNoteGroupChanged(false);
      return;
    }
    isNoteGroupChanged &&
      updateNoteGroup({ name, callback: () => setIsNoteGroupChanged(false) });
  };

  const onCreate = () => {
    if (newNote) {
      createNote({
        note: newNote,
        noteGroupID: noteGroupHeading.id,
        callback: resetInput,
      });
    } else {
      resetInput();
    }
  };

  const resetInput = () => {
    setNewNote('');
    setNewNoteBox(false);
  };

  // Effect for closing the new title input
  useEffect(() => {
    const callback = onCreate;
    document.addEventListener('click', callback);

    return () => document.removeEventListener('click', callback);
  }, [resetInput]);

  // Focus input when appear
  const prevNoteLength = useRef({ length: 0 }).current;
  useEffect(() => {
    if (inputNewNoteRef.current) inputNewNoteRef.current.focus();

    if (prevNoteLength.length < noteGroupHeading.notes.length) {
      noteListRef.current.scrollTop = noteListRef.current.scrollHeight;
    }

    return () => {
      prevNoteLength.length = noteGroupHeading.notes.length;
    };
  }, [newNoteBox, noteGroupHeading.notes]);

  // Resize textarea when text changes
  useEffect(() => {
    if (inputNewNoteRef.current) {
      inputNewNoteRef.current.style.height = 0;
      inputNewNoteRef.current.style.height = `${inputNewNoteRef.current.scrollHeight}px`;
    }
  }, [newNote]);

  useEffect(() => {
    setNoteGroupHeading(noteGroupInit);
  }, [noteGroupInit]);

  return (
    <Div className={styles.noteFolder} stopPropagation>
      <header className={styles.noteHeading}>
        <input
          type="text"
          className={styles.inputHeading}
          value={noteGroupHeading.name}
          onChange={(e) => {
            setIsNoteGroupChanged(true);
            setNoteGroupHeading((noteGroupHeading) => ({
              ...noteGroupHeading,
              name: e.target.value,
            }));
          }}
          onBlur={() => {
            saveNoteGroup();
          }}
        />
        <i
          className="fa-solid fa-trash"
          onDoubleClick={() => deleteNoteGroup({ id: noteGroupHeading.id })}
        />
      </header>

      <div
        className={clsx(styles.notes, {
          [styles.inputting]: newNoteBox,
        })}
        ref={noteListRef}
      >
        {noteGroupHeading.notes.list.map((note) => (
          <NoteItem
            pNote={note}
            key={`note_${note.id}`}
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
            actions={props.actions}
          />
        ))}

        {newNoteBox && (
          <div className={styles.newNoteWrapper}>
            <textarea
              type="text"
              className={styles.inputNewNote}
              value={newNote}
              onChange={(e) => {
                setNewNote(e.target.value);
              }}
              placeholder="Nhập nội dung ghi chú..."
              onKeyDown={(e) => {
                if (e.key === 'Enter' && !e.ctrlKey && !e.shiftKey) {
                  e.preventDefault();
                  onCreate();
                }
              }}
              ref={inputNewNoteRef}
            />

            <div className={styles.btnBar}>
              <button className={styles.addBtn} onClick={onCreate}>
                Thêm danh sách
              </button>

              <i
                className={clsx('fas fa-xmark', styles.removeIcon)}
                onClick={resetInput}
              />
            </div>
          </div>
        )}
      </div>

      {!newNoteBox && (
        <div
          className={styles.newNoteTrigger}
          onClick={() => setNewNoteBox(true)}
        >
          <i className="fa-solid fa-add" />
          Thêm ghi chú mới
        </div>
      )}
    </Div>
  );
}

export default NoteFolder;

import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';

import {
  EXPORT_ORDER_STATUSES,
  EXPORT_ORDER_STATUS_NAMES,
} from '@config/constants';
import actions from '@/application/actions';
import { useThrottle } from '@hooks';
import { FromToDatePicker, PaginationBar } from '@components';
import { ExportOrderDetailModal } from '@components/Modal';
import styles from './ExportOrderList.module.scss';

function ExportOrderList(props) {
  const {
    actions: { fetchExportOrders, fetchExportOrder, updateExportOrderFilter },
    state: {
      stock: {
        exportOrders,
        filter: { exportOrders: filter },
      },
    },
  } = props;

  const updatePage = useThrottle(
    (page) => updateExportOrderFilter({ page }),
    500,
  );

  const openModal = (id) => () =>
    fetchExportOrder({
      id,
      isOpenModal: true,
    });
  const handleSelectedStatusChange = useCallback((e) => {
    updateExportOrderFilter({
      status: e.target.value,
    });
  }, []);

  const handleDateChange = useCallback(
    (type) => (date) => {
      updateExportOrderFilter({
        [type]: date,
        page: 1,
      });
    },
    [],
  );

  const handleBothDateChange = useCallback((from, to) => {
    updateExportOrderFilter({
      from,
      to,
      page: 1,
    });
  }, []);

  useEffect(() => {
    fetchExportOrders();
  }, [filter]);

  return (
    <>
      <h3 className={styles.heading}>Danh sách đơn xuất</h3>

      <div className={styles.filter}>
        <div className={styles.date}>
          <FromToDatePicker
            startDate={filter.from.value}
            endDate={filter.to.value}
            setStartDate={handleDateChange('from')}
            setEndDate={handleDateChange('to')}
            setBothDate={handleBothDateChange}
          />
        </div>

        <select
          className={styles.filterSelect}
          value={filter.status}
          onChange={handleSelectedStatusChange}
        >
          <option value={0}>Tình trạng đơn hàng</option>
          {Object.values(EXPORT_ORDER_STATUSES).map((statusId) => (
            <option value={statusId} key={statusId}>
              {EXPORT_ORDER_STATUS_NAMES[statusId]}
            </option>
          ))}
        </select>
      </div>

      <div className={styles.table}>
        <div className={clsx(styles.tableHeader, styles.row)}>
          <div className={styles.tableHeaderItem}>Mã đơn</div>
          <div className={clsx(styles.tableHeaderItem, 'isPC')}>Ngày tạo</div>
          <div className={styles.tableHeaderItem}>Tình trạng</div>
          <div className={styles.tableHeaderItem}>Tổng tiền</div>
          <div className={clsx(styles.tableHeaderItem, 'isPC')}>Loại giá bán</div>
          <div className={clsx(styles.tableHeaderItem, 'isPC')}>Người tạo</div>
          <div className={styles.tableHeaderItem}>Hành động</div>
        </div>

        <div className={styles.tableBody}>
          {exportOrders.list.map((item) => (
            <div
              key={item.id}
              className={clsx(
                styles.row,
                styles.tableBodyItem,
                styles[`status-${item.status}`],
              )}
            >
              <div className={styles.tableBodyItemText}>{item.id}</div>

              <div className={clsx(styles.tableBodyItemText, 'isPC')}>
                {item.createdAt.localDate}
              </div>

              <div className={clsx(styles.tableBodyItemText, 'capitalize')}>
                {item.displayStatus}
              </div>

              <div className={styles.tableBodyItemText}>
                {item.totalPrice.vnd}
              </div>

              <div className={clsx(styles.tableBodyItemText, 'isPC capitalize')}>
                {item.displayPriceType}
              </div>

              <div className={clsx(styles.tableBodyItemText, 'isPC')}>
                {item.pic.displayName}
              </div>

              <div className={styles.tableBodyItemText}>
                <button
                  to={`/stock-management/export-bill/${item.id}`}
                  onClick={openModal(item.id)}
                  className={styles.action}
                >
                  <i className={clsx(styles.icon, 'fas fa-eye')} />
                </button>
                {/* <i
                  className={clsx(styles.icon, 'fas fa-trash')}
                  onClick={handleRemoveBillClick(item.id)}
                /> */}
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className={styles.pagination}>
        <PaginationBar
          totalPage={exportOrders.totalPage}
          noPages={9}
          columnWidth={40}
          currentPage={filter.page}
          setCurrentPage={updatePage}
        />
      </div>

      <ExportOrderDetailModal />
    </>
  );
}

const mapStateToProps = ({ state }) => ({ state });

export default connect(mapStateToProps, actions)(ExportOrderList);

import { _p, _pc } from '@/domain/models/utils/model-proto';
import BaseModel from '@/domain/models/entities/list-filter';

class SupplierListFilter extends BaseModel {
  static properties = {
    ...BaseModel.properties,
    name: '',
    limit: 15,
  };

  constructor(obj) {
    super(obj);
  }

  /*
  page: number | null;
  */

  get value() {
    return this;
  }
}

export default SupplierListFilter;

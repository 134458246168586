import BaseModel from '../..';

class Permission extends BaseModel {
  static properties = {
    id: null,
    permissionId: null,
  };

  constructor(obj) {
    super(obj);
  }

  /*
  type: string | null;
  name: string | null;
  */
}

export default Permission;

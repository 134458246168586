import _ from 'lodash';

import { _p, _pc } from '@/domain/models/utils/model-proto';
import { PRICE_TYPE_KEYS } from '@/config/constants';
import { QtProductSchema } from '@/config/schemas';

import QtPrice from '../../value-object/qtPrice';
import BaseModel from '..';
import facade from '../../utils/facade';

class QtProduct extends BaseModel {
  static properties = {
    id: null,
    name: null,
    importPrice: _pc(QtPrice, new QtPrice()),
    wholeSalePrice: _pc(QtPrice, new QtPrice()),
    price: _pc(QtPrice, new QtPrice()),
    count: null,
  };

  constructor(obj) {
    super(obj);
  }

  /*
  id: number | null;
  name: string | null;
  multiPrice: QtPrice;
  stockPrice: QtPrice;
  count: number | null;
  */

  displayPrice(displayType) {
    const priceTypeKey = PRICE_TYPE_KEYS[parseInt(displayType)];

    return this[priceTypeKey];
  }

  setItem(item) {
    return facade.update(this, item);
  }

  validate() {
    const { error } = QtProductSchema.validate(
      JSON.parse(JSON.stringify(this)),
      {
        abortEarly: false,
      },
    );

    return error?.details;
  }

  diff(qtProduct) {
    const diffKeys = this.diffKeys(qtProduct, ['id']);

    return diffKeys.length ? _.pick(qtProduct, [...diffKeys, 'id']) : null;
  }

  static new(defaultValue) {
    return facade.create(QtProduct, defaultValue);
  }

  static clone(qtProduct) {
    return facade.create(QtProduct, qtProduct);
  }
}

export default QtProduct;

import { fetcher } from '../utilities';

const urlOrigin = '/api/user';

const getAll = async (cancelSignal, { role, isActive }) => {
  let query = '?';

  if (role) {
    query += `role=${role}&`;
  }

  if (isActive) {
    query += `isActive&`;
  }

  const result = await fetcher(`${urlOrigin}/list${query}`, {
    signal: cancelSignal,
  });
  const data = await result.json();

  return data;
};

const update = (user) =>
  fetcher(`${urlOrigin}/update`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(user),
  });

const ban = async (id) => {
  const result = await fetcher(`${urlOrigin}/ban`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ id: id }),
  });
  const data = await result.json();

  return data;
};

const forceUpdate = (user) =>
  fetcher(`${urlOrigin}/admin/update`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(user),
  });

const create = async (user) => {
  const result = await fetcher(`${urlOrigin}/create`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(user),
  });
  const data = await result.json();

  return data;
};

const search = (query) => fetcher(`${urlOrigin}/search?q=${query}`);

const unban = async (id) => {
  const result = await fetcher(`${urlOrigin}/unban`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ id: id }),
  });
  const data = await result.json();

  return data;
};

const resetPassword = async (userID, password) => {
  const result = await fetcher(`${urlOrigin}/reset-password`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ userID, password }),
  });
  const data = await result.json();

  return data;
};

const updateInfo = async (userId, { startAt, noteGroupRuleId }) => {
  const result = await fetcher(`${urlOrigin}/a-update`, {
    body: JSON.stringify({
      id: userId,
      startAt,
      noteGroupRuleId,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'PATCH',
  });
  const data = await result.json();

  return data;
};

export {
  update,
  getAll,
  ban,
  unban,
  create,
  forceUpdate,
  search,
  resetPassword,
  updateInfo,
};

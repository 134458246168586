import clsx from 'clsx';
import {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from 'react';
import '@assets/image/fontawesome-free-6.0.0-web/css/all.min.css';
import styles from './BillTable.module.scss';

function BillTable(
    {
        bills,
        checked,
        handleEditClick,
        handleSelectMouseEnter,
        handleSelectMouseDown,
        handleSortOrderChange,
        mousePressed,
        setMousePressed,
        error,
        order,
        fullSize,
        setFullSize,
    },
    ref,
) {
    const tableBody = useRef();
    const [imgZoom, setImgZoom] = useState(false);

    const addEventListener = (event, cb) =>
        tableBody.current.addEventListener(event, cb);
    const removeEventListener = (event, cb) =>
        tableBody.current.removeEventListener(event, cb);

    useImperativeHandle(
        ref,
        () => ({
            addEventListener,
            removeEventListener,
        }),
        [tableBody],
    );
    return (
        <div
            className={clsx(styles.tableGrid, {
                [styles.fullSize]: fullSize,
            })}
        >
            {/* Heading: Start */}
            <div
                className={clsx('textCenter', styles.headerGrid)}
                onDoubleClick={(e) => setFullSize((fullSize) => !fullSize)}
            >
                <div
                    className={clsx(styles.smallCol, styles.heading, {
                        [styles.error]: error.unSelectedBill,
                    })}
                    error={error.unSelectedBill}
                >
                    Chọn
                </div>
                <div
                    className={clsx(styles.heading)}
                    onClick={() => handleSortOrderChange('id')}
                >
                    Mã đơn
                    <i
                        className={clsx(styles.sortIcon, 'fa-solid', {
                            'fa-sort-up': order.id === 'ASC',
                            'fa-sort-down': order.id === 'DESC',
                            'fa-sort': order.id === '',
                        })}
                    />
                </div>
                <div
                    className={clsx(styles.heading)}
                    onClick={() => handleSortOrderChange('createdAt')}
                >
                    Ngày tạo
                    <i
                        className={clsx(styles.sortIcon, 'fa-solid', {
                            'fa-sort-up': order.createdAt === 'ASC',
                            'fa-sort-down': order.createdAt === 'DESC',
                            'fa-sort': order.createdAt === '',
                        })}
                    />
                </div>
                <div
                    className={clsx(styles.heading)}
                    onClick={() => handleSortOrderChange('name')}
                >
                    Khách hàng
                    <i
                        className={clsx(styles.sortIcon, 'fa-solid', {
                            'fa-sort-up': order.name === 'ASC',
                            'fa-sort-down': order.name === 'DESC',
                            'fa-sort': order.name === '',
                        })}
                    />
                </div>
                <div className={clsx(styles.heading)}>SĐT</div>
                <div className={clsx(styles.heading)}>Địa chỉ</div>
                <div
                    className={clsx(styles.heading)}
                    onClick={() => handleSortOrderChange('price')}
                >
                    ST Cần Thu
                    <i
                        className={clsx(styles.sortIcon, 'fa-solid', {
                            'fa-sort-up': order.price === 'ASC',
                            'fa-sort-down': order.price === 'DESC',
                            'fa-sort': order.price === '',
                        })}
                    />
                </div>
                <div className={clsx(styles.heading)}>Trang TMĐT</div>
                {/* <div className={clsx(styles.heading)}>Sản phẩm</div> */}
                <div className={clsx(styles.heading)}>ĐVVC</div>
                <div className={clsx(styles.heading)}>Tình trạng</div>
                <div className={clsx(styles.heading)}>Hình ảnh</div>
                <div className={clsx(styles.heading)}>Sửa</div>
            </div>
            {/* Heading: End */}

            <div className={clsx(styles.tableBody)} ref={tableBody}>
                {!bills.length && (
                    <h3 className={styles.emptyBills}>Không có sản phẩm</h3>
                )}
                {bills.map((bill) => {
                    return (
                        <div
                            key={bill.id}
                            className={clsx(styles.tableRowGrid, {
                                textUnselect: mousePressed,
                                [styles.hightlight]: bill.statusID === 1,
                            })}
                        >
                            {/* Select  */}
                            <div
                                className={clsx(styles.tableBodyRowContent)}
                                onMouseEnter={handleSelectMouseEnter}
                                onMouseDown={() => {
                                    setMousePressed(true);
                                    handleSelectMouseDown(bill.id);
                                }}
                                id={bill.id}
                            >
                                <input
                                    type="checkbox"
                                    name="selected-bills"
                                    onMouseEnter={(e) => {
                                        e.stopPropagation();
                                    }}
                                    checked={checked[bill.id] || false}
                                    defaultChecked={true}
                                />
                            </div>

                            {/* Bill ID */}
                            <div className={clsx(styles.tableBodyRowContent)}>
                                {bill.id}
                            </div>

                            {/* Bill Created Date */}
                            <div className={clsx(styles.tableBodyRowContent)}>
                                <p>
                                    <span>
                                        {new Date(
                                            bill.createdAt,
                                        ).toLocaleDateString('vi-VN')}
                                    </span>
                                    <span>
                                        {new Date(
                                            bill.createdAt,
                                        ).toLocaleTimeString('vi-VN')}
                                    </span>
                                </p>
                            </div>

                            {/* Customer Name */}
                            <div className={clsx(styles.tableBodyRowContent)}>
                                {bill.name}
                            </div>

                            {/* Customer Phone */}
                            <div className={clsx(styles.tableBodyRowContent)}>
                                <p>
                                    {bill.phones.map((phone) => (
                                        <span key={phone.phone}>
                                            {phone.phone}
                                        </span>
                                    ))}
                                </p>
                            </div>

                            {/* Address */}
                            <div
                                className={clsx(
                                    styles.tableBodyRowContent,
                                    'textLeftFlex',
                                )}
                            >
                                {bill.address}
                            </div>

                            {/* Price */}
                            <div className={clsx(styles.tableBodyRowContent)}>
                                {(bill.price - bill.paid).toLocaleString(
                                    'vi-VN',
                                    {
                                        style: 'currency',
                                        currency: 'VND',
                                    },
                                )}
                            </div>

                            {/* Ecommerce */}
                            <div
                                className={clsx(
                                    'textCenter',
                                    'textUppercase',
                                    styles.tableBodyRowContent,
                                )}
                            >
                                <p>
                                    <span className="textBold">
                                        {bill.ecommerceName}
                                    </span>
                                    <span>
                                        {bill.ecommerce && bill.ecommerce.name}
                                    </span>
                                </p>
                            </div>

                            {/* Product Name */}
                            {/* <div
                                className={clsx(
                                    'textUppercase',
                                    styles.tableBodyRowContent
                                )}
                            >
                                {bill.product.name}
                            </div> */}

                            {/* Ship Service */}
                            <div
                                className={clsx(
                                    'textUppercase',
                                    styles.tableBodyRowContent,
                                )}
                            >
                                {bill.shipService.name}
                            </div>

                            {/* Status */}
                            <div
                                className={clsx(
                                    'textUppercase',
                                    styles.tableBodyRowContent,
                                )}
                            >
                                <p>{bill.status.name}</p>
                            </div>

                            {/* Product Image */}
                            <div
                                className={clsx(styles.tableBodyRowContent, {
                                    [styles.zoom]: imgZoom,
                                })}
                                onClick={(e) => setImgZoom(!imgZoom)}
                            >
                                <img
                                    loading="lazy"
                                    src={bill.imgURL}
                                    alt="Hình ảnh con dấu"
                                    className={styles.productImg}
                                />
                            </div>

                            {/* Edit */}
                            <div
                                className={clsx(styles.tableBodyRowContent)}
                                onClick={() => handleEditClick(bill)}
                            >
                                <i className={clsx('fas fa-edit')} />
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default forwardRef(BillTable);

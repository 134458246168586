import clsx from 'clsx';

import styles from './NotifyModal.module.scss';
import logo from '@assets/image/logo.ico';
import * as constant from './constant';
import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';

function NotifyModal({ modal, show, closeModal }, ref) {
    const btnRef = useRef(null);

    useEffect(() => {
        const cb = (e) => e.key === 'Escape' && closeModal();
        document.addEventListener('keydown', cb);
        return () => document.removeEventListener('keydown', cb);
    }, []);

    useImperativeHandle(ref, () => ({
        focus: function () {
            btnRef.current.focus();
        },
    }));

    return (
        <div
            className={clsx(styles.modalWrapper, {
                [styles.hide]: !show,
            })}
            onClick={(e) => e.stopPropagation()}
        >
            <div className={styles.overlay} onClick={() => closeModal()}></div>

            <div className={styles.modalBody}>
                <div className={styles.heading}>
                    <img src={logo} className={styles.logo} alt="Logo" />
                    <h3>Kết quả</h3>
                </div>

                <div className={styles.body}>
                    <p className={styles.bodyTitle}>
                        {modal.type === constant.SUCCESS ? (
                            <i
                                className={clsx(
                                    styles.iconSuccess,
                                    'fa-solid',
                                    'fa-circle-check',
                                )}
                            />
                        ) : (
                            <i
                                className={clsx(
                                    styles.iconError,
                                    'fa-solid',
                                    'fa-circle-xmark',
                                )}
                            />
                        )}
                        {modal.title}
                    </p>
                    <p className={styles.bodyContent}>{modal.content}</p>

                    <div className={styles.bodyFooter}>
                        <button
                            className={styles.btn}
                            onClick={() => {
                                closeModal();
                            }}
                            ref={btnRef}
                        >
                            OK
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default forwardRef(NotifyModal);

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import _ from 'lodash';

import { Navigation, CustomDatePicker, NotifyModal } from '@components';
import styles from './Statistic.module.scss';
import * as defaultDate from '@components/CustomDatePicker/defaultValue';
import * as event from './event';

function Statistic() {
    const [startDate, setStartDate] = useState(
        defaultDate.getStartDate(new Date()),
    );
    const [endDate, setEndDate] = useState(defaultDate.getEndDate(new Date()));

    const [products, setProducts] = useState([]);
    const [shipServices, setShipServices] = useState([]);
    const [ecommerces, setEcommerces] = useState([]);
    const [revenues, setRevenues] = useState({
        totalPrice: 0,
        count: 0,
    });
    const [employees, setEmployees] = useState([]);
    const [employeeExpenses, setEmployeeExpenses] = useState([]);
    const [expenses, setExpenses] = useState([]);
    const [expenseBills, setExpenseBills] = useState({
        totalPrice: 0,
        count: 0,
    });

    const [notifyModal, setNotifyModal] = useState({
        type: '',
        title: '',
        content: null,
    });
    const [notifyModalShow, setNotifyModalShow] = useState(false);

    const [order, setOrder] = useState({
        product: { count: '', totalPrice: '' },
        shipService: { count: '', totalPrice: '' },
        ecommerce: { count: '', totalPrice: '' },
        employee: { count: '', totalPrice: '' },
        expense: { count: '', totalPrice: '' },
        employeeExpense: { count: '', totalPrice: '' },
    });

    useEffect(() => {
        const controller = event.statistic(
            startDate,
            endDate,
            order,
            setProducts,
            setShipServices,
            setEcommerces,
            setRevenues,
            setEmployees,
            setExpenses,
            setEmployeeExpenses,
            setExpenseBills,
        );

        return () => controller.abort();
    }, [startDate, endDate]);

    useEffect(() => {
        event.sortAll(
            order,
            setProducts,
            setShipServices,
            setEcommerces,
            setEmployees,
            setExpenses,
            setEmployeeExpenses,
        );
    }, [order]);

    return (
        <React.Fragment>
            <div className={styles.container}>
                <div className={styles.containerHeader}>
                    <div className={styles.contentHeader}>
                        <label className={styles.datePickerLabel}>
                            Ngày thống kê
                        </label>

                        <CustomDatePicker
                            startDate={startDate}
                            setStartDate={setStartDate}
                            endDate={endDate}
                            setEndDate={setEndDate}
                        />

                        <div className={styles.profitTotal}>
                            <h3>Lãi: </h3>
                            {(
                                revenues.totalPrice - expenseBills.totalPrice
                            ).toLocaleString('vi-VN', {
                                style: 'currency',
                                currency: 'VND',
                            })}
                        </div>
                    </div>

                    <Link className={clsx(styles.btn, styles.link)} to="circle">
                        Xem dạng biểu đồ
                    </Link>
                </div>

                <div className={styles.gridWrapper}>
                    {/* First Row */}
                    <div className={styles.row}>
                        <div className={styles.total}>
                            <div className={styles.totalWrapper}>
                                <h3>Số lượng thu:</h3>
                                {revenues.count}
                            </div>

                            <div className={styles.totalWrapper}>
                                <h3>Tổng thu:</h3>
                                {revenues.totalPrice.toLocaleString('vi-VN', {
                                    style: 'currency',
                                    currency: 'VND',
                                })}
                            </div>
                        </div>

                        {/* Product: Start */}
                        <div className={styles.tableThird}>
                            <div className={styles.tableHeader}>
                                <div className={styles.cell}>Sản phẩm</div>
                                <div
                                    className={styles.cell}
                                    onClick={() =>
                                        event.handleSortClick(
                                            'product',
                                            'count',
                                            setOrder,
                                        )
                                    }
                                >
                                    Số lượng
                                    <i
                                        className={clsx(
                                            styles.sortIcon,
                                            'fa-solid',
                                            {
                                                'fa-sort-up':
                                                    order.product.count ===
                                                    'ASC',
                                                'fa-sort-down':
                                                    order.product.count ===
                                                    'DESC',
                                                'fa-sort':
                                                    order.product.count === '',
                                            },
                                        )}
                                    />
                                </div>
                                <div
                                    className={styles.cell}
                                    onClick={() =>
                                        event.handleSortClick(
                                            'product',
                                            'totalPrice',
                                            setOrder,
                                        )
                                    }
                                >
                                    Tổng tiền
                                    <i
                                        className={clsx(
                                            styles.sortIcon,
                                            'fa-solid',
                                            {
                                                'fa-sort-up':
                                                    order.product.totalPrice ===
                                                    'ASC',
                                                'fa-sort-down':
                                                    order.product.totalPrice ===
                                                    'DESC',
                                                'fa-sort':
                                                    order.product.totalPrice ===
                                                    '',
                                            },
                                        )}
                                    />
                                </div>
                            </div>

                            <div className={styles.tableBody}>
                                {products.map((product) => (
                                    <div
                                        className={styles.tableRow}
                                        key={product.id}
                                    >
                                        <div className={styles.cell}>
                                            {product.name}
                                        </div>
                                        <div className={styles.cell}>
                                            {product.count}
                                        </div>
                                        <div className={styles.cell}>
                                            {product.totalPrice.toLocaleString(
                                                'vi-VN',
                                                {
                                                    style: 'currency',
                                                    currency: 'VND',
                                                },
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        {/* Product: End */}

                        {/* Ship Service: Start */}
                        <div className={styles.tableThird}>
                            <div className={styles.tableHeader}>
                                <div className={styles.cell}>
                                    Đơn vị vận chuyển
                                </div>
                                <div
                                    className={styles.cell}
                                    onClick={() =>
                                        event.handleSortClick(
                                            'shipService',
                                            'count',
                                            setOrder,
                                        )
                                    }
                                >
                                    Số lượng
                                    <i
                                        className={clsx(
                                            styles.sortIcon,
                                            'fa-solid',
                                            {
                                                'fa-sort-up':
                                                    order.shipService.count ===
                                                    'ASC',
                                                'fa-sort-down':
                                                    order.shipService.count ===
                                                    'DESC',
                                                'fa-sort':
                                                    order.shipService.count ===
                                                    '',
                                            },
                                        )}
                                    />
                                </div>
                                <div
                                    className={styles.cell}
                                    onClick={() =>
                                        event.handleSortClick(
                                            'shipService',
                                            'totalPrice',
                                            setOrder,
                                        )
                                    }
                                >
                                    Tổng tiền
                                    <i
                                        className={clsx(
                                            styles.sortIcon,
                                            'fa-solid',
                                            {
                                                'fa-sort-up':
                                                    order.shipService
                                                        .totalPrice === 'ASC',
                                                'fa-sort-down':
                                                    order.shipService
                                                        .totalPrice === 'DESC',
                                                'fa-sort':
                                                    order.shipService
                                                        .totalPrice === '',
                                            },
                                        )}
                                    />
                                </div>
                            </div>

                            <div className={styles.tableBody}>
                                {shipServices.map((shipService) => (
                                    <div
                                        className={styles.tableRow}
                                        key={shipService.id}
                                    >
                                        <div className={styles.cell}>
                                            {shipService.name}
                                        </div>
                                        <div className={styles.cell}>
                                            {shipService.count}
                                        </div>
                                        <div className={styles.cell}>
                                            {shipService.totalPrice.toLocaleString(
                                                'vi-VN',
                                                {
                                                    style: 'currency',
                                                    currency: 'VND',
                                                },
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        {/* Ship Service: End */}

                        {/* Ecommerce: Start */}
                        <div className={styles.tableThird}>
                            <div className={styles.tableHeader}>
                                <div className={styles.cell}>Kênh TMĐT</div>
                                <div
                                    className={styles.cell}
                                    onClick={() =>
                                        event.handleSortClick(
                                            'ecommerce',
                                            'count',
                                            setOrder,
                                        )
                                    }
                                >
                                    Số lượng
                                    <i
                                        className={clsx(
                                            styles.sortIcon,
                                            'fa-solid',
                                            {
                                                'fa-sort-up':
                                                    order.ecommerce.count ===
                                                    'ASC',
                                                'fa-sort-down':
                                                    order.ecommerce.count ===
                                                    'DESC',
                                                'fa-sort':
                                                    order.ecommerce.count ===
                                                    '',
                                            },
                                        )}
                                    />
                                </div>
                                <div
                                    className={styles.cell}
                                    onClick={() =>
                                        event.handleSortClick(
                                            'ecommerce',
                                            'totalPrice',
                                            setOrder,
                                        )
                                    }
                                >
                                    Tổng tiền
                                    <i
                                        className={clsx(
                                            styles.sortIcon,
                                            'fa-solid',
                                            {
                                                'fa-sort-up':
                                                    order.ecommerce
                                                        .totalPrice === 'ASC',
                                                'fa-sort-down':
                                                    order.ecommerce
                                                        .totalPrice === 'DESC',
                                                'fa-sort':
                                                    order.ecommerce
                                                        .totalPrice === '',
                                            },
                                        )}
                                    />
                                </div>
                            </div>

                            <div className={styles.tableBody}>
                                {ecommerces.map((ecommerce) => (
                                    <div
                                        className={styles.tableRow}
                                        key={ecommerce.id}
                                    >
                                        <div className={styles.cell}>
                                            {ecommerce.name}
                                        </div>
                                        <div className={styles.cell}>
                                            {ecommerce.count}
                                        </div>
                                        <div className={styles.cell}>
                                            {ecommerce.totalPrice.toLocaleString(
                                                'vi-VN',
                                                {
                                                    style: 'currency',
                                                    currency: 'VND',
                                                },
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        {/* Ecommerce: End */}

                        {/* Employee Contribution: Start */}
                        <div className={styles.tableThird}>
                            <div className={styles.tableHeader}>
                                <div className={styles.cell}>Nhân viên</div>
                                <div
                                    className={styles.cell}
                                    onClick={() =>
                                        event.handleSortClick(
                                            'employee',
                                            'count',
                                            setOrder,
                                        )
                                    }
                                >
                                    Số lượng
                                    <i
                                        className={clsx(
                                            styles.sortIcon,
                                            'fa-solid',
                                            {
                                                'fa-sort-up':
                                                    order.employee.count ===
                                                    'ASC',
                                                'fa-sort-down':
                                                    order.employee.count ===
                                                    'DESC',
                                                'fa-sort':
                                                    order.employee.count === '',
                                            },
                                        )}
                                    />
                                </div>
                                <div
                                    className={styles.cell}
                                    onClick={() =>
                                        event.handleSortClick(
                                            'employee',
                                            'totalPrice',
                                            setOrder,
                                        )
                                    }
                                >
                                    Tổng tiền
                                    <i
                                        className={clsx(
                                            styles.sortIcon,
                                            'fa-solid',
                                            {
                                                'fa-sort-up':
                                                    order.employee
                                                        .totalPrice === 'ASC',
                                                'fa-sort-down':
                                                    order.employee
                                                        .totalPrice === 'DESC',
                                                'fa-sort':
                                                    order.employee
                                                        .totalPrice === '',
                                            },
                                        )}
                                    />
                                </div>
                            </div>

                            <div className={styles.tableBody}>
                                {employees.map((employee) => (
                                    <div
                                        className={styles.tableRow}
                                        key={employee.id}
                                    >
                                        <div className={styles.cell}>
                                            {employee.displayName}
                                        </div>
                                        <div className={styles.cell}>
                                            {employee.bills.length > 0
                                                ? employee.count
                                                : 0}
                                        </div>
                                        <div className={styles.cell}>
                                            {employee.totalPrice.toLocaleString(
                                                'vi-VN',
                                                {
                                                    style: 'currency',
                                                    currency: 'VND',
                                                },
                                            )}
                                        </div>
                                    </div>
                                ))}
                                {/* Illustration Data: End */}
                            </div>
                        </div>
                        {/* Employee Contribution: End */}
                    </div>

                    {/* Second Row */}
                    <div className={clsx(styles.row, styles.expense)}>
                        <div className={styles.total}>
                            <div className={styles.totalWrapper}>
                                <h3>Số lượng chi:</h3>
                                {expenseBills.count}
                            </div>

                            <div className={styles.totalWrapper}>
                                <h3>Tổng chi:</h3>
                                {expenseBills.totalPrice.toLocaleString(
                                    'vi-VN',
                                    {
                                        style: 'currency',
                                        currency: 'VND',
                                    },
                                )}
                            </div>
                        </div>

                        {/* Expense: Start */}
                        <div className={styles.tableThird}>
                            <div className={styles.tableHeader}>
                                <div className={styles.cell}>Khoản chi</div>

                                <div
                                    className={styles.cell}
                                    onClick={() =>
                                        event.handleSortClick(
                                            'expense',
                                            'count',
                                            setOrder,
                                        )
                                    }
                                >
                                    Số lượng
                                    <i
                                        className={clsx(
                                            styles.sortIcon,
                                            'fa-solid',
                                            {
                                                'fa-sort-up':
                                                    order.expense.count ===
                                                    'ASC',
                                                'fa-sort-down':
                                                    order.expense.count ===
                                                    'DESC',
                                                'fa-sort':
                                                    order.expense.count === '',
                                            },
                                        )}
                                    />
                                </div>
                                <div
                                    className={styles.cell}
                                    onClick={() =>
                                        event.handleSortClick(
                                            'expense',
                                            'totalPrice',
                                            setOrder,
                                        )
                                    }
                                >
                                    Tổng tiền
                                    <i
                                        className={clsx(
                                            styles.sortIcon,
                                            'fa-solid',
                                            {
                                                'fa-sort-up':
                                                    order.expense.totalPrice ===
                                                    'ASC',
                                                'fa-sort-down':
                                                    order.expense.totalPrice ===
                                                    'DESC',
                                                'fa-sort':
                                                    order.expense.totalPrice ===
                                                    '',
                                            },
                                        )}
                                    />
                                </div>
                            </div>

                            <div className={styles.tableBody}>
                                {expenses
                                    .filter(
                                        (expense) =>
                                            expense.count > 0 ||
                                            expense.totalPrice > 0,
                                    )
                                    .map((expense) => (
                                        <div
                                            className={styles.tableRow}
                                            key={expense.id}
                                        >
                                            <div className={styles.cell}>
                                                {expense.name}
                                            </div>
                                            <div className={styles.cell}>
                                                {expense.count}
                                            </div>
                                            <div className={styles.cell}>
                                                {expense.totalPrice.toLocaleString(
                                                    'vi-VN',
                                                    {
                                                        style: 'currency',
                                                        currency: 'VND',
                                                    },
                                                )}
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        </div>
                        {/* Expense: End */}

                        {/* Employee Contribution: Start */}
                        <div className={styles.tableThird}>
                            <div className={styles.tableHeader}>
                                <div className={styles.cell}>Nhân viên</div>
                                <div
                                    className={styles.cell}
                                    onClick={() =>
                                        event.handleSortClick(
                                            'employeeExpense',
                                            'count',
                                            setOrder,
                                        )
                                    }
                                >
                                    Số lượng
                                    <i
                                        className={clsx(
                                            styles.sortIcon,
                                            'fa-solid',
                                            {
                                                'fa-sort-up':
                                                    order.employeeExpense
                                                        .count === 'ASC',
                                                'fa-sort-down':
                                                    order.employeeExpense
                                                        .count === 'DESC',
                                                'fa-sort':
                                                    order.employeeExpense
                                                        .count === '',
                                            },
                                        )}
                                    />
                                </div>
                                <div
                                    className={styles.cell}
                                    onClick={() =>
                                        event.handleSortClick(
                                            'employeeExpense',
                                            'totalPrice',
                                            setOrder,
                                        )
                                    }
                                >
                                    Tổng tiền
                                    <i
                                        className={clsx(
                                            styles.sortIcon,
                                            'fa-solid',
                                            {
                                                'fa-sort-up':
                                                    order.employeeExpense
                                                        .totalPrice === 'ASC',
                                                'fa-sort-down':
                                                    order.employeeExpense
                                                        .totalPrice === 'DESC',
                                                'fa-sort':
                                                    order.employeeExpense
                                                        .totalPrice === '',
                                            },
                                        )}
                                    />
                                </div>
                            </div>

                            <div className={styles.tableBody}>
                                {employeeExpenses.map((employee) => (
                                    <div
                                        className={styles.tableRow}
                                        key={employee.id}
                                    >
                                        <div className={styles.cell}>
                                            {employee.displayName}
                                        </div>
                                        <div className={styles.cell}>
                                            {employee.count}
                                        </div>
                                        <div className={styles.cell}>
                                            {employee.totalPrice.toLocaleString(
                                                'vi-VN',
                                                {
                                                    style: 'currency',
                                                    currency: 'VND',
                                                },
                                            )}
                                        </div>
                                    </div>
                                ))}
                                {/* Illustration Data: End */}
                            </div>
                        </div>
                        {/* Employee Contribution: End */}
                    </div>
                </div>

                <NotifyModal
                    modal={notifyModal}
                    show={notifyModalShow}
                    closeModal={() => setNotifyModalShow(false)}
                />
            </div>
        </React.Fragment>
    );
}

export default Statistic;

import { _p } from '@/domain/models/utils/model-proto';
import BaseModel from '../..';
import Note from '../note';

class Selection extends BaseModel {
  static properties = {
    note: _p(Note, new Note()),
  };

  constructor(obj) {
    super(obj);
  }

  /*
  id: number | null;
  name: string;
  price: null
  */
}

export default Selection;

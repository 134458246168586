import { fetcher } from '../utilities';

const url = '/api/statistic';

const getPeriod = (startDate, endDate) =>
    `startDate=${startDate.getTime()}&endDate=${endDate.getTime()}`;

const statisticProduct = async (cancelSignal, startDate, endDate) => {
    const result = await fetcher(
        `${url}/product?${getPeriod(startDate, endDate)}`,
        {
            signal: cancelSignal,
        },
    );

    return await result.json();
};

const statisticShipService = async (cancelSignal, startDate, endDate) => {
    const result = await fetcher(
        `${url}/ship-service?${getPeriod(startDate, endDate)}`,
        {
            signal: cancelSignal,
        },
    );

    return await result.json();
};

const statisticEcommerce = async (cancelSignal, startDate, endDate) => {
    const result = await fetcher(
        `${url}/ecommerce?${getPeriod(startDate, endDate)}`,
        {
            signal: cancelSignal,
        },
    );

    return await result.json();
};

const statisticRevenue = async (cancelSignal, startDate, endDate) => {
    const result = await fetcher(
        `${url}/revenue?${getPeriod(startDate, endDate)}`,
        {
            signal: cancelSignal,
        },
    );

    return await result.json();
};

const statisticEmployee = async (cancelSignal, startDate, endDate) => {
    const result = await fetcher(
        `${url}/employee?${getPeriod(startDate, endDate)}`,
        {
            signal: cancelSignal,
        },
    );

    return await result.json();
};

const statisticEmployeeExpense = async (cancelSignal, startDate, endDate) => {
    const result = await fetcher(
        `${url}/employee-expense?${getPeriod(startDate, endDate)}`,
        {
            signal: cancelSignal,
        },
    );

    return await result.json();
};

const statisticExpense = async (cancelSignal, startDate, endDate) => {
    const result = await fetcher(
        `${url}/expense?${getPeriod(startDate, endDate)}`,
        {
            signal: cancelSignal,
        },
    );

    return await result.json();
};

const statisticExpenseBill = async (cancelSignal, startDate, endDate) => {
    const result = await fetcher(
        `${url}/expense-bill?${getPeriod(startDate, endDate)}`,
        {
            signal: cancelSignal,
        },
    );

    return await result.json();
};

const statisticRevenueDetail = async (cancelSignal, ref, time, type) => {
    const result = await fetcher(
        `${url}/revenue-detail?ref=${ref}&time=${time}&type=${type}`,
        {
            signal: cancelSignal,
        },
    );

    return await result.json();
};

export {
    statisticProduct,
    statisticShipService,
    statisticEcommerce,
    statisticRevenue,
    statisticEmployee,
    statisticEmployeeExpense,
    statisticExpense,
    statisticExpenseBill,
    statisticRevenueDetail,
};

import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { Pie } from 'react-chartjs-2';
import { Link } from 'react-router-dom';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import { CustomDatePicker, Navigation } from '@components';
import * as event from './event';
import * as defaultDate from '@components/CustomDatePicker/defaultValue';
import styles from './StatisticCircle.module.scss';
import * as constant from './constant';

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

function StatisticCircle() {
    const [startDate, setStartDate] = useState(
        defaultDate.getStartDate(new Date()),
    );
    const [endDate, setEndDate] = useState(defaultDate.getEndDate(new Date()));

    const [data, setData] = useState(null);

    const [catalog, setCatalog] = useState({
        name: 'Thu',
        type: 0,
        data: constant.types[0],
    });
    const [selectedType, setSelectedType] = useState(constant.types[0].type);
    const [selectedValue, setSelectedValue] = useState('count');

    useEffect(() => {
        setSelectedType(catalog.data[0].type);
    }, [catalog]);

    useEffect(() => {
        const controller = event.handleStatistic(
            catalog,
            selectedType,
            selectedValue,
            setData,
            startDate,
            endDate,
        );

        return () => controller.abort();
    }, [selectedType, selectedValue, startDate, endDate]);

    return (
        <React.Fragment>
            <div className={styles.container}>
                <div className={styles.containerHeader}>
                    <div className={styles.datePickerWrapper}>
                        <label className={styles.datePickerLabel}>
                            Ngày thống kê
                        </label>

                        <CustomDatePicker
                            startDate={startDate}
                            setStartDate={setStartDate}
                            endDate={endDate}
                            setEndDate={setEndDate}
                        />
                    </div>

                    <Link
                        className={clsx(styles.btn, styles.link)}
                        to="/statistic"
                    >
                        Xem dạng danh sách
                    </Link>
                </div>

                <div className={styles.containerContent}>
                    <Link
                        className={clsx(styles.btn, styles.link)}
                        to="/statistic/bar"
                    >
                        Dạng cột
                    </Link>

                    <div className={styles.pieWrapper}>
                        {data && (
                            <Pie
                                data={data}
                                options={constant.options[selectedValue]}
                            />
                        )}
                    </div>

                    <div className={styles.filter}>
                        <select
                            className={clsx(styles.selectType)}
                            value={selectedValue}
                            onChange={(e) =>
                                event.handleSelectedValue(e, setSelectedValue)
                            }
                        >
                            <option value="count">Số đơn</option>
                            <option value="totalPrice">Tổng tiền</option>
                        </select>

                        <select
                            className={clsx(styles.selectType)}
                            value={selectedType}
                            onChange={(e) =>
                                event.handleSelectedTypeChange(
                                    e,
                                    setSelectedType,
                                )
                            }
                        >
                            {catalog.data.map((type, index) => (
                                <option key={index} value={type.type}>
                                    {type.name}
                                </option>
                            ))}
                        </select>

                        <button
                            className={styles.btn}
                            onClick={() =>
                                event.handleChangeTypeClick(setCatalog)
                            }
                        >
                            {catalog.name}
                        </button>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default StatisticCircle;

import { memo } from 'react';
import clsx from 'clsx';
import styles from './KeyValue.module.scss';

export const KeyValue = memo(
  ({
    title,
    value,
    className = '',
    valueClassName,
    titleClassName,
    separator = '',
  }) => (
    <div className={clsx(styles.container, className)}>
      <span
        className={clsx(styles.title, {
          [titleClassName]: titleClassName,
        })}
      >{`${title}${separator}`}</span>
      <span
        className={clsx(styles.value, {
          [valueClassName]: valueClassName,
        })}
      >
        {value}
      </span>
    </div>
  ),
);

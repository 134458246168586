import { _pc } from '@/domain/models/utils/model-proto';
import QtPrice from '@/domain/models/value-object/qtPrice';
import BaseModel from '../..';

class BaseReport extends BaseModel {
  static properties = {
    count: null,
    totalPrice: _pc(QtPrice, null),
  };

  constructor(obj) {
    super(obj);
  }
}

export default BaseReport;


import clsx from 'clsx';
import { memo } from 'react';
import styles from './Button.module.scss';

function Button({ content, role = 'main', onClick, disabled, className }) {
  return (
    <button
      className={clsx('cursor-pointer', styles.btn, {
        [styles[role]]: role,
        [styles.disabled]: disabled,
        [className]: className,
      })}
      onClick={onClick}
      disabled={disabled}
    >
      {content}
    </button>
  );
}

export default memo(Button);

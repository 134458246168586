import { _p } from '@/domain/models/utils/model-proto';
import BaseModel from '@/domain/models/entities';
import ImportOrder from '../import-order';

class ImportOrders extends BaseModel {
  static properties = {
    list: _p(ImportOrder, new ImportOrder()),
    count: null,
    totalPage: null,
  };

  constructor(obj) {
    super(obj);
  }

  /*
  list: ImportOrder[];
  count: number | null;
  */
}

export default ImportOrders;

import { useRef, useMemo, useEffect, useCallback } from 'react';
import _ from 'lodash';

function useThrottle(callback, delay, otherDeps) {
    const cbRef = useRef(callback);
    // use mutable ref to make useCallback/throttle not depend on `cb` dep
    const dependencies = useMemo(() => {
        if (otherDeps) {
            return [delay, ...otherDeps];
        }
        return [delay];
    }, []);

    useEffect(() => {
        cbRef.current = callback;
    });
    return useCallback(
        _.throttle((...args) => cbRef.current(...args), delay),
        dependencies,
    );
}

export default useThrottle;

import { memo } from 'react';
import { QtDatePicker } from '../QtDatePicker';
import styles from './style.module.scss';

export const QtDatePickerField = memo(({ label, ...props }) => {
  return (
    <div className={styles.container}>
      <label className={styles.label}>{label}</label>
      <QtDatePicker {...props}></QtDatePicker>
    </div>
  );
});

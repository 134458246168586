import { _p } from '@/domain/models/utils/model-proto';
import QtProduct from '../../qt-product';
import BaseModel from '../baseReport';

class QtProductReport extends BaseModel {
  static properties = {
    ...BaseModel.properties,
    product: _p(QtProduct, new QtProduct()),
  };

  constructor(obj) {
    super(obj);
  }
}

export default QtProductReport;

import { useState, useEffect } from 'react';
import { Routes, Route, Outlet } from 'react-router-dom';
import { connect } from 'react-redux';

import {
  PreviewBill,
  RetailBillVertical,
  StaffRoute,
  ManagerRoute,
  MarketterRoute,
  Navigation,
  Settings,
} from '@components';

import {
  Home,
  PrintBill,
  ListBill,
  SignIn,
  SignOut,
  UserAccount,
  ListAccount,
  Statistic,
  StatisticCircle,
  StatisticRectangle,
  Expense,
  Note,
  Market,
  MarketStatistic,
  HomeImage,
  EditEcommerce,
  EditProduct,
  EditShipService,
  EditQtProduct,
  EditSupplier,
  EditProvince,
  CreateNewAccount,
  ListNote,
  PermissionRule,
  StockManagement,
  ImportStoreProduct,
  ImportBillList,
  ExportQtProduct,
  ExportOrderList,
  ImportQtProduct,
  ImportOrderList,
  MarketDetailStatistic,
  Bill,
  StockStatistic,
  KhadaRetail,
} from '@screens';

import MarketContainer from '@/features/marketting/pages';

import { defaultValue, UserContext } from '@contexts/App';
import { encode, decode } from '@utilities';
import * as event from './event';
import { ProtectedRoute } from '@components/ProtectedRoute';
import actions from '@/application/actions';

function AppRoute(props) {
  const {
    actions: { initializeFromToken, initializeMasterDataFromToken },
  } = props;
  const [user, setUser] = useState(
    localStorage.getItem('user')
      ? JSON.parse(decode(localStorage.getItem('user')))
      : defaultValue.user,
  );

  const updateUser = (signedUser) => {
    const savedUser = signedUser;

    if (!savedUser) {
      localStorage.removeItem('user');
    } else {
      localStorage.setItem('user', encode(JSON.stringify(savedUser)));
    }

    setUser(savedUser);
  };

  useEffect(() => {
    const controller = event.isSignedIn(setUser);

    initializeFromToken();
    initializeMasterDataFromToken();

    return () => controller.abort();
  }, []);

  return (
    <UserContext.Provider value={user}>
      <Routes>
        <Route path="/sign-in" element={<SignIn updateUser={updateUser} />} />

        <Route path="/sign-out" element={<SignOut updateUser={updateUser} />} />

        <Route
          path="/"
          element={
            <ProtectedRoute>
              <Outlet />
            </ProtectedRoute>
          }
        >
          <Route path="/" element={<Navigation />}>
            <Route index element={<Home />} />
            <Route
              path="my-account"
              element={<UserAccount updateUser={updateUser} />}
            />
          </Route>
        </Route>

        <Route path="/" element={<StaffRoute />}>
          <Route path="/" element={<Navigation />}>
            <Route path="bill" element={<Bill />} />
            <Route path="list" element={<ListBill />} />
            <Route path="note" element={<Note />} />
            <Route
              path="expense"
              element={<Expense updateUser={updateUser} />}
            />
            <Route path="stock" element={<StockManagement />}>
              <Route index element={<ExportQtProduct />} />
              <Route path="export" element={<Outlet />}>
                <Route index element={<ExportOrderList />} />
                <Route path="create" element={<ExportQtProduct />} />
              </Route>

              <Route path="import" element={<Outlet />}>
                <Route index element={<ImportOrderList />} />
                <Route path="create" element={<ImportQtProduct />} />
              </Route>
            </Route>
          </Route>

          <Route path="print" element={<PrintBill Sample={PreviewBill} />} />
          <Route path="khada" element={<Outlet />}>
            <Route path="retail" element={<KhadaRetail />} />
          </Route>

          <Route
            path="print-retail"
            element={<PrintBill Sample={RetailBillVertical} />}
          />
        </Route>

        <Route path="/" element={<ManagerRoute />}>
          <Route path="/" element={<Navigation />}>
            <Route path="statistic" element={<Outlet />}>
              <Route index element={<Statistic />} />

              <Route path="circle" element={<StatisticCircle />} />

              <Route path="bar" element={<StatisticRectangle />} />
            </Route>

            <Route path="stock-statistic" element={<Outlet />}>
              <Route index element={<StockStatistic />} />
            </Route>

            <Route path="settings" element={<Settings />}>
              <Route index element={<HomeImage />} />

              <Route path="edit" element={<Outlet />}>
                <Route path="ecommerce" element={<EditEcommerce />} />
                <Route path="product" element={<EditProduct />} />
                <Route path="ship-service" element={<EditShipService />} />
                <Route path="qt-product" element={<EditQtProduct />} />
                <Route path="supplier" element={<EditSupplier />} />
                <Route path="province" element={<EditProvince />} />
              </Route>

              <Route path="user" element={<Outlet />}>
                <Route index element={<ListAccount />} />

                <Route path="create" element={<CreateNewAccount />} />
              </Route>

              <Route path="rule" element={<Outlet />}>
                <Route path="note" element={<ListNote />} />
                <Route path="permission" element={<PermissionRule />} />
              </Route>

              <Route path="import" element={<Outlet />}>
                <Route path="create" element={<ImportStoreProduct />} />
                <Route path="bill" element={<ImportBillList />} />
              </Route>
            </Route>
          </Route>
        </Route>

        <Route path="/" element={<MarketterRoute />}>
          <Route path="/" element={<Navigation />}>
            <Route path="market" element={<MarketContainer />} />

            {/* Old things: S */}
            <Route path="marketter" element={<Market />}>
              <Route
                path="statistic-detail"
                element={<MarketDetailStatistic />}
              />

              <Route path="statistic" element={<MarketStatistic />} />
            </Route>
            {/* Old things: E */}
          </Route>
        </Route>

        <Route
          path="*"
          element={
            <main
              style={{
                padding: '1rem',
                display: 'flex',
                height: '100vh',
              }}
            >
              <p
                style={{
                  color: 'white',
                  fontSize: '16rem',
                  margin: 'auto',
                }}
              >
                KHÔNG CÓ GÌ Ở ĐÂY CẢ!
              </p>
            </main>
          }
        />
      </Routes>
    </UserContext.Provider>
  );
}

export default connect(null, actions)(AppRoute);

import { memo, useCallback } from 'react';
import clsx from 'clsx';
import _ from 'lodash';

import { UI } from '@config/constants';
import styles from './Table.module.scss';
import PaginationBar from '@/components/PaginationBar';

const {
  SORT_ORDER: { NO_SORT, ASC, DESC },
} = UI;

export const Table = memo((props) => {
  const {
    title,
    data = [],
    headers = {},
    containerClassName,
    onRowClick = (idx) => {},
    paginate = null,
    paginationClassName,
    orders,
    onOrderChange,
  } = props;

  const _onRowClick = useCallback((idx) => () => onRowClick(idx), []);

  return (
    <div
      className={clsx(styles.container, {
        [styles.containerWithTitle]: title,
        [containerClassName]: containerClassName,
      })}
    >
      {title && <h3 className={styles.title}>{title}</h3>}
      <table className={styles.tableContainer}>
        <thead>
          <tr>
            {/* <th className="table-heading text-center">No</th> */}
            {headers.map(({ field, name, isPC, key = '' }) => (
              <th
                className={clsx(styles.header, {
                  [styles.isPC]: isPC,
                })}
                key={name}
              >
                {name}
                {orders?.[key] && (
                  <i
                    className={clsx('pi', {
                      ['pi-sort']: orders[key] === NO_SORT,
                      ['pi-sort-up']: orders[key].current === ASC,
                      ['pi-sort-down']: orders[key].current === DESC,
                    })}
                    onClick={onOrderChange(key)}
                  />
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((item, idx) => (
            <tr key={`item_${item.id}`} onClick={_onRowClick(idx)}>
              {/* <td className="table-data text-center">{idx + 1}</td> */}
              {headers.map(({ field, custom: Custom, className, isPC }) => (
                <td
                  className={clsx(styles.row, {
                    [className]: className,
                    [styles.isPC]: isPC,
                  })}
                  key={`item_${item.id}_${field}`}
                >
                  {Custom ? <Custom item={item} /> : _.get(item, field)}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>

      {paginate && (
        <PaginationBar
          totalPage={paginate.totalPage}
          noPages={paginate.noPages ?? 9}
          columnWidth={40}
          currentPage={paginate.currentPage}
          setCurrentPage={paginate.updatePage}
          className={clsx(styles.pagination, {
            [paginationClassName]: paginationClassName,
          })}
        />
      )}
    </div>
  );
});

import { memo } from 'react';
import clsx from 'clsx';

import styles from './styles.module.scss';

export const Select = memo(
  ({ name, value, options, disabled, onChange, className }) => (
    <select
      className={clsx(styles.container, {
        [className]: className,
      })}
      value={value}
      name={name}
      onChange={onChange}
      disabled={disabled}
    >
      {options.map(({ value, label }) => (
        <option key={value} value={value}>{label}</option>
      ))}
    </select>
  ),
);

import { _p } from '@/domain/models/utils/model-proto';
import Category from '../../../master/category';
import BaseModel from '../baseReport';

class CategoryReport extends BaseModel {
  static properties = {
    ...BaseModel.properties,
    category: _p(Category, new Category()),
  };

  constructor(obj) {
    super(obj);
  }
}

export default CategoryReport;

import React from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';

import styles from './EditShipService.module.scss';
import { Button } from '@components';
import EditOnRight from './EditOnRight';
import actions from '@/application/actions';
import { confirmDialog } from 'primereact/confirmdialog';

function EditShipService(props) {
  const {
    state: {
      masterData: { shipServices },
    },
    actions: { deleteShipService, fetchShipServices },
  } = props;
  const [editButton, setEditButton] = React.useState({
    shipService: {
      name: '',
    },
    open: false,
  });
  const onDeleteItem = React.useCallback(
    ({ name, id }) =>
      () =>
        confirmDialog({
          message: `Bạn có chắc chắn xóa ${name}?`,
          header: 'Xác nhận xóa',
          icon: 'pi pi-info-circle',
          acceptClassName: 'p-button-danger',
          accept: () => deleteShipService({ id }),
        }),
  );

  const closeEditButton = React.useCallback(
    () =>
      setEditButton({
        shipService: {
          name: '',
        },
        open: false,
      }),
    [],
  );
  const openEditButton = React.useCallback(
    (shipService) => () => setEditButton({ shipService, open: true }),
    [],
  );

  return (
    <div className={styles.container}>
      <h1 className={styles.header}>Trang thương mại điện tử</h1>

      <div className={styles.content}>
        <div className={styles.contentLeft}>
          <ul className={clsx(styles.contentLeftHeader, styles.row)}>
            <li className={styles.contentLeftHeaderItem}>STT</li>
            <li className={styles.contentLeftHeaderItem}>Tên</li>
            <li className={styles.contentLeftHeaderItem}>Hành động</li>
          </ul>

          <ul className={styles.contentLeftBody}>
            {shipServices.list.map((item, index) => (
              <li
                key={item.id}
                className={clsx(styles.row, styles.contentLeftBodyItem)}
              >
                <span className={styles.contentLeftBodyItemText}>
                  {index + 1}
                </span>

                <span className={styles.contentLeftBodyItemText}>
                  {item.name}
                </span>

                <div className={clsx(styles.action)}>
                  <i
                    className={clsx(styles.actionIcon, 'fas fa-pen')}
                    onClick={openEditButton({
                      ...item,
                      index,
                    })}
                  />

                  <i
                    className={clsx(styles.actionIcon, 'fas fa-trash')}
                    onClick={onDeleteItem(item)}
                  />
                </div>
              </li>
            ))}
          </ul>
        </div>

        <div className={styles.contentRight}>
          {!editButton.open ? (
            <Button
              content={
                <React.Fragment>
                  <i className={clsx('fas fa-plus', styles.btnIcon)} />
                  Thêm mới
                </React.Fragment>
              }
              onClick={openEditButton({ name: '' })}
            />
          ) : (
            <EditOnRight
              selectedShipService={editButton.shipService}
              closeEditButton={closeEditButton}
              fetchShipServices={fetchShipServices}
            />
          )}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = ({ state }) => ({ state });

export default connect(mapStateToProps, actions)(EditShipService);

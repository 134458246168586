import { endpoint } from '@/config/endpoint';
import MasterData from '@/domain/models/entities/master';
import facade from '@/domain/models/utils/facade';
import { request } from '@/utilities/request';
import { formatProvinces } from './format';

const initializeMasterDataFromToken = async (token) => {
  if (!token) {
    return facade.create(MasterData, {});
  }
  const user = { token };
  const options = [
    { endpoint: endpoint.ecommerces, user },
    { endpoint: endpoint.shipServices, user },
    { endpoint: endpoint.categories, user },
    { endpoint: endpoint.provinces, user },
  ];
  const promises = options.map((option) => request.get(option));

  const [ecommerces, shipServices, categories, provinces] = await Promise.all(
    promises,
  );

  return facade.create(MasterData, {
    ecommerces,
    shipServices,
    categories,
    provinces: formatProvinces(provinces),
  });
};

const fetchEcommerces = async ({ user, masterData }) => {
  const options = { endpoint: endpoint.ecommerces, user };
  const ecommerces = await request.get(options);

  return facade.update(masterData, {
    ecommerces,
  });
};

const deleteEcommerce = async ({ user, id }) => {
  const options = {
    endpoint: endpoint.ecommerces,
    data: { id },
    user,
  };
  await request.del(options);
};

const fetchShipServices = async ({ user, masterData }) => {
  const options = { endpoint: endpoint.shipServices, user };
  const shipServices = await request.get(options);

  return facade.update(masterData, {
    shipServices,
  });
};

const deleteShipService = async ({ user, id }) => {
  const options = {
    endpoint: endpoint.shipServices,
    data: { id },
    user,
  };
  await request.del(options);
};

const getProvinces = async ({ user }) => {
  const option = { endpoint: endpoint.provinces, user };

  const result = await request.get(option);

  return formatProvinces(result);
};

const updateProvinces = async ({ data, user }) => {
  const options = {
    endpoint: endpoint.provinces,
    user,
  };
  await request.put({ ...options, data });
  const provinces = await request.get(options);
  return formatProvinces(provinces);
};

const createProvinceKeyword = async ({ data, user }) => {
  const options = {
    endpoint: endpoint.provinceKeyword,
    user,
  };
  await request.post({ ...options, data });
  const provinces = await request.get({
    endpoint: endpoint.provinces,
    user,
  });
  return formatProvinces(provinces);
};

const deleteProvinceKeyword = async ({ data, user }) => {
  const options = {
    endpoint: endpoint.provinceKeyword,
    user,
  };
  await request.del({ ...options, data });
  const provinces = await request.get({
    endpoint: endpoint.provinces,
    user,
  });
  return formatProvinces(provinces);
};

export default {
  initializeMasterDataFromToken,
  fetchEcommerces,
  deleteEcommerce,
  fetchShipServices,
  deleteShipService,
  getProvinces,
  updateProvinces,
  createProvinceKeyword,
  deleteProvinceKeyword,
};

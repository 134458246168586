import clsx from 'clsx';
import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { UserContext } from '../../contexts/App';
import { default as styles } from './Navigation.module.scss';
import '@assets/image/fontawesome-free-6.0.0-web/css/all.min.css';
import { shopConfig } from '@/config/shop-config';

const routes = [
  {
    to: '/market',
    actualTo: '/market#detail',
    text: 'QUẢNG CÁO',
  },
];
const routeUser = [
  {
    to: '/my-account',
    text: 'Thông tin tài khoản',
  },
  {
    to: '/sign-out',
    text: 'Đăng xuất',
  },
];

const routeSP = [
  {
    to: '/my-account',
    text: 'THÔNG TIN TÀI KHOẢN',
  },
  {
    to: '/market',
    actualTo: '/market#detail',
    text: 'QUẢNG CÁO',
  },
  {
    to: '/sign-out',
    text: 'ĐĂNG XUẤT',
  },
];

function Navigation({ user }) {
  const location = useLocation();
  const [hide, setHide] = useState(true);

  useEffect(() => {
    const callback = (e) => setHide(true);
    document.addEventListener('click', callback);
    return () => document.removeEventListener('click', callback);
  }, []);

  return (
    <div className="grid wide">
      <ul className={clsx(styles.nav, styles.hideOnPhone)}>
        <li>
          <Link to="/">
            <img className={styles.logo} src={shopConfig.images.logo} alt="Logo Quốc Tiến" />
          </Link>
        </li>

        {routes.map(({ to, actualTo, text }) => (
          <li key={to}>
            <Link
              className={clsx(styles.navBtn, {
                [styles.active]: location.pathname.startsWith(to),
              })}
              to={actualTo || to}
            >
              {text}
            </Link>
          </li>
        ))}

        <li
          onClick={(e) => {
            e.stopPropagation();
            setHide(!hide);
          }}
        >
          <i className="fas fa-user" />
          <h3 className={styles.greeting}>Xin chào {user?.displayName}</h3>

          <ul
            className={clsx(styles.userOptions, {
              [styles.hide]: hide,
            })}
          >
            {routeUser.map(({ actualTo, to, text }) => (
              <li className={styles.userOption} key={to}>
                <Link className={clsx(styles.userLink)} to={actualTo || to}>
                  {text}
                </Link>
              </li>
            ))}
          </ul>
        </li>
      </ul>

      {/* Phone interface */}
      <ul className={clsx(styles.nav, styles.showOnPhone)}>
        <li>
          <Link to="/">
            <img className={styles.logo} src={shopConfig.images.logo} alt="Logo Quốc Tiến" />
          </Link>
        </li>

        <div className={clsx(styles.btnWrapper)}>
          <i
            className="fa-solid fa-bars"
            onClick={(e) => {
              e.stopPropagation();
              setHide(!hide);
            }}
          ></i>

          <ul
            className={clsx(styles.btns, {
              [styles.hide]: hide,
            })}
          >
            {routeSP.map(({ to, actualTo, text }) => (
              <li key={to}>
                <Link
                  className={clsx(styles.navBtn, {
                    [styles.active]: location.pathname.startsWith(to),
                  })}
                  to={actualTo || to}
                >
                  {text}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </ul>
    </div>
  );
}

export default Navigation;

import { fetcher } from '../utilities';

const urlOrigin = '/api/supplier';

const getAll = async (cancelSignal, page, perPage, search) => {
  let query = `?page=${page}&perPage=${perPage}`;

  if (search) {
    query += `&search=${search}`;
  }

  const result = await fetcher(`${urlOrigin}/list${query}`, {
    signal: cancelSignal,
  });
  const data = await result.json();

  return data;
};

export { getAll };

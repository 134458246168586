import { createRef, useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import slugify from 'slugify';
import clsx from 'clsx';
import _ from 'lodash';
import '@assets/image/fontawesome-free-6.0.0-web/css/all.min.css';
import { convertCamelCaseString } from '@/utilities';

import { default as styles } from './Bill.module.scss';

import { PreviewBill, Input, Select } from '@components';
import CreateKeywords from './CreateKeywords';
import * as defaultValue from './defaultValue';
import * as event from './event';
import { removeError } from './validate';
import actions from '@/application/actions';
import { Toast } from 'primereact/toast';
import { useMemo } from 'react';

function Bill(props) {
  const {
    state: {
      user,
      masterData: {
        provinces,
        shipServices: { list: shipServices },
        ecommerces: { list: eCommerces },
        categories: { list: products },
      },
    },
    actions: { createProvinceKeyword, deleteProvinceKeyword },
  } = props;
  // State definition
  // UI helper
  const [phone, setPhone] = useState('');
  const [loading, setLoading] = useState(false);
  const toast = useRef(null);

  // Data
  const [bill, setBill] = useState(defaultValue.bill);
  const [prevBillId, setPrevBillId] = useState(null);
  const [copiedBillIdVisible, setCopiedBillIdVisible] = useState(null);
  const [img, setImg] = useState();
  const [hints, setHints] = useState({
    names: [],
    addresses: [],
    ecommerces: [],
  });

  // UI state
  const [submitDisabled, setSubmitDisabled] = useState('');

  // Error container
  const [error, setError] = useState(defaultValue.error);

  // Ref definition
  // Input refs
  const [inputElems] = useState(new Array(7).fill().map((_, i) => createRef()));
  const previewImg = useRef();
  const form = useRef();

  const canInsertProvinceKeyword = useMemo(
    () => user.canInsertProvinceKeyword,
    [],
  );
  const canDeleteProvinceKeyword = useMemo(
    () => user.canDeleteProvinceKeyword,
    [],
  );

  const afterSubmit = (billReceive) => {
    setPrevBillId(billReceive.id);
  };

  const copyBillId = useCallback(() => {
    if (!prevBillId) return;
    navigator.clipboard.writeText(prevBillId);
    setTimeout(() => setCopiedBillIdVisible(false), 1000);
    setCopiedBillIdVisible(true);
  }, [prevBillId]);

  const onFieldChange = useCallback((e) => {
    setBill((bill) => ({
      ...bill,
      [e.target.name]: e.target.value,
    }));
  }, []);

  const onAddressChanged = useCallback(
    (addr) => {
      const slugAddr = slugify(convertCamelCaseString(addr), {
        locale: 'vi',
        lower: true,
      });
      const province = provinces.findProvince(slugAddr);

      if (province?.shipServiceId) {
        setBill((_bill) => ({
          ..._bill,
          shipService: { id: province.shipServiceId },
        }));
      }
    },
    [provinces],
  );

  const handleAddressChanged = useCallback(
    () => onAddressChanged(bill.address),
    [onAddressChanged, bill],
  );

  useEffect(() => {
    // Action when paste
    const cbOnPaste = (e) => {
      if (!event.handleImagePaste(e, previewImg, setImg, setError)) {
        // Paste text to input
        event.handleTextPaste(e, inputElems, setPhone, setBill, setError, {
          onAddressChanged,
        });
      }
    };

    document.addEventListener('paste', cbOnPaste);

    return () => {
      if (document) {
        document.removeEventListener('paste', cbOnPaste);
      }
    };
  }, [inputElems, previewImg]);

  useEffect(() => {
    document.querySelectorAll('input, select').forEach((node) => {
      node.onfocus = (e) => {
        removeError(setError, e.target.id);
        removeError(setError, 'formSubmit', {
          msg: '',
          error: '',
        });

        if (submitDisabled) {
          event.handleClearForm(
            setBill,
            setImg,
            previewImg,
            setError,
            inputElems[0],
            setPhone,
            setSubmitDisabled,
          );
        }
      };
    });
  }, [submitDisabled]);

  useEffect(() => {
    // const handleKeyDown = (e) => {
    //   if (e.key === 'Enter' && !submitDisabled) {
    //     e.preventDefault();
    //     return;
    //     event.submitForm(
    //       bill,
    //       setBill,
    //       img,
    //       setError,
    //       submitDisabled,
    //       setSubmitDisabled,
    //       setLoading,
    //       afterSubmit,
    //     );
    //   }
    // };
    const handleKeyDown = (e) => { 
      if (e.key === 'Enter' && !submitDisabled && e.target.id !== 'keyword') {
        e.preventDefault();
        event.submitForm(
          bill,
          setBill,
          img,
          setError,
          submitDisabled,
          setSubmitDisabled,
          setLoading,
          afterSubmit,
        );
      }
    }
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [bill, submitDisabled, img]);

  useEffect(() => {
    if (bill.shipService.id > 0) {
      const shipService = _.find(
        shipServices,
        (shipService) => shipService.id === bill.shipService.id,
      );

      const ecommerce = _.find(
        eCommerces,
        (eCommerce) => eCommerce.name === shipService.name,
      );

      if (ecommerce) {
        setBill((bill) => ({
          ...bill,
          ecommerceID: ecommerce.id,
          shipService: {
            ...bill.shipService,
            name: shipService?.name,
          },
        }));
      } else {
        setBill((bill) => ({
          ...bill,
          shipService: {
            ...bill.shipService,
            name: shipService?.name,
          },
        }));
      }
    }
  }, [bill.shipService.id]);

  // Effect for hint
  useEffect(() => {
    event.handleHintPhone(hints, setHints, bill.phones);
  }, [bill.phones]);

  useEffect(() => {
    if (hints.addresses.length > 0 && hints.addresses[0]) {
      onAddressChanged(hints.addresses[0]);
    }

    setBill((bill) => ({
      ...bill,
      name: bill.name || (hints.names.length > 0 && hints.names[0]) || '',
      address:
        bill.address ||
        (hints.addresses.length > 0 && hints.addresses[0]) ||
        '',
      ecommerceID:
        bill.ecommerceID ||
        (hints.ecommerces.length > 0 && hints.ecommerces[0].ecommerceID) ||
        '',
      ecommerceName:
        bill.ecommerceName ||
        (hints.ecommerces.length > 0 && hints.ecommerces[0].ecommerceName) ||
        '',
    }));
  }, [hints]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div className={styles.preview}>
          <PreviewBill bill={bill} />
          <div
            className={clsx(styles.imgWrapper, {
              [styles.removable]: !!img,
              [styles.error]: error.img,
            })}
            onDoubleClick={() => event.handleRemoveImage(previewImg, setImg)}
            error={error.img}
          >
            <img
              className={styles.previewProduct}
              src=""
              alt="Sản phẩm"
              ref={previewImg}
            />
          </div>
        </div>

        {/* Form: Start */}
        <div className={styles.formContainer}>
          {canInsertProvinceKeyword && (
            <CreateKeywords
              provinces={provinces}
              createProvinceKeyword={createProvinceKeyword}
              deleteProvinceKeyword={deleteProvinceKeyword}
              canDeleteProvinceKeyword={canDeleteProvinceKeyword}
              toastRef={toast}
            />
          )}

          <div className={styles.formHeader}>
            <div className={styles.formSubmitResult}>
              {error.formSubmit.msg && (
                <label className={styles.formSubmitSuccess}>
                  {error.formSubmit.msg}
                </label>
              )}
              {error.formSubmit.error && (
                <label className={styles.formSubmitError}>
                  {error.formSubmit.error}
                </label>
              )}
            </div>
            <div className={styles.formActionContainer}>
              <button
                className={clsx(styles.copyButton, {
                  [styles.copiedTag]: copiedBillIdVisible,
                })}
                onClick={copyBillId}
              >
                <span>{prevBillId}</span>
                Copy mã đơn
              </button>
              <i
                className="fas fa-trash"
                onClick={(e) =>
                  event.handleClearForm(
                    setBill,
                    setImg,
                    previewImg,
                    setError,
                    inputElems[0],
                    setPhone,
                    setSubmitDisabled,
                  )
                }
              />
            </div>
          </div>

          <form
            className={styles.form}
            onSubmit={(e) => {
              e.preventDefault();
              event.handleFormSubmit(
                e,
                setBill,
                bill,
                img,
                setError,
                submitDisabled,
                setSubmitDisabled,
                setLoading,
                afterSubmit,
              );
            }}
            ref={form}
          >
            {/* Name */}
            <Input
              value={bill.name}
              label={{ id: 'name', name: 'Họ tên' }}
              onChange={(e) => event.handleNameChange(e, setBill)}
              error={error.name}
              className={clsx(styles.leftHintSide, styles.row)}
              ref={inputElems[0]}
            />

            {/* Phone: Start*/}
            <Input
              error={error.phone}
              label={{ id: 'phone', name: 'SĐT' }}
              className={clsx(styles.leftHintSide, styles.row)}
              value={phone}
              onChange={(e) =>
                event.handlePhoneInputChange(e, setPhone, setBill, setError)
              }
              ref={inputElems[1]}
              maxLength={10}
            >
              {bill.phones.map((phone, index) => (
                <div className={styles.phoneWrapper} key={index}>
                  <div className={styles.phoneBox}>{phone}</div>
                  <i
                    className={clsx('fas', 'fa-multiply', styles.phoneRemove)}
                    onClick={(e) => event.handlePhoneRemoveClick(e, setBill)}
                    id={index}
                  />
                </div>
              ))}
            </Input>
            {/* Phone: End */}

            {/* Address*/}
            <div className={clsx(styles.row, styles.addressContainer)}>
              <Input
                error={error.address}
                label={{ id: 'address', name: 'Địa chỉ' }}
                className={clsx(styles.address)}
                value={bill.address}
                onChange={onFieldChange}
                onBlur={handleAddressChanged}
                name="address"
                ref={inputElems[2]}
              />
            </div>

            {/* Total Price */}
            <div className={clsx('grid-2', styles.row)}>
              <Input
                error={error.price}
                label={{
                  id: 'price',
                  name: 'Tổng tiền',
                }}
                value={bill.price.toLocaleString('vi-VN')}
                onChange={(e) =>
                  event.handlePriceChange(e, setBill, bill.paid, setError)
                }
                ref={inputElems[3]}
              />

              {/* Total paid*/}

              <Input
                error={error.paid}
                label={{
                  id: 'paid',
                  name: 'Khách đưa',
                }}
                value={bill.paid.toLocaleString('vi-VN')}
                onChange={(e) =>
                  event.handlePaidChange(e, setBill, bill.price, setError)
                }
                ref={inputElems[4]}
              />
            </div>

            {/* Ship Service: Start */}
            <div className={clsx('grid-2', styles.row)}>
              <Select
                error={error.ship}
                label={{
                  id: 'shipService',
                  name: 'ĐV Vận Chuyển',
                }}
                select={{
                  firstItem: 'Đơn vị vận chuyển',
                  items: shipServices,
                }}
                value={bill.shipService.id}
                onChange={(e) => event.handleShipServiceChange(e, setBill)}
              />
              {/* Ship Service: End */}

              {/* Product: Start */}
              <Select
                error={error.product}
                label={{
                  id: 'product',
                  name: 'Sản phẩm',
                }}
                select={{
                  firstItem: 'Sản phẩm',
                  items: products,
                }}
                value={bill.productID}
                onChange={(e) =>
                  event.handleProductChange(e, setBill, products)
                }
              />
              {/* Product: End */}
            </div>

            {/* Ecommerce */}

            <div className={clsx('flex', styles.row)}>
              <Input
                error={error.ecommerceName || error.ecommerceID}
                label={{
                  id: 'ecommerceName',
                  name: 'Trang TMĐT',
                }}
                className={'flexStretch'}
                value={bill.ecommerceName}
                onChange={(e) => event.handleEcommerceNameChange(e, setBill)}
                ref={inputElems[5]}
              />

              <Select
                select={{
                  firstItem: 'Kênh TMĐT',
                  items: eCommerces,
                }}
                value={bill.ecommerceID}
                onChange={(e) => event.handleEcommerceChange(e, setBill)}
              />
            </div>

            {/* Note */}
            <Input
              className={styles.row}
              error={error.ecommerceID}
              label={{
                id: 'note',
                name: 'Ghi chú',
              }}
              value={bill.note}
              onChange={(e) => event.handleNoteChange(e, setBill)}
              ref={inputElems[6]}
            ></Input>

            {/* Submit Button */}
            <div className={clsx(styles.row, 'flex')}>
              <input
                className={clsx(styles.submitBtn, {
                  [styles.submitBtnDisabled]: submitDisabled,
                  [styles.submitHide]: loading,
                })}
                type="submit"
                value="Tạo đơn"
              />
              <div
                className={clsx('lds-ring', {
                  [styles.loading]: loading,
                })}
              >
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </form>
        </div>
        {/* Form: End*/}

        <div className={styles.hintWrapper}>
          <h3>Đề xuất</h3>
          <h4>Họ tên</h4>
          <ul className={styles.hintOptions}>
            {hints.names.map((name, index) => (
              <li
                className={styles.hintOption}
                onClick={() => event.handleHintNameClick(setBill, name)}
                key={index}
              >
                {name}
              </li>
            ))}
          </ul>

          <h4>Địa chỉ</h4>
          <ul className={styles.hintOptions}>
            {hints.addresses.map((address, index) => (
              <li
                className={styles.hintOption}
                onClick={() => event.handleHintAddressClick(setBill, address)}
                key={index}
              >
                {address}
              </li>
            ))}
          </ul>

          <h4>Trang TMĐT</h4>
          <ul className={styles.hintOptions}>
            {hints.ecommerces.map((ecommerce, index) => (
              <li
                className={styles.hintOption}
                onClick={() =>
                  event.handleHintEcommerceClick(setBill, ecommerce)
                }
                key={index}
              >
                {`${ecommerce.ecommerceName} - ${ecommerce.ecommercePage}`}
              </li>
            ))}
          </ul>
        </div>
      </div>

      <Toast ref={toast} />
    </div>
  );
}
Bill.displayName = 'Bill';

const mapStateToProps = ({ state }) => ({
  state,
});

export default connect(mapStateToProps, actions)(Bill);

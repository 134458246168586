import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import Supplier from '@/domain/models/entities/stock/supplier';

import actions from '@/application/actions';
import { TOAST_SEVERITY } from '@/config/constants';
import { FORM } from '@config/ui';
import { Form } from '@/components/organisms';
import QtModal from '../QtModal';
import styles from './styles.module.scss';

const mapStateToProps = ({ state }) => ({ state });

const defaultValue = {
  id: null,
  name: '',
  company: '',
  address: '',
  email: '',
  phone: '',
};

export const SupplierDetailModal = connect(
  mapStateToProps,
  actions,
)((props) => {
  const {
    state: {
      stock: { supplier },
      ui: { supplierModal, toastRef, isError },
    },
    actions: {
      toggleSupplierModal,
      createSupplier,
      updateSupplier,
      toggleError,
    },
  } = props;

  const [newSupplier, setNewSupplier] = useState(Supplier.new(defaultValue));
  const onClose = () => toggleSupplierModal({ isOpen: false, isCreate: false });

  const onFormFieldChange = (e) => {
    const { name, value } = e.target;
    setNewSupplier(newSupplier.setItem({ [name]: value }));
  };

  const fields = [
    {
      type: FORM.TYPE.INPUT,
      label: 'Công ty',
      key: 'company',
      name: 'company',
      onChange: onFormFieldChange,
    },
    {
      type: FORM.TYPE.INPUT,
      label: 'Tên',
      key: 'name',
      name: 'name',
      onChange: onFormFieldChange,
    },
    {
      type: FORM.TYPE.INPUT,
      label: 'Địa chỉ',
      key: 'address',
      name: 'address',
      onChange: onFormFieldChange,
    },
    {
      type: FORM.TYPE.INPUT,
      label: 'Email',
      key: 'email',
      name: 'email',
      onChange: onFormFieldChange,
    },
    {
      type: FORM.TYPE.INPUT,
      label: 'SĐT',
      key: 'phone.value',
      name: 'phone',
      onChange: onFormFieldChange,
    },
  ];

  const onSubmit = () => {
    const error = newSupplier.validate();
    if (error) {
      toastRef.show({
        type: TOAST_SEVERITY.ERROR,
        message: error.map(({ message }) => <p>{message}</p>),
        callback: () =>
          toggleError({
            isError: true,
            callback: () =>
              setTimeout(() => toggleError({ isError: false }), 1500),
          }),
        life: 1500,
      });
    } else {
      if (supplierModal.isCreate)
        createSupplier({
          data: newSupplier,
          fromModal: true,
        });
      else {
        const data = supplier.diff(newSupplier);

        if (data) {
          updateSupplier({ data });
        } else {
          toggleSupplierModal({ isOpen: false });
        }
      }
    }
  };

  useEffect(() => {
    setNewSupplier(
      supplierModal.isCreate ? Supplier.new(defaultValue) : supplier,
    );
  }, [supplierModal, supplier]);

  return (
    <QtModal
      contentContainer={styles.contentContainer}
      isOpen={supplierModal.isOpen}
      onClose={onClose}
    >
      <h3 className={styles.header}>
        {supplierModal.isCreate ? 'Tạo mới' : `Nhà cung cấp #${newSupplier.id}`}
      </h3>

      <Form
        className={styles.form}
        onSubmit={onSubmit}
        value={newSupplier}
        fields={fields}
        submitText={supplierModal.isCreate ? 'Tạo mới' : 'Cập nhật'}
        submitDisabled={isError}
      />
    </QtModal>
  );
});

import { _p } from '../../../utils/model-proto';
import BaseModel from '../..';
import Ecommerce from '../ecommerce';

class Ecommerces extends BaseModel {
  static properties = {
    list: _p(Ecommerce, new Ecommerce()),
  };

  constructor(obj) {
    super(obj);
  }

  /*
  list: Ecommerce;
  */
}

export default Ecommerces;

import { fetcher } from '../utilities';

const url = '/api/home-image';

const days = {
    1: { vi: 'Chủ nhật', en: 'sunday' },
    2: { vi: 'Thứ hai', en: 'monday' },
    3: { vi: 'Thứ ba', en: 'tuesday' },
    4: { vi: 'Thứ tư', en: 'wednesday' },
    5: { vi: 'Thứ năm', en: 'thursday' },
    6: { vi: 'Thứ sáu', en: 'friday' },
    7: { vi: 'Thứ bảy', en: 'saturday' },
};

const getDay = (day) => days[day].vi;

const getSentDay = (day) => days[day].en;

const getAll = () => fetcher(`${url}/list`);

const update = (changeLists) => {
    const formData = new FormData();
    changeLists.forEach((changeList) => {
        formData.append(getSentDay(changeList.day), changeList.img);
    });

    return fetcher(`${url}/update`, {
        method: 'PUT',
        body: formData,
    });
};

export { getAll, update, getDay };

import { productService } from '@services';
import toastMessageType from '@constant/ToastMessage';

class ProductController {
    getAll = async (cancelSignal, setproducts) => {
        try {
            const result = await productService.getAll(cancelSignal);

            if (!result.error) {
                setproducts(result);
            }
        } catch (err) {
            if (err.name === 'AbortError') {
                console.log('Aborted!');
            }
        }
    };

    update = async (product, setToastMessage, setData, closeEditButton) => {
        try {
            const result = await productService.update(product);

            if (result.success) {
                setToastMessage({
                    isVisible: true,
                    type: toastMessageType.SUCCESS,
                    msg: {
                        title: 'Thành công',
                        duration: 3000,
                        desc: 'Cập nhật thành công',
                    },
                });
                setData((data) =>
                    data.map((item) =>
                        item.id === product.id ? product : item,
                    ),
                );
                closeEditButton();
            } else {
                throw new Error();
            }
        } catch (err) {
            setToastMessage({
                isVisible: true,
                type: toastMessageType.ERROR,
                msg: {
                    title: 'Lỗi',
                    duration: 3000,
                    desc: 'Không thể cập nhật',
                },
            });
        }
    };

    create = async (name, setToastMessage, setData, closeEditButton) => {
        try {
            const result = await productService.create(name);

            if (result.success) {
                setToastMessage({
                    isVisible: true,
                    type: toastMessageType.SUCCESS,
                    msg: {
                        title: 'Thành công',
                        duration: 3000,
                        desc: 'Cập nhật thành công',
                    },
                });
                setData((data) => [...data, { id: result.id, name: name }]);
                closeEditButton();
            } else {
                setToastMessage({
                    isVisible: true,
                    type: toastMessageType.ERROR,
                    msg: {
                        title: 'Thất bại',
                        duration: 3000,
                        desc: 'Không có thay đổi gì để cập nhật',
                    },
                });
            }
        } catch (err) {
            setToastMessage({
                isVisible: true,
                type: toastMessageType.ERROR,
                msg: {
                    title: 'Lỗi',
                    duration: 3000,
                    desc: 'Không thể cập nhật',
                },
            });
        }
    };
}

export default ProductController;

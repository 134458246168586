import { memo } from 'react';
import _ from 'lodash';

import { KeyValue } from '@/components/molecules';

export const KeyValues = memo(({ data, fields, separator = '' }) => (
  <div>
    {fields.map(({ title, field, valueClassName, titleClassName }) => (
      <KeyValue
        key={field}
        title={title}
        value={_.get(data, field)}
        separator={separator}
        valueClassName={valueClassName}
        titleClassName={titleClassName}
      />
    ))}
  </div>
));

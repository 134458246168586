import React, { memo, useRef } from 'react';
import { connect } from 'react-redux';
import { Toast } from 'primereact/toast';
import style from './style.scss';

const ToastMessage = ({ toast }) => {
  const toastRef = useRef(null);

  if (toast.isOpen) {
    const toastInfo = {
      severity: toast.type,
      summary: toast.title,
      detail: toast.content,
      className: style.wrapper,
      contentClassName: style.content,
    };
    toastRef.current.show(toastInfo);
  }

  return <Toast ref={toastRef} />;
};

const mapStateToProps = ({ state }) => ({ toast: state.ui.toast });

export default connect(mapStateToProps)(memo(ToastMessage));

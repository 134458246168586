import { mapAsyncActions } from './utils/async-actions-helper';
import { asyncActions as domainAsyncActions } from '../reducer/domain';
import facade from '@/domain/models/utils/facade';
import Domain from '@/domain/models/entities/domain';

export const initialState = facade.create(Domain, {});
export { actions as reducers } from '../reducer/domain';
export const { asyncActions, extraReducers } = mapAsyncActions(
  'domain',
  domainAsyncActions,
);
